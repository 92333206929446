import { Box, Stack } from '@mui/material';
import { FreightExtraResume } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const FreightExtraResumeEntity: WebEntity<FreightExtraResume> = {
  name: 'freightExtraResume',
  endpoint: 'freightExtra/resume',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Concepto',
      accessorKey: 'conceptName',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sub Concepto',
      accessorKey: 'subConceptDescription',
      columnType: 'text',
      size: 200
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Rut',
      accessorKey: 'carrierRut',
      columnType: 'text',
      size: 200
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text',
      size: 150
    },
    {
      header: 'SOLPED',
      accessorKey: 'purchaseRequest',
      columnType: 'text',
      size: 200
    },
    {
      header: 'OC',
      accessorKey: 'purchaseOrder',
      columnType: 'text',
      size: 200
    },
    {
      header: 'NP',
      accessorKey: 'noteOrder',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona CD',
      accessorKey: 'distributionCenterZona',
      columnType: 'text',
      size: 200
    },
    {
      header: 'CD',
      accessorKey: 'distributionCenter',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Enero',
      accessorKey: 'january',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.january ? formatStringNumber(row?.original?.january, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.january ? acc + Number(obj.january) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'february',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.february ? formatStringNumber(row?.original?.february, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.february ? acc + Number(obj.february) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'march',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.march ? formatStringNumber(row?.original?.march, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.march ? acc + Number(obj.march) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Abril',
      accessorKey: 'april',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.april ? formatStringNumber(row?.original?.april, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.april ? acc + Number(obj.april) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'may',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => (row?.original?.may ? formatStringNumber(row?.original?.may, 0, 2) : ''),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => (obj.may ? acc + Number(obj.may) : acc), 0);
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Junio',
      accessorKey: 'june',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => (row?.original?.june ? formatStringNumber(row?.original?.june, 0, 2) : ''),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => (obj.june ? acc + Number(obj.june) : acc), 0);
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Julio',
      accessorKey: 'july',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => (row?.original?.july ? formatStringNumber(row?.original?.july, 0, 2) : ''),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => (obj.july ? acc + Number(obj.july) : acc), 0);
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'august',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.august ? formatStringNumber(row?.original?.august, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.august ? acc + Number(obj.august) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'september',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.september ? formatStringNumber(row?.original?.september, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.september ? acc + Number(obj.september) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'october',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.october ? formatStringNumber(row?.original?.october, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.october ? acc + Number(obj.october) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'november',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.november ? formatStringNumber(row?.original?.november, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.november ? acc + Number(obj.november) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'december',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.december ? formatStringNumber(row?.original?.december, 0, 2) : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.december ? acc + Number(obj.december) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => (row?.original?.total ? formatStringNumber(row?.original?.total) : ''),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Stack>
        );
      }
    }
  ]
};

export default FreightExtraResumeEntity;
