import { GeneralZoneReport } from '../types';
import { formatStringNumber } from '../utils/utils';
import { Box, Stack } from '@mui/material';
import { WebEntity } from './types';

const GeneralZoneReportEntity: WebEntity<GeneralZoneReport> = {
  name: 'generalZoneReport',
  endpoint: 'expenses/generalZoneReport',
  referenceColumn: 'categoriesName',
  tableColumns: [
    {
      header: 'Categorias',
      accessorKey: 'categoriesName',
      id: 'categoriesName',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      id: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sub Zona',
      accessorKey: 'description',
      id: 'description',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Centro De costo',
      accessorKey: 'costCenterName',
      id: 'costCenterName',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Centro De costo SAP',
      accessorKey: 'costCenterSap',
      id: 'costCenterSap',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Descp Cuenta',
      accessorKey: 'accountDescription',
      id: 'accountDescription',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Cuenta SAP',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 200
    },
    {
      header: 'GL',
      accessorKey: 'gl',
      columnType: 'numeric',
      id: 'gl',
      size: 200,
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.gl))
          ? formatStringNumber(Number(row?.original?.gl).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.gl), 0);
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total GL:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Provisiones',
      accessorKey: 'provision',
      id: 'provision',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.provision))
          ? formatStringNumber(Number(row?.original?.provision).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.provision), 0);
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total Provision:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Reclasificaciones',
      accessorKey: 'reclasificacion',
      id: 'reclasificacion',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.reclasificacion))
          ? formatStringNumber(Number(row?.original?.reclasificacion).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.reclasificacion), 0);
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total reclasificacion:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Gestion HFM',
      accessorKey: 'hfm',
      id: 'hfm',
      size: 200,
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.hfm))
          ? formatStringNumber(Number(row?.original?.hfm).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.hfm), 0);
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total HFM:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      id: 'total',
      columnType: 'numeric',
      size: 250,
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.total))
          ? formatStringNumber(Number(row?.original?.total).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.total), 0);
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total General:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Presupuesto Anual',
      accessorKey: 'budgetYear',
      id: 'budgetYear',
      columnType: 'numeric',
      size: 300,
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.budgetYear))
          ? formatStringNumber(Number(row?.original?.budgetYear).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.budgetYear), 0);
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total Presupuesto Anual:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Estimado cierre',
      accessorKey: 'estimatedTotal',
      id: 'estimatedTotal',
      columnType: 'numeric',
      size: 250,
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.estimatedTotal))
          ? formatStringNumber(Number(row?.original?.estimatedTotal).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.estimatedTotal ? acc + Number(obj.estimatedTotal) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total Estimado Cierre:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Diferencia estimado vs Real',
      accessorKey: 'totalVsEstimatedclose',
      id: 'totalVsEstimatedclose',
      columnType: 'numeric',
      size: 300,
      Cell: ({ row }) =>
        !isNaN(Number(row?.original?.totalVsEstimatedclose))
          ? formatStringNumber(Number(row?.original?.totalVsEstimatedclose).toString())
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total:
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && formatStringNumber(sum?.toString())}
            </Box>
          </div>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.totalVsEstimatedclose ? acc + Number(obj.totalVsEstimatedclose) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            Total Diferencias:
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(totalFooter.toString())}
            </Stack>
          </div>
        );
      }
    }
  ]
};

export default GeneralZoneReportEntity;
