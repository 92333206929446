import { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Path } from 'history';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import FireTruckIcon from '@mui/icons-material/FireTruck';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { AuthState } from '../reducers/types';
import { UserTypeValues, modules } from '../types';
import BackButton from '../components/BackButton';
import '../css/modulesPage.css';

interface Props {
  auth: AuthState;
}

const ModulesPage: FunctionComponent<Props> = ({ auth }) => {
  const router = useHistory();

  const buttons: Array<{
    name: string;
    icon: JSX.Element;
    disabled: boolean;
    onlyAdmin: boolean;
    href: Path;
  }> = [
    {
      name: modules.SD.name,
      icon: <RvHookupIcon sx={{ fontSize: 80 }} />,
      disabled: false,
      onlyAdmin: false,
      href: `/mapa-zona-global/${modules.SD.abbreviation}`
    },
    {
      name: modules.PORTEO.name,
      icon: <Inventory2Icon sx={{ fontSize: 80 }} />,
      disabled: false,
      onlyAdmin: true,
      href: '/porteo'
    },
    {
      name: 'Planificación Acarreo',
      icon: <FireTruckIcon sx={{ fontSize: 80 }} />,
      disabled: false,
      onlyAdmin: true,
      href: '/acarreo'
    }
  ];
  return (
    <div className='modules-container'>
      <BackButton route='/' />
      <div className='modules-buttons-section'>
        {buttons.map((b, idx) => {
          if (b.onlyAdmin && !auth.user?.userTypeName.includes(UserTypeValues.admin)) {
            return null;
          }
          return (
            <div className='modules-button-container' key={idx}>
              <Button
                className={`modules-button ${b.disabled ? 'modules-button-disabled' : ''}`}
                disabled={b.disabled}
                onClick={!b.disabled ? () => router.push(b.href) : undefined}>
                {b.icon}
                {b.name}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default connect(({ auth }: AppState) => ({ auth }))(ModulesPage);
