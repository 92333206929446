import { Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import { useHistory, useParams } from 'react-router-dom';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CachedIcon from '@mui/icons-material/Cached';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { UserLevels, UserTypeValues, globalZones } from '../types';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import BackButton from '../components/BackButton';
import Swal from 'sweetalert2';
import '../css/operationsPage.css';

interface ButtonProps {
  onClick: () => void;
  icon: JSX.Element;
  title: string;
  allowedUserTypes: Array<UserTypeValues>;
  allowedUserLevels: Array<UserLevels>;
}

interface Operations {
  estimatedClosing: ButtonProps[];
  administration: ButtonProps[];
  provision: ButtonProps[];
  reclassification: ButtonProps[];
  generalZone: ButtonProps[];
}

const OperationsPage: FunctionComponent<{
  auth: AuthState;
}> = ({ auth }) => {
  const router = useHistory();
  const params = useParams<{
    optionType: string;
    globalZone: string;
    level: string;
    subCategories: string;
    zones: string;
  }>();

  if (
    !params ||
    !params.optionType ||
    !params.globalZone ||
    !params.level ||
    !params.subCategories ||
    (params.globalZone === 'Centro' && !params.zones)
  ) {
    Swal.fire({
      icon: 'error',
      title: 'Ocurrió un error al obtener los parametros. Contacta al administrador'
    });
    router.push('/');
  }

  const optionType = params.optionType;
  const globalZone = params.globalZone;
  const level = params.level;
  const subCategories = params.subCategories.split(',');
  const zones = params.zones ? params.zones.split(',') : [];

  const buttons: Operations = {
    estimatedClosing: [
      {
        title: 'Estimado cierre',
        icon: <CachedIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Estimado Cierre Pendientes',
        icon: <CachedIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre-pendiente/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Estimado Cierre Rechazados',
        icon: <ChangeCircleIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre-rechazado/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Estimado Cierre Global',
        icon: <LibraryBooksIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/estimado-cierre-global/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ],
    administration: [
      {
        title: 'Libro Mayor',
        icon: <LibraryBooksIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(`/SD/gastos/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`)
      },
      {
        title: 'HFM',
        icon: <ChangeCircleIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(`/SD/HFM/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`)
      }
    ],
    provision: [
      {
        title: 'Provisiones',
        icon: <Inventory2Icon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(`/provisiones/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`)
      },
      {
        title: 'Provisiones Pendientes',
        icon: <ChangeCircleIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/provisiones-pendientes/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Provisiones Rechazadas',
        icon: <ChangeCircleIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/provisiones-rechazadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Provisiones Aprobadas',
        icon: <ChangeCircleIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/provisiones-aprobadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ],
    reclassification: [
      {
        title: 'Reclasificaciones',
        icon: <CachedIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Pendientes Reclasificaciones',
        icon: <CachedIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion-pendientes/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Reclasificaciones Rechazados',
        icon: <CachedIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelTwo, UserLevels.levelThree],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion-rechazadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      },
      {
        title: 'Reclasificaciones Aprobadas',
        icon: <ChangeCircleIcon sx={{ fontSize: 60, border: 'none', color: 'white' }} />,
        allowedUserLevels: [],
        allowedUserTypes: [UserTypeValues.admin],
        onClick: () =>
          router.push(
            `/reclasificacion-aprobadas/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ],
    generalZone: [
      {
        title: 'Cierre General Zona',
        icon: <DonutSmallIcon sx={{ fontSize: 60, color: 'white' }} />,
        allowedUserLevels: [UserLevels.levelOne, UserLevels.levelTwo],
        allowedUserTypes: [],
        onClick: () =>
          router.push(
            `/mantenedores/totales-generales/${optionType}/${globalZone}/${level}/${subCategories}/${zones}`
          )
      }
    ]
  };

  const ItemButton: FunctionComponent<{
    onClick: () => void;
    icon: JSX.Element;
    title: string;
  }> = ({ onClick, icon, title }) => {
    return (
      <div className='operations-container-button'>
        <button className='operations-button' onClick={onClick}>
          {icon}
          {title}
        </button>
      </div>
    );
  };

  const checkButtonAccess = (button: ButtonProps) =>
    button.allowedUserTypes.some((uT) => auth.user?.userTypeName.includes(uT)) ||
    button.allowedUserLevels.some((uL) => uL === level);

  return (
    <div className='operations-container'>
      <div>
        <BackButton
          route={
            globalZone !== 'Centro'
              ? `/mapa-zona-global/${optionType}`
              : `/mapa-zona-centro/${optionType}/${globalZone}/${level}/${subCategories}`
          }
        />
        <h2 className='operations-title operations-title-center'>Estimados y cierres del mes</h2>
        <div className='operations-info'>
          <p>
            Zona Global: <span>{globalZone}</span>
          </p>
          {globalZone === 'Centro' && zones.length === 1 ? (
            <p>
              Zona: <span>{zones}</span>
            </p>
          ) : globalZone === 'Centro' && zones.length > 1 ? (
            <button
              onClick={() =>
                Swal.fire({
                  icon: 'info',
                  title: 'Zonas',
                  html: `<p>${zones.join(' - ')}</p>`
                })
              }>
              Mis Zonas
            </button>
          ) : subCategories.length === 1 ? (
            <p>
              Sub Categoria: <span>{subCategories}</span>
            </p>
          ) : (
            <button
              onClick={() =>
                Swal.fire({
                  icon: 'info',
                  title: 'Sub categorias',
                  html: `<p>${subCategories.join(' - ')}</p>`
                })
              }>
              Mis Sub categorias
            </button>
          )}
        </div>
      </div>

      {(buttons.estimatedClosing.some((b) => checkButtonAccess(b)) ||
        buttons.administration.some((b) => checkButtonAccess(b)) ||
        buttons.provision.some((b) => checkButtonAccess(b)) ||
        buttons.reclassification.some((b) => checkButtonAccess(b)) ||
        buttons.generalZone.some((b) => checkButtonAccess(b))) && (
        <div className='operations-body'>
          {buttons.estimatedClosing.some((b) => checkButtonAccess(b)) && (
            <div className='operations-section'>
              {globalZones.some((z) => z === globalZone) && (
                <h2 className='operations-title operations-title-1'>Estimados cierre</h2>
              )}
              <div className='operations-section-button-container'>
                {buttons.estimatedClosing.map((b, idx) => {
                  if (checkButtonAccess(b)) {
                    return (
                      <ItemButton key={idx} onClick={b.onClick} icon={b.icon} title={b.title} />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          )}

          {buttons.administration.some((b) => checkButtonAccess(b)) && (
            <Fragment>
              <hr className='operations-dividing-line' />
              <div className='operations-section'>
                <h2 className='operations-title operations-title-1'>Administrar</h2>
                <div className='operations-section-button-container'>
                  {buttons.administration.map((b, idx) => {
                    if (checkButtonAccess(b)) {
                      return (
                        <ItemButton key={idx} onClick={b.onClick} icon={b.icon} title={b.title} />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </Fragment>
          )}

          {buttons.estimatedClosing.some((b) => checkButtonAccess(b)) &&
            buttons.provision.some((b) => checkButtonAccess(b)) &&
            buttons.reclassification.some((b) => checkButtonAccess(b)) && (
              <Fragment>
                <hr className='operations-dividing-line' />
                <h2 className='operations-title operations-title-1'>Cierre mes</h2>
                <h2 className='operations-title operations-title-2'>Provision</h2>

                <div className='operations-section-button-container'>
                  {buttons.provision.map((b, idx) => {
                    if (checkButtonAccess(b)) {
                      return (
                        <ItemButton key={idx} onClick={b.onClick} icon={b.icon} title={b.title} />
                      );
                    }
                    return null;
                  })}
                </div>
                <h2 className='operations-title operations-title-2'>Reclasificación</h2>
                <div className='operations-section-button-container'>
                  {buttons.reclassification.map((b, idx) => {
                    if (checkButtonAccess(b)) {
                      return (
                        <ItemButton key={idx} onClick={b.onClick} icon={b.icon} title={b.title} />
                      );
                    }
                    return null;
                  })}
                </div>
              </Fragment>
            )}

          {buttons.generalZone.some((b) => checkButtonAccess(b)) && (
            <div className='operations-section'>
              {!auth.user?.userTypeName.includes(UserTypeValues.boss) && (
                <hr className='operations-dividing-line' />
              )}
              <h2 className='operations-title operations-title-2'>General Zona</h2>
              {buttons.generalZone.map((b, idx) => {
                if (checkButtonAccess(b)) {
                  return <ItemButton key={idx} onClick={b.onClick} icon={b.icon} title={b.title} />;
                }
                return null;
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(OperationsPage);
