import { Box, Stack } from '@mui/material';
import { FreightCharges, SimpleObject } from '../types';
import { WebEntity } from './types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';

const FreightChargesEntity: WebEntity<FreightCharges> = {
  name: 'freightChargesQ1',
  endpoint: 'freightCharges',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text'
    },
    {
      header: 'Fecha',
      accessorKey: 'documentDate',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row.original?.documentDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text'
    },
    {
      header: 'Rut',
      accessorKey: 'carrierRut',
      columnType: 'text'
    },
    {
      header: 'Nombre',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Tipo de transportista',
      accessorKey: 'carrierTypeName',
      columnType: 'text'
    },
    {
      header: 'Monto Neto',
      accessorKey: 'netPrice',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.netPrice))
          ? `$ ${formatStringNumber(row.original?.netPrice, 0, 0)}`
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && `$ ${formatStringNumber(sum?.toString(), 0, 0, '.', ',')}`}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: SimpleObject) => (obj.netPrice ? acc + Math.ceil(obj.netPrice) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Stack sx={{ fontSize: 15, color: `${total > 0 ? 'green' : 'red'}` }}>
              $ {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'IVA',
      accessorKey: 'tax',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.tax)) ? `$ ${formatStringNumber(row.original?.tax, 0, 0)}` : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && `$ ${formatStringNumber(sum?.toString(), 0, 0, '.', ',')}`}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: SimpleObject) => (obj.tax ? acc + Number(obj.tax) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Stack sx={{ fontSize: 15, color: `${total > 0 ? 'green' : 'red'}` }}>
              $ {formatStringNumber(total.toString(), 0, 0, '.', ',')}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Monto Bruto',
      accessorKey: 'grossPrice',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.grossPrice))
          ? `$ ${formatStringNumber(row.original?.grossPrice, 0, 0)}`
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {sum && `$ ${formatStringNumber(sum?.toString(), 0, 0, '.', ',')}`}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: SimpleObject) => (obj.grossPrice ? acc + Number(obj.grossPrice) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Stack sx={{ fontSize: 15, color: `${total > 0 ? 'green' : 'red'}` }}>
              $ {formatStringNumber(total.toString(), 0, 0, '.', ',')}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Orden de Compra',
      accessorKey: 'purchaseOrder',
      columnType: 'text'
    }
  ]
};

export const FREIGHT_CHARGES_COLUMNS = {
  purchaseOrder: 'Documento compras',
  documentDate: 'Fecha documento',
  netPrice: 'Precio neto',
  supliersName: 'Nombre de proveedor',
  zonePortingDescription: 'Zona'
};

export default FreightChargesEntity;
