import { FunctionComponent } from 'react';
import NavigationIcon from '@mui/icons-material/Navigation';
import CardAlert, { CardAlertProps } from './CardAlert';
import BackButton from './BackButton';

const GenericTopTableCGFCCU: FunctionComponent<{
  titleTable: string;
  arrowName: string;
  cardAlerts: CardAlertProps[];
  backButtonPathName?: string;
  CustomComponent?: JSX.Element;
}> = ({ titleTable, arrowName, cardAlerts, backButtonPathName, CustomComponent }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'baseline' }}>
    {backButtonPathName && <BackButton route={backButtonPathName} />}
    <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
      <div>
        <h2 className='title-DataTable'>{titleTable}</h2>
        <div
          style={{
            border: '1px solid black',
            padding: 8,
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}>
          <NavigationIcon sx={{ mr: 1 }} />
          <span>{arrowName}</span>
        </div>
      </div>
      {cardAlerts.map((c, idx) => (
        <CardAlert
          key={idx}
          title={c.title}
          icon={c.icon}
          numberInfo={c.numberInfo}
          variant={c.variant}
          detailStyle={c.detailStyle}
        />
      ))}
      {CustomComponent}
    </div>
  </div>
);

export default GenericTopTableCGFCCU;
