import { CSSProperties } from 'react';
import update from '../../img/CloudArrowUp-r.svg';
import datafy from '../../img/Logo.svg';
import logout from '../../img/SignOut-r.svg';
import avatar from '../../img/UserCircle-r.svg';
import edit from '../../img/PencilLine-r.svg';
import filter from '../../img/Funnel-r.svg';
import add from '../../img/Plus-r.svg';
import arrowLeft from '../../img/arrowLeftMenu.png';
import manteiner from '../../img/FolderNotch-d.svg';
import count from '../../img/ListNumbers-r.svg';
import arrow from '../../img/ArrowLineRight-s.svg';
import info from '../../img/Info-r.svg';
import infoWhite from '../../img/Info-rWhite.svg';
import report from '../../img/ClipboardText.svg';
import table from '../../img/Table-r.svg';
import trash from '../../img/Trash-r.svg';
import trashWhite from '../../img/trash_white.png';
import Eye from '../../img/Eye-r.svg';
import duplicate from '../../img/duplicate.png';
import duplicateWhite from '../../img/duplicate_White.png';
import CaretRightIcon from '../../img/CaretRightIcon.svg';
import HouseIcon from '../../img/HouseIcon.svg';
import FilterIcon from '../../img/filter-icon.png';
import pageNotFound from '../../img/pageNotFound.svg';
import ccuTruck from '../../img/ccu-truck.svg';
import truckIcon from '../../img/truck-icon.svg';
import portingTruckImg from '../../img/portingTruckImg.png';
import truckIcon2 from '../../img/truckIcon2.svg';
import calendarIcon from '../../img/calendarIcon.svg';

type Props = {
  icon: string;
  imageStyle?: CSSProperties;
  className?: string;
};

const GenericIcons = (props: Props) => {
  const { icon, imageStyle } = props;

  const iconData = [
    {
      icon: 'update',
      svg: update
    },
    {
      icon: 'datafy',
      svg: datafy
    },
    {
      icon: 'logout',
      svg: logout
    },
    {
      icon: 'mantainer',
      svg: manteiner
    },
    {
      icon: 'count',
      svg: count
    },
    {
      icon: 'arrow',
      svg: arrow
    },
    {
      icon: 'avatar',
      svg: avatar
    },
    {
      icon: 'edit',
      svg: edit
    },
    {
      icon: 'filter',
      svg: filter
    },
    {
      icon: 'add',
      svg: add
    },
    {
      icon: 'arrowLeft',
      svg: arrowLeft
    },
    {
      icon: 'info',
      svg: info
    },
    {
      icon: 'infoWhite',
      svg: infoWhite
    },
    {
      icon: 'report',
      svg: report
    },
    {
      icon: 'table',
      svg: table
    },
    {
      icon: 'trash',
      svg: trash
    },
    {
      icon: 'eye',
      svg: Eye
    },
    {
      icon: 'duplicate',
      svg: duplicate
    },
    {
      icon: 'trashWhite',
      svg: trashWhite
    },
    {
      icon: 'duplicateWhite',
      svg: duplicateWhite
    },
    {
      icon: 'CaretRightIcon',
      svg: CaretRightIcon
    },
    {
      icon: 'HouseIcon',
      svg: HouseIcon
    },
    {
      icon: 'FilterIcon',
      svg: FilterIcon
    },
    {
      icon: 'pageNotFound',
      svg: pageNotFound
    },
    {
      icon: 'ccuTruck',
      svg: ccuTruck
    },
    {
      icon: 'truckIcon',
      svg: truckIcon
    },
    {
      icon: 'portingTruckImg',
      svg: portingTruckImg
    },
    {
      icon: 'truckIcon2',
      svg: truckIcon2
    },
    {
      icon: 'truckIcon2',
      svg: truckIcon2
    },
    {
      icon: 'calendarIcon',
      svg: calendarIcon
    }
  ];

  const findIcon = (icon_: string) => {
    const icon = iconData.find((p) => p.icon === icon_);

    return icon ? icon?.svg : null;
  };

  const SVGIcon = icon ? findIcon(icon) : null;

  return SVGIcon ? (
    <img className={props.className} src={SVGIcon} alt='' style={{ ...imageStyle }} />
  ) : null;
};

export default GenericIcons;
