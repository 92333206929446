import { Box, Button, Input, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ForeignExchange } from '../types';
import {
  dateFormatFn,
  formatStringNumber,
  spanishDayNames,
  spanishMonthNames
} from '../utils/utils';
import { FieldTypes, WebEntity } from './types';
import { errorPopAlert, requestErrorPopAlert } from '../components/PopAlert';
import { useRef, useState } from 'react';
import { getUncatchEndpointData } from '../utils/request';

const PDFfileType = 'application/pdf';

const ForeignExchangeEntity: WebEntity<ForeignExchange> = {
  name: 'foreignExchange',
  endpoint: 'foreignExchange',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Variable'
  },
  customDataActions: {
    editModalTitle: 'Editar Variable'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Mes',
      accessorKey: 'valueDateMonth',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.valueDateMonth
          ? `${spanishMonthNames[new Date(row.original?.valueDateMonth).getUTCMonth()]} ${new Date(
              row.original?.valueDateMonth
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Dia',
      accessorKey: 'valueDateDay',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.valueDateDay
          ? spanishDayNames[new Date(row.original?.valueDateDay).getUTCDay()]
          : ''
    },
    {
      header: 'Fecha',
      accessorKey: 'valueDate',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row.original?.valueDate, 'DD-MM-YYYY', true)
    },
    {
      header: 'Dolar Observado',
      accessorKey: 'usd',
      columnType: 'numeric',
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Box>$ {formatStringNumber(row.original?.usd, 0, 2)}</Box>
        </div>
      ),
      AggregatedCell: ({ row }) => {
        const filteredOriginal = row.subRows
          ?.filter((subRow) => !isNaN(subRow.original?.usd) && Number(subRow.original?.usd) > 0)
          .map((subRow) => subRow.original);

        const sum = filteredOriginal?.reduce((acc, obj) => acc + Number(obj?.usd), 0);

        const average =
          sum && filteredOriginal && filteredOriginal.length > 0
            ? sum / filteredOriginal.length
            : 0;

        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                color: 'success.main',
                fontWeight: 'bold'
              }}>
              AVG: $ {formatStringNumber(average.toString(), 0, 2)}
            </Box>
          </div>
        );
      }
    },
    {
      header: 'UF',
      accessorKey: 'uf',
      columnType: 'numeric',
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Box>$ {formatStringNumber(row.original?.uf, 0, 2)}</Box>
        </div>
      ),
      AggregatedCell: ({ row }) => {
        const filteredOriginal = row.subRows
          ?.filter((subRow) => !isNaN(subRow.original?.uf) && Number(subRow.original?.uf) > 0)
          .map((subRow) => subRow.original);

        const sum = filteredOriginal?.reduce((acc, obj) => acc + Number(obj?.uf), 0);

        const average =
          sum && filteredOriginal && filteredOriginal.length > 0
            ? sum / filteredOriginal.length
            : 0;

        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                color: 'success.main',
                fontWeight: 'bold'
              }}>
              AVG: $ {formatStringNumber(average.toString(), 0, 2)}
            </Box>
          </div>
        );
      }
    },
    {
      header: 'Diesel',
      accessorKey: 'dsl',
      columnType: 'numeric',
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Box>$ {formatStringNumber(row.original?.dsl, 0, 2)}</Box>
        </div>
      ),
      AggregatedCell: ({ row }) => {
        const filteredOriginal = row.subRows
          ?.filter((subRow) => !isNaN(subRow.original?.dsl) && Number(subRow.original?.dsl) > 0)
          .map((subRow) => subRow.original);

        const sum = filteredOriginal?.reduce((acc, obj) => acc + Number(obj?.dsl), 0);

        const average =
          sum && filteredOriginal && filteredOriginal.length > 0
            ? sum / filteredOriginal.length
            : 0;

        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                color: 'success.main',
                fontWeight: 'bold'
              }}>
              AVG: $ {formatStringNumber(average.toString(), 0, 2)}
            </Box>
          </div>
        );
      }
    },
    {
      header: 'PDF',
      accessorKey: 'fileName',
      columnType: 'text',
      Cell: ({ row }) => {
        const targetDateLocal = new Date(row.original.valueDate);
        const targetDateUTC = !isNaN(targetDateLocal.getTime())
          ? new Date(
              `${
                targetDateLocal.getUTCMonth() + 1
              }-${targetDateLocal.getUTCDate()}-${targetDateLocal.getUTCFullYear()}`
            )
          : undefined;

        const [loading, setLoading] = useState<boolean>(false);

        const handleDownload = async () => {
          try {
            setLoading(true);
            const res = await getUncatchEndpointData({
              endpoint: 'foreignExchange/getFile',
              query: { fileName: row.original.fileName }
            });

            const byteCharacters = atob(res);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: PDFfileType });

            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = row.original.fileName;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            setLoading(false);
          } catch (error) {
            console.log(error);
            setLoading(false);
            requestErrorPopAlert(error);
          }
        };

        return targetDateUTC &&
          spanishDayNames[targetDateUTC.getDay()] === 'Jueves' &&
          row.original.fileName ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}>
            <Button variant='outlined' onClick={handleDownload} disabled={loading}>
              <PictureAsPdfIcon />
              {row.original.fileName}
            </Button>
          </div>
        ) : null;
      }
    }
  ],

  fields: [
    {
      name: 'Fecha',
      selector: 'valueDate',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true
    },
    {
      name: 'Diesel',
      selector: 'dsl',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'PDF',
      selector: 'file',
      type: FieldTypes.Custom,
      required: true,
      CustomField: (onChange, value: File, _) => {
        const FileInput: React.FC = () => {
          const [file, setFile] = useState<File | undefined>(value);
          const [error, setError] = useState<string>('');
          const fileInputRef = useRef<HTMLInputElement | null>(null);

          const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target?.files || event.target?.files?.length === 0) {
              setError('No se seleccionó ningún archivo');
              return;
            }

            const selectedFile = event.target.files[0];

            if (selectedFile.type !== PDFfileType) {
              errorPopAlert('Tipo de archivo', 'El Archivo debe ser PDF');
              setError('Solo se permiten archivos PDF.');
              setFile(undefined);
              onChange(undefined);
              return;
            }
            setError('');
            if (selectedFile.size > 1 * 1024 * 1024) {
              errorPopAlert('Tamaño de Archivo', 'El Archivo no puede pesar mas de 1MB');
              setError('Se permiten archivos de hasta 1MB');
              setFile(undefined);
              onChange(undefined);
              return;
            }
            setError('');
            setFile(selectedFile);

            const reader = new FileReader();

            reader.onload = function () {
              if (typeof reader.result === 'string') {
                const base64Data = reader.result.split(',')[1];
                onChange({
                  name: selectedFile.name,
                  type: selectedFile.type,
                  base64Data
                });
              }
            };
            reader.readAsDataURL(selectedFile);
          };

          return (
            <div style={{ display: ' flex', flexDirection: 'column' }}>
              <label htmlFor='upload-button'>
                <Input
                  ref={fileInputRef}
                  id='upload-button'
                  type='file'
                  onChange={handleFileChange}
                />
              </label>
              {file && (
                <Typography variant='body2'>
                  Archivo seleccionado: <b>{file.name}</b>
                </Typography>
              )}
              {error && (
                <Typography variant='body2' color='error'>
                  {error}
                </Typography>
              )}
            </div>
          );
        };
        return <FileInput />;
      }
    }
  ],

  editableFields: [
    {
      name: 'Fecha',
      selector: 'valueDate',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true,
      disabled: true
    },
    {
      name: 'Diesel',
      selector: 'dsl',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'PDF',
      selector: 'file',
      type: FieldTypes.Custom,
      required: true,
      CustomField: (onChange, value: File, _) => {
        const FileInput: React.FC = () => {
          const [file, setFile] = useState<File | undefined>(value);
          const [error, setError] = useState<string>('');
          const fileInputRef = useRef<HTMLInputElement | null>(null);

          const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target?.files || event.target?.files?.length === 0) {
              setError('No se seleccionó ningún archivo');
              return;
            }

            const selectedFile = event.target.files[0];

            if (selectedFile.type !== PDFfileType) {
              errorPopAlert('Tipo de archivo', 'El Archivo debe ser PDF');
              setError('Solo se permiten archivos PDF.');
              setFile(undefined);
              onChange(undefined);
              return;
            }
            setError('');
            if (selectedFile.size > 1 * 1024 * 1024) {
              errorPopAlert('Tamaño de Archivo', 'El Archivo no puede pesar mas de 1MB');
              setError('Se permiten archivos de hasta 1MB');
              setFile(undefined);
              onChange(undefined);
              return;
            }
            setError('');
            setFile(selectedFile);

            const reader = new FileReader();

            reader.onload = function () {
              if (typeof reader.result === 'string') {
                const base64Data = reader.result.split(',')[1];
                onChange({
                  name: selectedFile.name,
                  type: selectedFile.type,
                  base64Data
                });
              }
            };
            reader.readAsDataURL(selectedFile);
          };

          return (
            <div style={{ display: ' flex', flexDirection: 'column' }}>
              <label htmlFor='upload-button'>
                <Input
                  ref={fileInputRef}
                  id='upload-button'
                  type='file'
                  onChange={handleFileChange}
                />
              </label>
              {file && (
                <Typography variant='body2'>
                  Archivo seleccionado: <b>{file.name}</b>
                </Typography>
              )}
              {error && (
                <Typography variant='body2' color='error'>
                  {error}
                </Typography>
              )}
            </div>
          );
        };
        return <FileInput />;
      }
    }
  ]
};

export default ForeignExchangeEntity;
