import { User } from '../types';
import { booleanFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const UserEntity: WebEntity<User> = {
  name: 'user',
  endpoint: 'users',
  referenceColumn: 'userUsername',
  customButtonNames: {
    addButtonName: 'Agregar Usuario'
  },
  customDataActions: {
    editModalTitle: 'Editar Usuario'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      size: 100
    },
    {
      header: 'Nombre',
      accessorKey: 'name',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Nombre Usuario',
      accessorKey: 'userUsername',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Email',
      accessorKey: 'userEmail',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 200,
      Cell: ({ row }) =>
        row.original.zoneGlobalDescrp ? (
          <span style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
            {row.original.zoneGlobalDescrp.join(', ')}
          </span>
        ) : (
          ''
        )
    },
    {
      header: 'Nivel de Usuario',
      accessorKey: 'userLevels',
      size: 200,
      columnType: 'text',
      Cell: ({ row }) =>
        row.original.userLevels ? (
          <span style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
            {row.original.userLevels.join(', ')}
          </span>
        ) : (
          ''
        )
    },
    {
      header: '¿Activo?',
      accessorKey: 'userIsActive',
      columnType: 'boolean',
      accessorFn: (row) => booleanFormatFn(row.userIsActive),
      size: 150
    },
    {
      header: '¿Recibe Notificaciones?',
      accessorKey: 'isNotifiable',
      columnType: 'boolean',
      accessorFn: (row) => booleanFormatFn(row.isNotifiable),
      size: 230
    },
    {
      header: 'Es Admin',
      accessorKey: 'isAdmin',
      columnType: 'boolean',
      accessorFn: (row) => booleanFormatFn(row.isAdmin),
      size: 180
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'name',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'userUsername',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Email',
      selector: 'userEmail',
      type: FieldTypes.Email,
      required: true
    },
    {
      name: 'Activo',
      selector: 'userIsActive',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Clave',
      selector: 'userPassword',
      type: FieldTypes.Password,
      required: true
    },
    {
      name: '¿Recibe Notificaciones?',
      selector: 'isNotifiable',
      required: true,
      type: FieldTypes.Boolean
    },
    {
      name: '¿Asignar como Administrador?',
      selector: 'isAdmin',
      required: true,
      type: FieldTypes.Boolean
    }
  ],

  editableFields: [
    {
      name: 'Nombre',
      selector: 'name',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'userUsername',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Email',
      selector: 'userEmail',
      type: FieldTypes.Email,
      required: true
    },
    {
      name: 'Activo',
      selector: 'userIsActive',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Clave',
      selector: 'userPassword',
      type: FieldTypes.Password,
      required: true
    },
    {
      name: '¿Recibe Notificaciones?',
      selector: 'isNotifiable',
      required: true,
      type: FieldTypes.Boolean
    },
    {
      name: '¿Asignar como Administrador?',
      selector: 'isAdmin',
      required: true,
      type: FieldTypes.Boolean
    }
  ]
};

export const USERS_COLUMNS = {
  name: 'Nombre',
  userUsername: 'Nombre Usuario',
  userEmail: 'Email',
  userPassword: 'Clave'
};

export default UserEntity;
