import { Box } from '@mui/material';
import { ShipmentAuditingCarriage, PostgresInterval } from '../types';
import { dateFormatFn, postgresIntervalFormat } from '../utils/utils';
import { WebEntity } from './types';
import { PaymentAuthorizationButton } from '../components/SecondaryButton';

const ShipmentAuditingCarriageEntity: WebEntity<ShipmentAuditingCarriage> = {
  name: 'shipmentAuditingCarriageQ1',
  endpoint: 'shipmentCarriage/shipmentAuditingCarriage',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'ID',
      accessorKey: 'shipmentAuditingCarriageId',
      columnType: 'text',
      size: 100,
      omitExport: true
    },
    {
      header: 'Creado el',
      accessorKey: 'createdDateSource',
      columnType: 'dateTimeUTC',
      size: 180,
      Cell: ({ row }) => dateFormatFn(row.original?.createdDateSource, 'DD/MM/YYYY HH:mm:ss', true),
      AggregatedCell: ({ row }) => {
        const timeDifferenceOca: PostgresInterval =
          row.subRows && row.subRows.length > 0
            ? row.subRows[0].original?.timeDifferenceOca
            : undefined;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
            <Box sx={{ fontSize: 15, fontWeight: 'bold' }}>Diferencia:</Box>{' '}
            <Box sx={{ fontSize: 15, fontWeight: 'bold' }}>
              {postgresIntervalFormat(timeDifferenceOca)}
            </Box>
          </Box>
        );
      }
    },
    {
      header: 'Acción',
      accessorKey: 'action',
      columnType: 'text',
      size: 450,
      omitExport: true,
      Cell: ({ row }) => {
        const shipmentAuditingCarriageId = row.original?.shipmentAuditingCarriageId;

        const authorizedForPay = row.original?.authorizedForPay;

        const endDateCarriageReport = row.original?.endDateCarriageReport;

        return row.original?.isAuditing ? (
          <PaymentAuthorizationButton
            shipmentAuditingCarriageId={shipmentAuditingCarriageId}
            authorizedForPay={authorizedForPay}
            endDateCarriageReport={endDateCarriageReport}
          />
        ) : null;
      },
      AggregatedCell: ({ row }) => {
        const authorizedForPay =
          row.subRows && row.subRows.length > 0
            ? row.subRows[0].original?.authorizedForPay
            : undefined;

        const authorizedBy =
          row.subRows && row.subRows.length > 0 ? row.subRows[0].original?.authorizedBy : undefined;

        const authorized = authorizedForPay === true;
        const notAuthorized = authorizedForPay === false;

        const color = authorized ? 'green' : notAuthorized ? 'red' : undefined;
        const text = authorized ? 'Autorizado' : notAuthorized ? 'No autorizado' : '';

        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
            {text && (
              <>
                <Box sx={{ fontSize: 15, fontWeight: 'bold' }}>Estado:</Box>
                <Box sx={{ fontSize: 15, fontWeight: 'bold', color }}>{text}</Box>
              </>
            )}
            {authorizedBy && (
              <>
                <Box sx={{ fontSize: 15, fontWeight: 'bold' }}>Actualizado por:</Box>
                <Box sx={{ fontSize: 15, fontWeight: 'bold', color }}>{authorizedBy}</Box>
              </>
            )}
          </Box>
        );
      }
    },
    {
      header: 'N° Transporte',
      accessorKey: 'shipmentNumber',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Clase de Transporte',
      accessorKey: 'shipmentClassCarriageIdCcu',
      columnType: 'text',
      size: 240
    },
    {
      header: 'Id.1 / OCA',
      accessorKey: 'oca',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Creado Por',
      accessorKey: 'createdBy',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Patente/Matrícula Vehículo',
      accessorKey: 'vehiclePatent',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Patente/Matrícula Rampla',
      accessorKey: 'trailerPatent',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Fin act.transporte',
      accessorKey: 'endDateCarriageReport',
      columnType: 'dateUTC',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row.original?.createdDateSource, 'DD/MM/YYYY', true)
    },
    {
      header: 'Ruta p.transporte',
      accessorKey: 'timeRouteIdCcu',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Denom.ruta',
      accessorKey: 'route',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Tiempo',
      accessorKey: 'time',
      columnType: 'text',
      size: 220,
      valueToExport: (row) => postgresIntervalFormat(row?.time),
      Cell: ({ row }) => postgresIntervalFormat(row.original?.time)
    },
    {
      header: 'Nombre agente servicios transp.',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 350
    },
    {
      header: 'Tipo Envase/Carga',
      accessorKey: 'chargePackingType',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Creado Por',
      accessorKey: 'modifiedBy',
      columnType: 'text',
      size: 200
    }
  ]
};

export default ShipmentAuditingCarriageEntity;
