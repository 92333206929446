import { ZonePorting } from '../types';
import { FieldTypes, WebEntity } from './types';

const ZonePortingEntity: WebEntity<ZonePorting> = {
  name: 'zonePorting',
  endpoint: 'zonePorting',
  referenceColumn: 'zonePortingDescription',
  customButtonNames: {
    addButtonName: 'Agregar Zona'
  },
  customDataActions: {
    addModalTitle: 'Agregar Zona',
    editModalTitle: 'Editar Zona'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Zona',
      selector: 'zonePortingDescription',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Zona',
      selector: 'zonePortingDescription',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default ZonePortingEntity;
