import { Box } from '@mui/material';
import { Carrier } from '../types';
import { booleanFormatFn, formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const CarrierEntity: WebEntity<Carrier> = {
  name: 'carrier',
  endpoint: 'carrier',
  referenceColumn: 'carrierName',
  customButtonNames: {
    addButtonName: 'Agregar Transportista'
  },
  customDataActions: {
    editModalTitle: 'Editar Transportista'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Rut',
      accessorKey: 'carrierRut',
      columnType: 'text'
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text'
    },
    {
      header: 'Tipo EETT',
      accessorKey: 'managementTypeName',
      columnType: 'text'
    },
    {
      header: 'Cantidad de Bahías',
      accessorKey: 'truckBayQuantity',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.truckBayQuantity !== undefined && row?.original?.truckBayQuantity !== null
          ? formatStringNumber(row?.original?.truckBayQuantity, 0, 0)
          : ''
    },
    {
      header: '¿Omitir Flete Acarreo?',
      accessorKey: 'omitOnShipmentCarriage',
      columnType: 'boolean',
      Cell: ({ row }) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          {booleanFormatFn(row?.original?.omitOnShipmentCarriage)}
        </Box>
      )
    },
    {
      header: 'Tipo Para Resumen Acarreo',
      accessorKey: 'carrierTypeCarriageName',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'EETT',
      selector: 'carrierName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Rut',
      selector: 'carrierRut',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'BP',
      selector: 'bp',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo EETT',
      selector: 'managementTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Tipo EETT',
        endpoint: 'managementType',
        select: '_id',
        show: 'managementTypeName'
      }
    },
    {
      name: 'Cantidad de Bahías',
      selector: 'truckBayQuantity',
      type: FieldTypes.Text
    },
    {
      name: '¿Omitir Flete Acarreo?',
      selector: 'omitOnShipmentCarriage',
      type: FieldTypes.Boolean
    },
    {
      name: 'Tipo Para Resumen Acarreo',
      selector: 'carrierTypeCarriageId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Tipo Para Resumen Acarreo',
        endpoint: 'carrierRouteCarriage/carrierTypeCarriage',
        select: '_id',
        show: 'carrierTypeCarriageName'
      }
    }
  ],

  editableFields: [
    {
      name: 'EETT',
      selector: 'carrierName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Rut',
      selector: 'carrierRut',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'BP',
      selector: 'bp',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo EETT',
      selector: 'managementTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Tipo EETT',
        endpoint: 'managementType',
        select: '_id',
        show: 'managementTypeName'
      }
    },
    {
      name: 'Cantidad de Bahías',
      selector: 'truckBayQuantity',
      type: FieldTypes.Text
    },
    {
      name: '¿Omitir Flete Acarreo?',
      selector: 'omitOnShipmentCarriage',
      type: FieldTypes.Boolean
    },
    {
      name: 'Tipo Para Resumen Acarreo',
      selector: 'carrierTypeCarriageId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Tipo Para Resumen Acarreo',
        endpoint: 'carrierRouteCarriage/carrierTypeCarriage',
        select: '_id',
        show: 'carrierTypeCarriageName'
      }
    }
  ]
};

export const CARRIER_COLUMNS = {
  carrierName: 'EETT',
  carrierRut: 'Rut',
  bp: 'BP',
  managementTypeName: 'Tipo EETT'
};

export default CarrierEntity;
