import { ManagementType } from '../types';
import { WebEntity } from './types';

const ManagementTypeEntity: WebEntity<ManagementType> = {
  name: 'managementType',
  endpoint: 'managementType',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Tipo EETT',
      accessorKey: 'managementTypeName',
      columnType: 'text'
    }
  ]
};
export default ManagementTypeEntity;
