import { CarrierType } from '../types';
import { FieldTypes, WebEntity } from './types';

const CarrierTypeEntity: WebEntity<CarrierType> = {
  name: 'carrierType',
  endpoint: 'carrierType',
  referenceColumn: 'carrierTypeName',
  customButtonNames: {
    addButtonName: 'Agregar Tipo de Transportista'
  },
  customDataActions: {
    editModalTitle: 'Editar Tipo de Transportista'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'Tipo de transportista',
      accessorKey: 'carrierTypeName',
      columnType: 'text'
    }
  ],
  fields: [
    {
      name: 'Tipo de transportista',
      selector: 'carrierTypeName',
      type: FieldTypes.Text,
      required: true
    }
  ],
  editableFields: [
    {
      name: 'Tipo de transportista',
      selector: 'carrierTypeName',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default CarrierTypeEntity;
