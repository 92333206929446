import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { utils, writeFile } from 'xlsx-js-style';

export type ReportUENExportDataType = {
  carrierRut: string;
  carrierName: string;
  netAmount: number;
  grossAmount: number;
  gloss: string;
};

const columnNames = ['RUT', 'RAZÓN SOCIAL', 'NETO', 'BRUTO', 'GLOSA'];
const selectors = ['carrierRut', 'carrierName', 'netAmount', 'grossAmount', 'gloss'];

const headerStyle = {
  fill: {
    fgColor: { rgb: '006600' }
  },
  font: {
    color: { rgb: 'FFFFFF' }
  }
};

const borderStyle = {
  border: {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } }
  }
};

const ReportUENExport: FunctionComponent<{
  month: string;
  year: string;
  data: Array<ReportUENExportDataType>;
}> = ({ month, year, data }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const workbook = utils.book_new();
      const worksheet = utils.aoa_to_sheet([]);

      let startRow = 2;

      data.forEach((row) => {
        const headerIndex = startRow + 1;
        const dataRowIndex = headerIndex + 1;
        const separationBetweenTables = dataRowIndex + 1;

        const dataHeader = columnNames.map((header) => ({
          v: header,
          t: 't',
          s: { ...borderStyle, ...headerStyle }
        }));
        utils.sheet_add_aoa(worksheet, [dataHeader], {
          origin: `B${headerIndex}`
        });

        const dataRow = selectors.map((selector) => {
          const value = row[selector as keyof ReportUENExportDataType];
          if (typeof value === 'number') {
            return { v: value, t: 'n', z: '#,##0', s: { ...borderStyle } };
          }
          return {
            v: value,
            t: 't',
            s: { ...borderStyle }
          };
        });

        utils.sheet_add_aoa(worksheet, [dataRow], {
          origin: `B${dataRowIndex}`
        });

        utils.sheet_add_aoa(worksheet, [], {
          origin: `B${separationBetweenTables}`
        });

        startRow = separationBetweenTables;
      });

      const totalNetAmount = data.reduce((acc, obj) => acc + obj.netAmount, 0);

      utils.sheet_add_aoa(worksheet, [[{ v: totalNetAmount, t: 'n', z: '#,##0' }]], {
        origin: `D${startRow + 1}`
      });

      worksheet['!cols'] = [
        { wch: 10 }, // column width A
        { wch: 20 }, // column width B
        { wch: 30 }, // column width C
        { wch: 15 }, // column width D
        { wch: 15 }, // column width E
        { wch: 45 } // column width F
      ];

      utils.book_append_sheet(workbook, worksheet, 'Datos');
      writeFile(workbook, `Cobros_UEN_${month}_${year}.xlsx`);
      setLoading(false);
    }, 500);
  };

  return (
    <Button
      onClick={handleExport}
      color='warning'
      variant='contained'
      disabled={loading || data.length === 0}>
      <FileDownloadOutlinedIcon />
      Reporte
    </Button>
  );
};

export default ReportUENExport;
