import { Box } from '@mui/material';
import { BudgetCarriage, BudgetTypeCarriageNames } from '../types';
import { WebEntity } from './types';
import { formatStringNumber } from '../utils/utils';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const BudgetCarriageEntity: WebEntity<BudgetCarriage> = {
  name: 'budgetCarriageIngresoAcarreo',
  endpoint: 'budgetCarriage',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Enero',
      accessorKey: 'january',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.january ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.january, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.january ? acc + Number(obj.january) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'february',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.february ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.february, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.february ? acc + Number(obj.february) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'march',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.march ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.march, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.march ? acc + Number(obj.march) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Abril',
      accessorKey: 'april',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.april ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.april, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.april ? acc + Number(obj.april) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'may',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.may ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.may, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.may ? acc + Number(obj.may) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Junio',
      accessorKey: 'june',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.june ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.june, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.june ? acc + Number(obj.june) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Julio',
      accessorKey: 'july',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.july ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.july, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.july ? acc + Number(obj.july) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'august',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.august ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.august, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.august ? acc + Number(obj.august) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'september',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.september ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.september, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.september ? acc + Number(obj.september) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'october',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.october ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.october, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.october ? acc + Number(obj.october) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'november',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.november ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.november, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.november ? acc + Number(obj.november) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'december',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.december ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.december, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.december ? acc + Number(obj.december) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const decimal = row?.original?.type === BudgetTypeCarriageNames.volumenRepro ? 2 : 0;
        return row?.original?.total ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.total, 0, decimal)}
          </Box>
        ) : (
          ''
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: BudgetCarriage) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    }
  ]
};

export const BUDGET_CARRIAGE_COLUMNS = {
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre'
};

export default BudgetCarriageEntity;
