import { Box, Stack } from '@mui/material';
import { ContingencyWithholding } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';
import moment from 'moment';

const ContingencyWithholdingEntity: WebEntity<ContingencyWithholding> = {
  name: 'contingencyWithholding',
  endpoint: 'contingencyWithholding',
  referenceColumn: 'equivalentBoxDate',

  tableColumns: [
    {
      header: 'Fecha',
      accessorKey: 'date',
      columnType: 'dateUTC',
      Cell: ({ row }) => {
        const date = row.original?.date ? moment(row.original?.date).utc() : null;

        const monthName = date ? spanishMonthNames[date.month()] : '';
        return monthName;
      }
    },
    {
      header: 'Glosa',
      accessorKey: 'freightGloss',
      columnType: 'text'
    },
    {
      header: 'Retención',
      accessorKey: 'contingencyWithholding',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row.original?.contingencyWithholding ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                color:
                  !isNaN(row.original?.contingencyWithholding) &&
                  Number(row.original?.contingencyWithholding) > 0
                    ? 'success.main'
                    : 'error.main'
              }}>
              {formatStringNumber(row.original?.contingencyWithholding, 0, 0)}
            </Box>
          </div>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                color: !isNaN(Number(sum)) && Number(sum) > 0 ? 'success.main' : 'error.main',
                fontWeight: 'bold'
              }}>
              {sum && formatStringNumber(sum?.toString(), 0, 0)}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ContingencyWithholding) =>
            obj.contingencyWithholding ? acc + Number(obj.contingencyWithholding) : acc,
          0
        );
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Stack sx={{ fontSize: 15, color: total > 0 ? 'green' : 'red' }}>
              Total: {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    }
  ]
};

export default ContingencyWithholdingEntity;
