import { Input } from 'reactstrap';
import { PostgresInterval, TimeRoute } from '../types';
import { FieldTypes, WebEntity } from './types';
import { useState } from 'react';
import { postgresIntervalFormat } from '../utils/utils';

const TimeRouteEntity: WebEntity<TimeRoute> = {
  name: 'timeRoute',
  endpoint: 'timeRoute',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Tiempo de Ruta'
  },
  customDataActions: {
    editModalTitle: 'Editar Tiempo de Ruta'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text'
    },
    {
      header: 'Ruta p.transporte',
      accessorKey: 'timeRouteIdCcu',
      columnType: 'text'
    },
    {
      header: 'Ruta',
      accessorKey: 'route',
      columnType: 'text'
    },
    {
      header: 'Tiempo',
      accessorKey: 'time',
      columnType: 'text',
      valueToExport: (row) => postgresIntervalFormat(row?.time),
      Cell: ({ row }) => postgresIntervalFormat(row.original?.time)
    }
  ],

  fields: [
    {
      name: 'Ruta p.transporte',
      selector: 'timeRouteIdCcu',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Ruta',
      selector: 'route',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tiempo',
      selector: 'time',
      type: FieldTypes.Custom,
      required: true,
      CustomField: (onChange, value: PostgresInterval, _) => {
        const TimeForm: React.FC = () => {
          const [hours, setHours] = useState<number>(value.hours ? value.hours : 0);
          const [minutes, setMinutes] = useState<number>(value.minutes ? value.minutes : 0);
          const [seconds, setseconds] = useState<number>(value.seconds ? value.seconds : 0);
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Input
                name='hours'
                type='number'
                placeholder='Horas'
                value={hours}
                min={0}
                max={23}
                onChange={(e) => {
                  onChange({ hours: Number(e.target.value), minutes, seconds });
                  return setHours(Number(e.target.value));
                }}
              />
              <Input
                name='minutes'
                type='number'
                placeholder='Minutos'
                value={minutes}
                min={0}
                max={59}
                onChange={(e) => {
                  onChange({ hours, minutes: Number(e.target.value), seconds });
                  return setMinutes(Number(e.target.value));
                }}
              />
              <Input
                name='seconds'
                type='number'
                placeholder='Segundos'
                value={seconds}
                min={0}
                max={59}
                onChange={(e) => {
                  onChange({ hours, minutes, seconds: Number(e.target.value) });
                  return setseconds(Number(e.target.value));
                }}
              />
            </div>
          );
        };
        return <TimeForm />;
      }
    }
  ],

  editableFields: [
    {
      name: 'Ruta p.transporte',
      selector: 'timeRouteIdCcu',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Ruta',
      selector: 'route',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tiempo',
      selector: 'time',
      type: FieldTypes.Custom,
      required: true,
      CustomField: (onChange, value: PostgresInterval, _) => {
        const TimeForm: React.FC = () => {
          const [hours, setHours] = useState<number>(value.hours ? value.hours : 0);
          const [minutes, setMinutes] = useState<number>(value.minutes ? value.minutes : 0);
          const [seconds, setseconds] = useState<number>(value.seconds ? value.seconds : 0);
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Input
                name='hours'
                type='number'
                placeholder='Horas'
                value={hours}
                min={0}
                max={23}
                onChange={(e) => {
                  onChange({ hours: Number(e.target.value), minutes, seconds });
                  return setHours(Number(e.target.value));
                }}
              />
              <Input
                name='minutes'
                type='number'
                placeholder='Minutos'
                value={minutes}
                min={0}
                max={59}
                onChange={(e) => {
                  onChange({ hours, minutes: Number(e.target.value), seconds });
                  return setMinutes(Number(e.target.value));
                }}
              />
              <Input
                name='seconds'
                type='number'
                placeholder='Segundos'
                value={seconds}
                min={0}
                max={59}
                onChange={(e) => {
                  onChange({ hours, minutes, seconds: Number(e.target.value) });
                  return setseconds(Number(e.target.value));
                }}
              />
            </div>
          );
        };
        return <TimeForm />;
      }
    }
  ]
};

export default TimeRouteEntity;
