import { Box } from '@mui/material';
import { ContingencyWithholdingInconsistencies } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const ContingencyWithholdingInconsistenciesEntity: WebEntity<ContingencyWithholdingInconsistencies> =
  {
    name: 'contingencyWithholdingInconsistencies',
    endpoint: 'contingencyWithholding/inconsistencies',
    referenceColumn: '_id',
    tableColumns: [
      {
        header: 'Fecha',
        accessorKey: 'accountingDate',
        columnType: 'dateUTC',
        size: 200,
        Cell: ({ row }) =>
          row.original?.accountingDate
            ? `${
                spanishMonthNames[new Date(row.original?.accountingDate).getUTCMonth()]
              } ${new Date(row.original?.accountingDate).getUTCFullYear()}`
            : ''
      },
      {
        header: 'Glosa',
        accessorKey: 'freightGloss',
        columnType: 'text',
        size: 200
      },
      {
        header: 'BP',
        accessorKey: 'bp',
        columnType: 'text',
        size: 200
      },
      {
        header: 'EETT',
        accessorKey: 'carrierName',
        columnType: 'text',
        size: 200
      },
      {
        header: 'Valor del Mayor',
        accessorKey: 'majorValue',
        columnType: 'numeric',
        size: 200,
        Cell: ({ row }) =>
          row.original?.majorValue ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              {formatStringNumber(row.original.majorValue, 0, 2)}
            </div>
          ) : (
            ''
          )
      },
      {
        header: 'Valor Retención',
        accessorKey: 'equivalentBoxValue',
        columnType: 'numeric',
        size: 200,
        Cell: ({ row }) =>
          row.original?.equivalentBoxValue ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              {formatStringNumber(row.original.equivalentBoxValue, 0, 2)}
            </div>
          ) : (
            ''
          )
      },
      {
        header: 'Diferencia',
        accessorKey: 'difference',
        columnType: 'numeric',
        size: 200,
        Cell: ({ row }) =>
          row.original?.difference ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <Box
                sx={{
                  color:
                    !isNaN(row.original?.difference) && Number(row.original?.difference) === 0
                      ? 'success.main'
                      : 'error.main'
                }}>
                {formatStringNumber(row.original.difference, 0, 2)}
              </Box>
            </div>
          ) : (
            ''
          )
      }
    ]
  };

export default ContingencyWithholdingInconsistenciesEntity;
