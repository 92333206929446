import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import GenericIcons from '../components/generics/GenericIcons';
import { Button } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import '../css/notFoundPage.css';

const NotFoundPage: FunctionComponent = () => {
  const router = useHistory();
  return (
    <div className='not-found-page-container'>
      <GenericIcons icon='pageNotFound' className='page-not-found-img' />
      <Button onClick={() => router.push('/')} variant='contained' color='info'>
        <KeyboardReturnIcon />
        Home
      </Button>
    </div>
  );
};

export default NotFoundPage;
