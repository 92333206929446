import { Box, Stack } from '@mui/material';
import { FareDifference, SimpleObject } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const FareDifferenceEntity: WebEntity<FareDifference> = {
  name: 'fareDifference',
  endpoint: 'fareDifference',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text'
    },
    {
      header: 'Transportista',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Tarifa',
      accessorKey: 'tariffTotal',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.tariffTotal))
          ? `$ ${formatStringNumber(row.original?.tariffTotal, 0, 2, '.', ',')}`
          : ''
    },
    {
      header: 'Cajas Eq.',
      accessorKey: 'boxQuantity',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.boxQuantity))
          ? formatStringNumber(row.original?.boxQuantity, 0, 0, '.', ',')
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {!isNaN(Number(sum)) && formatStringNumber((sum || '').toString(), 0, 0, '.', ',')}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: SimpleObject) =>
            obj.boxQuantity ? acc + Number(obj.boxQuantity) : acc,
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Stack sx={{ fontSize: 15, color: `${total > 0 ? 'green' : 'red'}` }}>
              {formatStringNumber(total.toString(), 0, 0, '.', ',')}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.total))
          ? `$ ${formatStringNumber(row.original?.total, 0, 0, '.', ',')}`
          : '',
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {!isNaN(Number(sum)) &&
                `$ ${formatStringNumber((sum || '').toString(), 0, 0, '.', ',')}`}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: SimpleObject) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Stack sx={{ fontSize: 15, color: `${total > 0 ? 'green' : 'red'}` }}>
              $ {formatStringNumber(total.toString(), 0, 0, '.', ',')}
            </Stack>
          </div>
        );
      }
    }
  ]
};

export default FareDifferenceEntity;
