import { ManagementTypeNames, Tariff } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';

const updateDependency =
  (fieldName: string, fieldToUpdate: 'dateValidFrom' | 'dateValidTo'): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);

      const fieldValueToUpdate = getFieldValue(fieldToUpdate);

      const fieldValueDateFormat = new Date(fieldValue);
      const fieldValueToUpdateDateFormat = new Date(fieldValueToUpdate);

      const checkIfIsStart = () => fieldName === 'dateValidFrom';
      const checkIfIsEnd = () => fieldName === 'dateValidTo';
      const checkIfIsMajor = () => fieldValueDateFormat > fieldValueToUpdateDateFormat;
      const checkIfIsMinus = () => fieldValueDateFormat < fieldValueToUpdateDateFormat;

      let newValueToUpdate = fieldValueToUpdate;

      if (fieldValue && fieldValueToUpdate) {
        if ((checkIfIsEnd() && checkIfIsMinus()) || (!checkIfIsEnd() && checkIfIsMajor())) {
          newValueToUpdate = fieldValue;
        }
      } else if (
        (!fieldValue && fieldValueToUpdate && checkIfIsStart()) ||
        (fieldValue && !fieldValueToUpdate && checkIfIsEnd())
      ) {
        newValueToUpdate = fieldValue;
      }

      updateFieldValue(fieldToUpdate, newValueToUpdate);
      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const TariffEntity: WebEntity<Tariff> = {
  name: 'tariff',
  endpoint: 'tariff',
  referenceColumn: '_id',
  customButtonNames: { addButtonName: 'Agregar Tarifa' },
  customDataActions: {
    editModalTitle: 'Editar Tarifa'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 150
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Tipo EETT',
      accessorKey: 'carrierTypeName',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Tarifa',
      accessorKey: 'tariff',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.tariff ? formatStringNumber(row?.original?.tariff, 0, 2, '.', ',') : '',
      size: 130
    },
    {
      header: 'Retencion por contigencia TCCU',
      accessorKey: 'contingency',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.contingency
          ? formatStringNumber(row?.original?.contingency, 0, 2, '.', ',')
          : '',
      size: 320
    },
    {
      header: 'Total',
      accessorKey: 'tariffTotal',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.tariffTotal
          ? formatStringNumber(row?.original?.tariffTotal, 0, 2, '.', ',')
          : '',
      size: 120
    },
    {
      header: 'Retencion por contigencia EETT',
      accessorKey: 'contingencyEett',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.contingencyEett
          ? formatStringNumber(row?.original?.contingencyEett, 0, 2)
          : '',
      size: 320
    },
    {
      header: 'Retencion Total',
      accessorKey: 'totalContingency',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.totalContingency
          ? formatStringNumber(row?.original?.totalContingency, 0, 2)
          : '',
      size: 210
    },
    {
      header: 'Valida Desde',
      accessorKey: 'dateValidFrom',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original?.dateValidFrom, 'DD-MM-YYYY', true)
    },
    {
      header: 'Valida Hasta',
      accessorKey: 'dateValidTo',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original?.dateValidTo, 'DD-MM-YYYY', true)
    }
  ],

  fields: [
    {
      name: 'Transportista',
      selector: 'carrierId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Transportista',
        endpoint: `carrier/managementType/${ManagementTypeNames.porteo}`,
        select: '_id',
        show: 'carrierName'
      }
    },
    {
      name: 'Zona',
      selector: 'zonePortingDescription',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'zonePortingDescription',
        endpoint: 'zonePorting',
        select: 'zonePortingDescription',
        show: 'zonePortingDescription'
      }
    },
    {
      name: 'Tipo EETT',
      selector: 'carrierTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'carrierTypeId',
        endpoint: 'carrierType',
        select: '_id',
        show: 'carrierTypeName'
      }
    },
    {
      name: 'Tarifa',
      selector: 'tariff',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Retencion por contigencia TCCU',
      selector: 'contingency',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Retencion por contigencia EETT',
      selector: 'contingencyEett',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Valida Desde',
      selector: 'dateValidFrom',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true,
      updateDependency: updateDependency('dateValidFrom', 'dateValidTo')
    }
  ],

  editableFields: [
    {
      name: 'Transportista',
      selector: 'carrierId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Transportista',
        endpoint: `carrier/managementType/${ManagementTypeNames.porteo}`,
        select: '_id',
        show: 'carrierName'
      }
    },
    {
      name: 'Zona',
      selector: 'zonePortingDescription',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'zonePortingDescription',
        endpoint: 'zonePorting',
        select: 'zonePortingDescription',
        show: 'zonePortingDescription'
      }
    },
    {
      name: 'Tipo EETT',
      selector: 'carrierTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'carrierTypeId',
        endpoint: 'carrierType',
        select: '_id',
        show: 'carrierTypeName'
      }
    },
    {
      name: 'Tarifa',
      selector: 'tariff',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Retencion por contigencia TCCU',
      selector: 'contingency',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Retencion por contigencia EETT',
      selector: 'contingencyEett',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Valida Desde',
      selector: 'dateValidFrom',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true,
      updateDependency: updateDependency('dateValidFrom', 'dateValidTo')
    },
    {
      name: 'Valida Hasta',
      selector: 'dateValidTo',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true,
      updateDependency: updateDependency('dateValidTo', 'dateValidFrom')
    }
  ]
};

export const TARIFF_COLUMNS = {
  zonePortingDescription: 'Zona Entrega',
  carrierName: 'EETT',
  bp: 'BP',
  carrierTypeName: 'Tipo EETT',
  tariff: 'Tarifa',
  contingency: 'Retencion por contigencia TCCU',
  contingencyEett: 'Retencion por contigencia EETT',
  dateValidFrom: 'Fecha Desde'
};

export default TariffEntity;
