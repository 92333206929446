import { Stack } from '@mui/material';
import { ContractorTariff } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const ContractorTariffEntity: WebEntity<ContractorTariff> = {
  name: 'contractorTariff',
  endpoint: 'contractorTariff',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Fecha',
      accessorKey: 'tariffDate',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.tariffDate
          ? `${spanishMonthNames[new Date(row.original?.tariffDate).getUTCMonth()]} ${new Date(
              row.original?.tariffDate
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text'
    },
    {
      header: 'Transportista',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Tarifa',
      accessorKey: 'tariff',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.tariff, 0, 2),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ContractorTariff) => (obj.tariff ? acc + Number(obj.tariff) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Cajas Equivalentes',
      accessorKey: 'boxQuantity',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.boxQuantity, 0, 2),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ContractorTariff) =>
            obj.boxQuantity ? acc + Number(obj.boxQuantity) : acc,
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.total, 0, 2),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ContractorTariff) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    }
  ]
};

export const CONTRACTOR_TARIFF_COLUMNS = {
  zonePortingDescription: 'ZONA',
  bp: 'BP',
  tariff: 'TARIFA',
  boxQuantity: 'CAJAS EQ.'
};

export default ContractorTariffEntity;
