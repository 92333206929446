import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import mapChile from '../img/mapsChile.gif';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { UserTypeValues, modules, ModuleOption, UserLevels } from '../types';
import BackButton from '../components/BackButton';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { getUncatchEndpointData } from '../utils/request';
import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/globalZoneMapPage.css';

interface IProps {
  auth: AuthState;
}

const GlobalZoneMapPage: FunctionComponent<IProps> = ({ auth }) => {
  const router = useHistory();
  const globalZonesAssigned = useMemo(() => auth.user?.zoneGlobalDescrp || [], [auth]);
  const params = useParams<{ optionType: string }>();

  const userTypeAllowed = auth.user?.userTypeName.includes(UserTypeValues.admin);

  if (
    globalZonesAssigned.length === 0 ||
    !params ||
    !params.optionType ||
    (!userTypeAllowed &&
      !(
        auth.user?.subCategoriesLevelOne ||
        auth.user?.subCategoriesLevelTwo ||
        auth.user?.subCategoriesLevelThree
      ))
  ) {
    Swal.fire({
      icon: 'error',
      title: 'Ocurrió un error al obtener los parametros. Contacta al administrador'
    });
    router.push('/');
  }

  const optionType = params.optionType as ModuleOption;
  const title = modules[optionType].name;

  const subCategoriesLevelOne = useMemo(() => auth.user?.subCategoriesLevelOne || [], [auth]);
  const subCategoriesLevelTwo = useMemo(() => auth.user?.subCategoriesLevelTwo || [], [auth]);
  const subCategoriesLevelThree = useMemo(() => auth.user?.subCategoriesLevelThree || [], [auth]);

  const levels = useMemo(
    () =>
      [
        { name: UserLevels.levelOne, subCategories: subCategoriesLevelOne },
        { name: UserLevels.levelTwo, subCategories: subCategoriesLevelTwo },
        { name: UserLevels.levelThree, subCategories: subCategoriesLevelThree }
      ].filter((l) => l.subCategories.length > 0),
    [subCategoriesLevelOne, subCategoriesLevelTwo, subCategoriesLevelThree]
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [centerAssignment, setCenterAssignment] = useState<
    Array<{
      description: string; //nivel 4 - sub categoria
      zoneDescrp: string; // zona
      zoneGlobalDescrp: string; // zona global
      levelOne: string; // nivel 1
      levelTwo: string; // nivel 2
      levelThree: string; // nivel 3
    }>
  >([]);

  useEffect(() => {
    const update = async () => {
      setLoading(true);
      const res = await getUncatchEndpointData({ endpoint: 'costCenter/centerAssignment' });
      if (res && res.length > 0) {
        setCenterAssignment(res);
      }
      setLoading(false);
    };
    update();
  }, []);

  const [subCategories, setSubCategories] = useState<
    Array<{ globalZone: string; subCategories: Array<string> }>
  >([]);

  const buttons: Array<{
    name: string;
    href: string;
    disabled: boolean;
  }> = useMemo(
    () => [
      {
        name: 'Norte Grande',
        href: '/operaciones',
        disabled: !globalZonesAssigned.includes('Norte Grande') && !userTypeAllowed
      },
      {
        name: 'Norte Chico',
        href: '/operaciones',
        disabled: !globalZonesAssigned.includes('Norte Chico') && !userTypeAllowed
      },
      {
        name: 'Centro',
        href: '/mapa-zona-centro',
        disabled: !globalZonesAssigned.includes('Centro') && !userTypeAllowed
      },
      {
        name: 'Centro Sur',
        href: '/operaciones',
        disabled: !globalZonesAssigned.includes('Centro Sur') && !userTypeAllowed
      },
      {
        name: 'Sur',
        href: '/operaciones',
        disabled: !globalZonesAssigned.includes('Sur') && !userTypeAllowed
      }
    ],
    [globalZonesAssigned, userTypeAllowed]
  );

  useEffect(() => {
    const filteredSubcategories: Array<{ globalZone: string; subCategories: Array<string> }> = [];
    buttons.forEach((b) => {
      if (auth.user?.userTypeName.includes(UserTypeValues.boss) && levels.length === 1) {
        const filterSubCategoryZone = centerAssignment.filter(
          (f) =>
            f.zoneGlobalDescrp === b.name &&
            levels[0].subCategories.some((sC) => sC === f.description)
        );
        filteredSubcategories.push({
          globalZone: b.name,
          subCategories: filterSubCategoryZone.map((f) => f.description)
        });
      } else {
        filteredSubcategories.push({
          globalZone: b.name,
          subCategories: []
        });
      }
    });

    setSubCategories(filteredSubcategories);
  }, [centerAssignment, auth, buttons, levels]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedHref, setSelectedHref] = useState<string | null>(null);
  const [selectedGlobalZone, setSelectedGlobalZone] = useState<string | null>(null);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const LevelQuestionModal: FunctionComponent = () => {
    const handleLevelButtonClick = (level: string, subCategories: string[]) => {
      const isCentro = selectedGlobalZone === 'Centro';
      const zoneRoute = isCentro ? '' : `/${subCategories}`;
      router.push(
        selectedHref + `/${optionType}/${selectedGlobalZone}/${level}/${subCategories}${zoneRoute}`
      );
    };

    return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>¿Con qué nivel deseas ingresar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='level-question-button-container'>
            {levels.map((l, idx) => (
              <Button
                key={idx}
                className='level-question-button'
                onClick={() => handleLevelButtonClick(l.name, l.subCategories)}>
                {l.name}
              </Button>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const onClickGlobalZone = (href: string, globalZone: string) => {
    const isCentro = globalZone === 'Centro';

    if (
      auth.user?.userTypeName.length === 1 &&
      auth.user?.userTypeName.includes(UserTypeValues.admin)
    ) {
      const userTypeName = auth.user?.userTypeName[0];
      const zoneRoute = isCentro ? '' : `/${userTypeName}`;
      router.push(
        href + `/${optionType}/${globalZone}/${userTypeName}/${userTypeName}${zoneRoute}`
      );
    } else if (auth.user?.userTypeName.includes(UserTypeValues.boss) && levels.length === 1) {
      const foundSubcategories = subCategories.find((sC) => sC.globalZone === globalZone);
      const zoneRoute = isCentro ? '' : `/${foundSubcategories?.subCategories}`;
      router.push(
        href +
          `/${optionType}/${globalZone}/${UserLevels.levelOne}/${foundSubcategories?.subCategories}${zoneRoute}`
      );
    } else if (levels.length === 1) {
      const zoneRoute = isCentro ? '' : `/${levels[0]?.subCategories}`;
      router.push(
        href +
          `/${optionType}/${globalZone}/${levels[0].name}/${levels[0].subCategories}${zoneRoute}`
      );
    } else if (levels.length > 1) {
      setSelectedHref(href);
      setSelectedGlobalZone(globalZone);
      handleShow();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Tu usuario no tiene niveles asignados. Contacta al administrador'
      });
    }
  };

  return (
    <div className='global-zone-map-page-container'>
      <div className='global-zone-map-page-top-buttons'>
        <BackButton route='/modulos' />
        {auth.user?.userTypeName.includes(UserTypeValues.boss) && (
          <div className='global-zone-map-page-total-general-button'>
            <Button
              onClick={() => router.push('/reporte/totales-generales')}
              variant='contained'
              color='primary'>
              Total General Transportes CCU
            </Button>
          </div>
        )}
      </div>

      {selectedHref && selectedGlobalZone && <LevelQuestionModal />}

      <div className='global-zone-map-page-title-container'>
        <h2>{title}</h2>
      </div>
      <div className='global-zone-map-buttons-body'>
        {loading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <img src={mapChile} alt='img-country-map' className='img-country-map' />

            <div className='global-zone-map-buttons-section'>
              {subCategories.length > 0 &&
                buttons?.map((b, idx) => (
                  <Button
                    key={idx}
                    className={`global-zone-map-button ${
                      b.disabled ? 'global-zone-map-button-disabled' : ''
                    }`}
                    disabled={b.disabled || loading}
                    onClick={() => onClickGlobalZone(b.href, b.name)}>
                    {b.name}
                  </Button>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default connect(({ auth }: AppState) => ({ auth }))(GlobalZoneMapPage);
