import { NotificationType } from '../types';
import { WebEntity } from './types';

const NotificationTypeEntity: WebEntity<NotificationType> = {
  name: 'notificationType',
  endpoint: 'notification/notificationType',
  referenceColumn: 'reclassification',
  tableColumns: [
    {
      header: 'Nombre',
      accessorKey: 'notificationTypeName',
      columnType: 'text'
    },
    {
      header: 'Descripción',
      accessorKey: 'notificationTypeDescription',
      columnType: 'text'
    }
  ]
};

export default NotificationTypeEntity;
