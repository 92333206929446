import { HFM } from '../types';
import { WebEntity } from './types';
import { dateFormatFn } from '../utils/utils';

const HFMEntity: WebEntity<HFM> = {
  name: 'hfm',
  endpoint: 'hfm',
  referenceColumn: 'hfm',
  tableColumns: [
    {
      header: 'Categoria',
      accessorKey: 'categoriesName',
      id: 'categoriesName',
      columnType: 'text'
    },
    {
      header: 'Cuenta Sap',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text'
    },
    {
      header: 'Descripcion Cuenta',
      accessorKey: 'accountDescription',
      id: 'accountDescription',
      columnType: 'text'
    },
    {
      header: 'Centro de Coste SAP',
      accessorKey: 'costCenterSap',
      id: 'costCenterSap',
      columnType: 'text'
    },
    {
      header: 'Fecha',
      accessorKey: 'loadMonth',
      id: 'loadMonth',
      columnType: 'dateUTC',
      accessorFn: (row) => dateFormatFn(row.loadMonth, 'MM/YYYY', true)
    }
  ],

  fields: [],

  editableFields: []
};

export default HFMEntity;
