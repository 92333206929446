import { FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Maintainers } from '.';
import '../css/foreignExchange.css';
import { getUncatchEndpointData } from '../utils/request';
import { CardAlertProps } from '../components/CardAlert';

const ForeignExchangePage: FunctionComponent = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  type PercentageType = {
    title: string;
    value: string;
  };

  const [foreignExchangePercentages, setForeignExchangePercentages] = useState<PercentageType[]>();
  const [additionalCardAlerts, setAdditionalCardAlerts] = useState<CardAlertProps[]>([]);

  useEffect(() => {
    const update = async () => {
      const res: PercentageType[] = await getUncatchEndpointData({
        endpoint: 'foreignExchange/foreignExchangePercentages'
      });
      if (res) {
        setForeignExchangePercentages(res);
      }
    };
    update();
  }, []);

  useEffect(() => {
    if (foreignExchangePercentages && foreignExchangePercentages.length > 0) {
      const data = foreignExchangePercentages.map((row) => {
        return {
          title: row.title,
          numberInfo: row.value
        };
      });

      setAdditionalCardAlerts(data);
    } else {
      setAdditionalCardAlerts([]);
    }
  }, [foreignExchangePercentages]);

  const ForeignExchangeResumeCRUDMaintainer = useMemo(
    () => Maintainers.ForeignExchangeResumeCRUDMaintainer,
    []
  );

  const ForeignExchangeCRUDMaintainer = useMemo(
    () => Maintainers.ForeignExchangeCRUDMaintainer,
    []
  );

  const ForeignExchangeEstimatedCRUDMaintainerConnected = useMemo(
    () => Maintainers.ForeignExchangeEstimatedCRUDMaintainerConnected,
    []
  );

  return (
    <div className='foreign-exchange-page'>
      <h1 className='foreign-exchange-title'>Variables Macroeconomicas</h1>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Resumen' value='1' />
            <Tab label='Real' value='2' />
            <Tab label='Presupuesto' value='3' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <ForeignExchangeResumeCRUDMaintainer additionalCardAlerts={additionalCardAlerts} />
        </TabPanel>
        <TabPanel value='2'>
          <ForeignExchangeCRUDMaintainer />
        </TabPanel>
        <TabPanel value='3'>
          <ForeignExchangeEstimatedCRUDMaintainerConnected />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ForeignExchangePage;
