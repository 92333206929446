import { FunctionComponent } from 'react';
import {
  ContingencyWithholdingInconsistenciesCRUD,
  FreightExtraInconsistenciesCRUD
} from '../components/GeneratedComponents';

export const ContingencyWithholdingInconsistenciesReport: FunctionComponent = () => (
  <ContingencyWithholdingInconsistenciesCRUD />
);

export const FreightExtraInconsistenciesReport: FunctionComponent = () => (
  <FreightExtraInconsistenciesCRUD />
);
