import { BudgetType } from '../types';
import { WebEntity } from './types';

const BudgetTypeEntity: WebEntity<BudgetType> = {
  name: 'budgetType',
  endpoint: 'budgetType',
  referenceColumn: 'budgetTypeName',

  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Tipo de presupuesto',
      accessorKey: 'budgetTypeName',
      columnType: 'text'
    },
    {
      header: 'Indicador',
      accessorKey: 'budgetTypeIndicator',
      columnType: 'text'
    },
    {
      header: 'Año',
      accessorKey: 'currentYear',
      columnType: 'text'
    }
  ],

  fields: [],

  editableFields: []
};

export default BudgetTypeEntity;
