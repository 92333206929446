import { FunctionComponent, useState, SyntheticEvent } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  ShipmentCarriageCRUDMaintainerConnected,
  ShipmentAuditingCarriageCRUDMaintainerConnected,
  ShipmentCarriagePXQCRUDMaintainerConnected,
  ResumeShipmentCarriageCRUDMaintainerConnected
} from './Maintainers';
import BackButton from '../components/BackButton';

const ShipmentCarriagePage: FunctionComponent = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <div style={{ padding: '10px' }}>
        <BackButton route='/acarreo' />
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Resumen' value='1' />
            <Tab label='PxQ SPOT' value='2' />
            <Tab label='PxQ Licitadas' value='3' />
            <Tab label='Carga' value='4' />
            <Tab label='Inconsistencias' value='5' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <ResumeShipmentCarriageCRUDMaintainerConnected />
        </TabPanel>
        <TabPanel value='2'>
          <ShipmentCarriagePXQCRUDMaintainerConnected carrierTypeCarriage='SPOT' />
        </TabPanel>
        <TabPanel value='3'>
          <ShipmentCarriagePXQCRUDMaintainerConnected carrierTypeCarriage='LICITADO' />
        </TabPanel>
        <TabPanel value='4'>
          <ShipmentCarriageCRUDMaintainerConnected />
        </TabPanel>
        <TabPanel value='5'>
          <ShipmentAuditingCarriageCRUDMaintainerConnected />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ShipmentCarriagePage;
