import { Budget } from '../types';
import { formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const BudgetEntity: WebEntity<Budget> = {
  name: 'budget',
  endpoint: 'budget',
  referenceColumn: 'budget',
  tableColumns: [
    {
      header: 'Total General',
      accessorKey: 'totalGeneral',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.totalGeneral)
    },
    {
      header: 'Nombre de categoria',
      id: 'categoriesName',
      accessorKey: 'categoriesName',
      columnType: 'text'
    },
    {
      header: 'Cuenta SAP',
      accessorKey: 'accountSap',
      columnType: 'text'
    },

    {
      header: 'Nombre CC SAP',
      accessorKey: 'accountDescription',
      columnType: 'text'
    },
    {
      header: 'CC SAP',
      accessorKey: 'costCenterSap',
      columnType: 'text'
    },
    {
      header: 'Centro de beneficio',
      accessorKey: 'costCenterName',
      columnType: 'text'
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      columnType: 'text'
    },
    {
      header: 'Sub Zona',
      accessorKey: 'subCategoriesDescription',
      columnType: 'text'
    },
    {
      header: 'Indicador',
      accessorKey: 'budgetTypeName',
      columnType: 'text'
    },
    {
      header: 'Enero',
      accessorKey: 'enero',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.enero)
    },
    {
      header: 'Febrero',
      accessorKey: 'febrero',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.febrero)
    },
    {
      header: 'Marzo',
      accessorKey: 'marzo',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.marzo)
    },
    {
      header: 'Abril',
      accessorKey: 'abril',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.abril)
    },
    {
      header: 'Mayo',
      accessorKey: 'mayo',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.mayo)
    },
    {
      header: 'Junio',
      accessorKey: 'junio',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.junio)
    },
    {
      header: 'Julio',
      accessorKey: 'julio',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.julio)
    },
    {
      header: 'Agosto',
      accessorKey: 'agosto',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.agosto)
    },
    {
      header: 'Septiembre',
      accessorKey: 'septiembre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.septiembre)
    },
    {
      header: 'Octubre',
      accessorKey: 'octubre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.octubre)
    },
    {
      header: 'Noviembre',
      accessorKey: 'noviembre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.noviembre)
    },
    {
      header: 'Diciembre',
      accessorKey: 'diciembre',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.diciembre)
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'userTypeName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export const BUDGET_COLUMNS = {
  costCenterSap: 'CC',
  accountSap: 'Cta',
  budgetTypeIndicator: 'Indicador',
  month: 'Mes',
  costCenterName: 'Nombre CC',
  zoneDescrp: 'Zona',
  userUsernameLevelOne: { name: 'Nivel 1', required: false, export: true },
  userUsernameLevelTwo: { name: 'Nivel 2', required: false, export: true },
  userUsernameLevelThree: { name: 'Nivel 3', required: false, export: true },
  userUsernameLevelFour: { name: 'Nivel 4', required: false, export: true },
  area: { name: 'Area', required: false, export: true },
  categoriesName: 'Categoria',
  accountDescription: 'Descrip_Cuenta',
  total: 'Monto',
  ccPs: { name: 'CC_PS', required: false, export: true },
  ctaPs: { name: 'CTA_PS', required: false, export: true }
};

export default BudgetEntity;
