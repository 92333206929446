import { SubCostCenter } from '../types';
import { FieldTypes, WebEntity } from './types';

const SubCostCenterEntity: WebEntity<SubCostCenter> = {
  name: 'subCostCenter',
  endpoint: 'costCenter/subCostCenters',
  referenceColumn: 'description',
  customButtonNames: {
    addButtonName: 'Agregar Sub Centro de costo'
  },
  customDataActions: {
    editModalTitle: 'Editar Sub Centro de costo'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Sub Centro de costo',
      accessorKey: 'description',
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Sub Centro de costo',
      selector: 'description',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona',
      selector: 'zoneId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Sub Categoria',
        endpoint: 'zone',
        select: 'zoneId',
        show: 'zoneDescrp'
      }
    }
  ],

  editableFields: [
    {
      name: 'Sub Centro de costo',
      selector: 'description',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona',
      selector: 'zoneDescrp',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Sub Categoria',
        endpoint: 'zone',
        select: 'zoneDescrp',
        show: 'zoneDescrp'
      }
    }
  ]
};

export default SubCostCenterEntity;
