import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import { AuthState, ErrorState, LOGIN_FAIL } from '../../reducers/types';
import { AppState } from '../../store';
import Vector from '../../img/Vector.svg';
import CCU from '../../img/ccu_logo.png';
import visibility from '../../img/visibility-off.svg';
import Persons from '../../img/icono_personas.svg';
import GenericIcons from '../generics/GenericIcons';

const LoginForm: FunctionComponent<{
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onClick: (e: React.MouseEvent<HTMLImageElement>) => void;
  viewPassword: boolean;
  msg: string | null;
}> = ({ onChange, onSubmit, onClick, viewPassword, msg }) => {
  return (
    <form onSubmit={onSubmit} className='form-login'>
      {/* Email */}

      <div className='container-form-login'>
        <div className='login-user-email'>
          <label htmlFor='username' className='label-input-username'>
            Nombre de usuario
          </label>
          <input
            name='userUsername'
            type='text'
            id='userUsername'
            placeholder='Nombre de usuario'
            className='login-input-username'
            onChange={onChange}
          />
        </div>

        {/* Password */}
        <div className='login-user-password'>
          <label htmlFor='password' className='label-input-password'>
            Password
          </label>
          <div className='login-password'>
            <input
              name='userPassword'
              type={viewPassword ? 'password' : 'text'}
              id='userPassword'
              className='login-input-password'
              onChange={onChange}
              placeholder='***********'
            />

            <img
              onClick={onClick}
              src={visibility}
              alt='visibility-password'
              className='visibility-login-password'
              width={24}
              height={24}
            />
          </div>
        </div>
        {msg && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              color: '#fff',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <GenericIcons icon='infoWhite' />
            {msg}
          </div>
        )}
      </div>
      {/* Register Button */}
      <div className='login-user-button'>
        <button type='submit' className='button-login'>
          Iniciar sesión
        </button>
      </div>
    </form>
  );
};

type Props = {
  auth: AuthState;
  error: ErrorState;
  login: ({
    userUsername,
    userPassword
  }: {
    userUsername: string;
    userPassword: string;
  }) => Promise<void>;
  clearErrors: typeof clearErrors;
};

const Login: FunctionComponent<Props> = ({ auth, error, login, clearErrors }) => {
  const [userInfo, setUserInfo] = useState<{ userUsername: string; userPassword: string }>({
    userUsername: '',
    userPassword: ''
  });
  const [msg, setMsg] = useState<string | null>(null);
  useEffect(() => {
    if (error) {
      // Check for register error
      if (error.id === LOGIN_FAIL) {
        setMsg(error.msg.msg);
      } else {
        setMsg(null);
      }
    }
  }, [error]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    clearErrors();
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    login(userInfo);
    setViewPassword(true);
  };

  const [viewPassword, setViewPassword] = useState<boolean>(true);
  const handleImageOnClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setViewPassword(!viewPassword);
  };

  return (
    <div className='container-login'>
      <div className='left-login'>
        <div className='container-left-title'>
          <div className='left-row-title'>
            <div className='title-left-div-Login'>
              <span className='left-title-login'>LOGIN</span>
            </div>
            <div className='title-left-div-gestion'>
              <span className='left-title-gestion'>Gestión</span>
            </div>
            <div className='title-left-div-inventario'>
              <span className='left-title-inventario'>Finanzas</span>
            </div>
          </div>
          <div className='left-row-img'>
            <img src={Persons} alt='' />
          </div>
        </div>
      </div>
      <div className='right-login'>
        <LoginForm
          onSubmit={handleOnSubmit}
          onChange={handleOnChange}
          onClick={handleImageOnClick}
          viewPassword={viewPassword}
          msg={msg}
        />
        <div className='vector-CCU'>
          <img src={Vector} alt='vector' />
          <img src={CCU} alt='' className='ccu-logo-login' />
        </div>
      </div>
    </div>
  );
};

export default connect(({ auth, error }: AppState) => ({ auth, error }), { login, clearErrors })(
  Login
);
