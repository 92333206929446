import { useEffect, useMemo, useState } from 'react';
import { Reclassification, StatusBudgetNames } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { ApproveOrRejectReclassificationButton } from '../components/SecondaryButton';
import { MRT_RowData } from 'material-react-table';

const PendingReclassificationEntity: WebEntity<Reclassification> = {
  name: 'reclassification',
  endpoint: 'reclassification/pendingReclassification',
  referenceColumn: 'reclassification',
  tableColumns: [
    {
      header: 'Opciones',
      accessorKey: '_id',
      id: 'Options',
      omitExport: true,
      columnType: 'text',
      AggregatedCell: ({ row }) => {
        const [rows, setRows] = useState<Array<MRT_RowData>>([]);
        useEffect(() => {
          if (row.subRows) {
            setRows(row.subRows?.map((obj) => obj.original));
          }
        }, [row.subRows]);

        const [selectedOption, setSelectedOption] = useState<string>('');
        const [textExplication, setTextExplication] = useState<string>('');

        const options = useMemo(() => [StatusBudgetNames.approved, StatusBudgetNames.rejected], []);

        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <FormControl sx={{ minWidth: '140px' }}>
              <InputLabel id='pending-reclassification-select-label'>Opcion</InputLabel>
              <Select
                labelId='pending-reclassification-select-label'
                id='pending-reclassification-select'
                value={selectedOption}
                label='Opción'
                onChange={(event: SelectChangeEvent) => setSelectedOption(event.target.value)}>
                {options.map((opt, idx) => (
                  <MenuItem key={idx} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedOption === StatusBudgetNames.rejected && (
              <TextField
                id='pending-reclassification-text-field'
                label='Explicación'
                variant='standard'
                value={textExplication}
                onChange={(event) => setTextExplication(event.target.value)}
              />
            )}
            {selectedOption === StatusBudgetNames.rejected && textExplication !== '' ? (
              <ApproveOrRejectReclassificationButton
                rows={rows}
                selectedOption={selectedOption}
                textExplication={textExplication}
              />
            ) : selectedOption === StatusBudgetNames.approved ? (
              <ApproveOrRejectReclassificationButton rows={rows} selectedOption={selectedOption} />
            ) : null}
          </div>
        );
      },
      size: 400
    },
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Correlativo',
      accessorKey: 'correlative',
      id: 'correlative',
      columnType: 'text'
    },
    {
      header: 'Grupo Ledgers',
      accessorKey: 'ledgerGroup',
      id: 'ledgerGroup',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sociedad',
      accessorKey: 'society',
      id: 'society',
      columnType: 'text'
    },
    {
      header: 'Clase de Documento',
      accessorKey: 'documentType',
      id: 'documentType',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Fecha de Documento',
      accessorKey: 'documentDate',
      id: 'documentDate',
      columnType: 'dateUTC',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row?.original?.documentDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Fecha de Contabilizacion',
      accessorKey: 'accountingDate',
      id: 'accountingDate',
      columnType: 'dateUTC',
      size: 300,
      Cell: ({ row }) => dateFormatFn(row?.original?.accountingDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Moneda',
      accessorKey: 'coinDescription',
      id: 'coinDescription',
      columnType: 'text'
    },
    {
      header: 'Texto Cabecera Docuemnto',
      accessorKey: 'textDocument',
      id: 'textDocument',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Tipo de cuenta',
      accessorKey: 'typeAccount',
      id: 'typeAccount',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Numero de cuenta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Importe MDA',
      accessorKey: 'amountMd',
      id: 'amountMd',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row?.original?.amountMd)
    },
    {
      header: 'Asignacion',
      accessorKey: 'assignment',
      id: 'assignment',
      columnType: 'text'
    },
    {
      header: 'Texto Posicion',
      accessorKey: 'text',
      id: 'text',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Centro Beneficio',
      accessorKey: 'centroBeneficio',
      id: 'centroBeneficio',
      columnType: 'text',
      size: 210
    },
    {
      header: 'Centro de coste',
      accessorKey: 'centroCoste',
      id: 'centroCoste',
      columnType: 'text',
      size: 200
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'userTypeName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default PendingReclassificationEntity;
