import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { utils, writeFile } from 'xlsx-js-style';

export type FareDifferenceExportDataType = {
  zonePortingDescription: string;
  carrierName: string;
  tariffTotal: number;
  boxQuantity: number;
  total: number;
};

const columnNames = ['ZONA', 'TRANSPORTISTA', 'TARIFA', 'CAJAS EQ.', 'TOTAL'];
const selectors = ['zonePortingDescription', 'carrierName', 'tariffTotal', 'boxQuantity', 'total'];

export type FareDifferenceExportProps = {
  month: string;
  year: string;
  data: Array<FareDifferenceExportDataType>;
  freightCharges: {
    q1: number;
    q2: number;
  };
};

const boldAndUnderline = {
  font: {
    underline: true,
    bold: true
  }
};

const bold = {
  font: {
    bold: true
  }
};

const borderStyle = {
  border: {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } }
  }
};

const headerStyle = {
  fill: {
    fgColor: { rgb: '92D050' }
  },
  font: {
    color: { rgb: 'FFFFFF' }
  }
};

const FareDifferenceExport: FunctionComponent<FareDifferenceExportProps> = ({
  month,
  year,
  data,
  freightCharges
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const workbook = utils.book_new();
      const worksheet = utils.aoa_to_sheet([]);
      //Mes -> Union A1 - A2
      utils.sheet_add_aoa(worksheet, [[{ v: month, s: boldAndUnderline }]], { origin: 'A1' });

      // Cabeceras tabla
      utils.sheet_add_aoa(worksheet, [columnNames], { origin: 'A3' });

      //Tabla
      const dataRows = data.map((row) =>
        selectors.map((selector) => {
          const value = row[selector as keyof FareDifferenceExportDataType];
          if (typeof value === 'number') {
            if (selector === 'tariffTotal' && value % 1 !== 0) {
              return { v: value, t: 'n', z: '#,##0.##' };
            }
            return { v: value, t: 'n', z: '#,##0' };
          }
          return value;
        })
      );
      utils.sheet_add_aoa(worksheet, dataRows, { origin: 'A4' });
      //Bordes para tabla
      ['A', 'B', 'C', 'D', 'E'].forEach((letter) => {
        for (let i = 0; i < dataRows.length + 1; i++) {
          const row = i + 3;
          const cell = `${letter}${row}`;

          if (worksheet[cell]) {
            worksheet[cell].s = {
              ...borderStyle
            };
            if (i === 0) {
              worksheet[cell].s = {
                ...worksheet[cell].s,
                ...headerStyle,
                ...borderStyle
              };
            }
          }
        }
      });
      const lastRowTable = 4 + dataRows.length;
      //Total de columna 'TOTAL'
      utils.sheet_add_aoa(worksheet, [[{ v: 'Total', s: { ...borderStyle, ...bold } }]], {
        origin: `D${lastRowTable}`
      });
      utils.sheet_add_aoa(
        worksheet,
        [
          [
            {
              t: 'n',
              f: `SUM(E4:E${lastRowTable - 1})`,
              z: '#,##0',
              s: { ...borderStyle, ...bold }
            }
          ]
        ],
        {
          origin: `E${lastRowTable}`
        }
      );

      //Total de columna 'CAJAS EQ.'
      utils.sheet_add_aoa(
        worksheet,
        [[{ t: 'n', f: `SUM(D4:D${lastRowTable - 1})`, z: '#,##0' }]],
        {
          origin: `D${lastRowTable + 1}`
        }
      );

      const firstRowFreightTable = lastRowTable + 2;

      //Tabla Flete
      utils.sheet_add_aoa(
        worksheet,
        [['FLETE PAGADO', '', `1Q ${month}`, { v: freightCharges.q1, t: 'n', z: '#,##0' }]],
        {
          origin: `B${firstRowFreightTable}`
        }
      );
      worksheet['!merges'] = [
        { s: { r: firstRowFreightTable - 1, c: 1 }, e: { r: firstRowFreightTable - 1, c: 2 } }
      ];
      utils.sheet_add_aoa(
        worksheet,
        [['FLETE PAGADO', '', `2Q ${month}`, { v: freightCharges.q2, t: 'n', z: '#,##0' }]],
        {
          origin: `B${firstRowFreightTable + 1}`
        }
      );
      worksheet['!merges'] = [
        ...worksheet['!merges'],
        { s: { r: firstRowFreightTable, c: 1 }, e: { r: firstRowFreightTable, c: 2 } }
      ];

      ['B', 'C', 'D', 'E'].forEach((letter) => {
        for (let i = 0; i <= 2; i++) {
          const row = firstRowFreightTable + i;
          const cell = `${letter}${row}`;
          if (worksheet[cell]) {
            worksheet[cell].s = {
              ...borderStyle
            };
          }
        }
      });

      //Total Flete
      utils.sheet_add_aoa(worksheet, [['Total']], {
        origin: `D${firstRowFreightTable + 2}`
      });
      worksheet[`D${firstRowFreightTable + 2}`].s = {
        ...borderStyle,
        ...bold
      };
      utils.sheet_add_aoa(
        worksheet,
        [[{ t: 'n', f: `SUM(E${firstRowFreightTable}:E${firstRowFreightTable + 1})`, z: '#,##0' }]],
        {
          origin: `E${firstRowFreightTable + 2}`
        }
      );
      worksheet[`E${firstRowFreightTable + 2}`].s = {
        ...borderStyle,
        ...bold
      };

      const lastRowFreightTable = firstRowFreightTable + 2;
      //Diferencia de tarifa
      utils.sheet_add_aoa(
        worksheet,
        [
          [
            `DIFERENCIA DE TARIFA ${month.toUpperCase()} ${year}`,
            '',
            '',
            { f: `E${lastRowTable}-E${firstRowFreightTable + 2}`, t: 'n', z: '#,##0' },
            {
              f: `(E${lastRowTable}-E${firstRowFreightTable + 2})/E${firstRowFreightTable + 2}`,
              t: 'n',
              z: '#0.##%'
            }
          ]
        ],
        {
          origin: `B${lastRowFreightTable + 2}`
        }
      );

      worksheet['!merges'] = [
        ...worksheet['!merges'],
        { s: { r: lastRowFreightTable + 1, c: 1 }, e: { r: lastRowFreightTable + 1, c: 3 } }
      ];

      ['B', 'C', 'D', 'E'].forEach((letter) => {
        const cell = `${letter}${lastRowFreightTable + 2}`;
        if (worksheet[cell]) {
          if (letter === 'E') {
            worksheet[cell].s = {
              ...borderStyle,
              ...bold,
              fill: {
                fgColor: { rgb: '0070C0' }
              },
              font: {
                color: { rgb: 'FFFFFF' }
              }
            };
            return;
          }
          worksheet[cell].s = {
            ...borderStyle,
            ...bold
          };
        }
      });

      utils.book_append_sheet(workbook, worksheet, 'Datos');
      writeFile(workbook, `Diferencia_Tarifa_${month}_${year}.xlsx`);
      setLoading(false);
    }, 500);
  };

  return (
    <Button
      onClick={handleExport}
      variant='contained'
      color='warning'
      disabled={loading || data.length === 0}>
      <FileDownloadOutlinedIcon />
      Para Mail
    </Button>
  );
};

export default FareDifferenceExport;
