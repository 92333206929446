import { Box } from '@mui/material';
import { FreightExtraInconsistencies } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const FreightExtraInconsistenciesEntity: WebEntity<FreightExtraInconsistencies> = {
  name: 'freightExtraInconsistencies',
  endpoint: 'freightExtra/inconsistencies',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Fecha Contabilización',
      accessorKey: 'accountingDate',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) =>
        row.original?.accountingDate
          ? `${spanishMonthNames[new Date(row.original?.accountingDate).getUTCMonth()]} ${new Date(
              row.original?.accountingDate
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Rut',
      accessorKey: 'carrierRut',
      columnType: 'text',
      size: 200
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Valor Extra',
      accessorKey: 'extraAmount',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.extraAmount ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.extraAmount, 0, 2)}
          </Box>
        ) : (
          ''
        )
    },
    {
      header: 'Valor GL SAP',
      accessorKey: 'glSapAmount',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.glSapAmount ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.glSapAmount, 0, 2)}
          </Box>
        ) : (
          ''
        )
    }
  ]
};

export default FreightExtraInconsistenciesEntity;
