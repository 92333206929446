import { Box, Stack } from '@mui/material';
import { Expenses } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const ExpensesEntity: WebEntity<Expenses> = {
  name: 'expenses',
  endpoint: 'expenses',
  referenceColumn: 'expenses',
  tableColumns: [
    {
      header: 'Cuenta de mayor',
      accessorKey: 'accountSap',
      enableEditing: false,
      columnType: 'text'
    },
    {
      header: 'Centro de costo',
      accessorKey: 'costCenterName',
      enableEditing: false,
      columnType: 'text'
    },
    {
      header: 'Descripcion Cuenta',
      accessorKey: 'accountDescription',
      enableEditing: false,
      columnType: 'text'
    },
    {
      header: 'Categoria',
      accessorKey: 'categoriesName',
      enableEditing: false,
      columnType: 'text'
    },
    {
      header: 'Subido por',
      accessorKey: 'userUsername',
      enableEditing: false,
      columnType: 'text'
    },
    {
      header: 'Total',
      accessorKey: 'total',
      enableEditing: false,
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row?.original?.total),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum?.toString())}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + Number(obj.total), 0);
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString())}
          </Stack>
        );
      }
    }
  ]
};

export const EXPENSES_COLUMNS = {
  society: { name: 'Sociedad', required: false, export: true },
  accountingPeriod: { name: 'Período contable', required: false, export: true },
  documentNumber: { name: 'Nº documento', required: false, export: true },
  accountingDate: 'Fe.contabilización',
  accountSap: 'Cuenta de mayor',
  textoExplicativo: {
    name: 'Cuenta de mayor: Texto explicativo',
    required: false,
    export: true
  },
  costCenterSap: 'Centro de beneficio',
  costCenterheader: {
    name: 'Centro de beneficio: Texto explicativo',
    required: false,
    export: true
  },
  documentType: {
    name: 'Clase de documento',
    required: false,
    export: true
  },
  coinDescription: 'Clave de moneda de sociedad',
  spentDetailTotal: 'Valor de moneda de sociedad',
  text: {
    name: 'Texto',
    required: false,
    export: true
  },
  textDocument: {
    name: 'Texto cab.documento',
    required: false,
    export: true
  },
  societyGl: {
    name: 'Sociedad GL asociada',
    required: false,
    export: true
  },
  reference: {
    name: 'Referencia',
    required: false,
    export: true
  },
  userUserheader: {
    name: 'Nombre del usuario',
    required: false,
    export: true
  },
  documentDate: {
    name: 'Fecha de documento',
    required: false,
    export: true
  },
  assignment: {
    name: 'Asignación',
    required: false,
    export: true
  },
  concBusq: {
    name: 'Conc.búsq.',
    required: false,
    export: true
  },
  concBusqrut: {
    name: 'Conc.búsq. Rut',
    required: false,
    export: true
  },
  debtorAccount: {
    name: 'Cuenta de deudor: Nombre 1',
    required: false,
    export: true
  },
  companyName: {
    name: 'Nombre',
    required: false,
    export: true
  },
  supplier: {
    name: 'Proveedor',
    required: false,
    export: true
  },
  centroCoste: {
    name: 'Centro de coste',
    required: false,
    export: true
  },
  costCenterheadetwo: {
    name: 'Centro de coste: Texto explicativo',
    required: false,
    export: true
  },
  referenceDocument: {
    name: 'Documento referencia',
    required: false,
    export: true
  },
  elementPep: {
    name: 'Elemento PEP',
    required: false,
    export: true
  },
  documentPuchasing: {
    name: 'Documento compras',
    required: false,
    export: true
  }
};

export default ExpensesEntity;
