import { Map } from '../types';
import { FieldTypes, WebEntity } from './types';

const MapEntity: WebEntity<Map> = {
  name: 'map',
  endpoint: 'map',
  referenceColumn: 'cityName',
  customButtonNames: {
    addButtonName: 'Agregar Relacion-Mapa'
  },
  customDataActions: {
    editModalTitle: 'Editar Ciudad'
  },
  tableColumns: [
    {
      header: 'Ciudad',
      accessorKey: 'cityName',
      columnType: 'text',
      size: 100
    },
    {
      header: 'Zonas',
      accessorKey: 'zones',
      columnType: 'text',
      size: 300,
      Cell: ({ row }) =>
        row.original.zones ? (
          <span style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
            {row.original.zones.join(', ')}
          </span>
        ) : (
          ''
        )
    }
  ],

  fields: [
    {
      name: 'Ciudad',
      selector: 'cityId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'cityName',
        endpoint: 'map/cities',
        select: 'cityId',
        show: 'cityName'
      }
    },
    {
      name: 'Zonas',
      selector: 'zonesId',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'zones',
        endpoint: 'map/zones',
        select: 'zoneId',
        show: 'zoneDescrp'
      }
    }
  ],

  editableFields: [
    {
      name: 'Ciudad',
      selector: 'cityName',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Zonas',
      selector: 'zones',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'zones',
        endpoint: 'map/zones',
        select: 'zoneDescrp',
        show: 'zoneDescrp'
      }
    }
  ]
};

export default MapEntity;
