import React, { ReactNode, useState } from 'react';
import './GenericContainerSidebar.css';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import GenericIcons from '../GenericIcons';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthState } from '../../../reducers/types';

type Props = {
  children: ReactNode;
  img?: string;
  titleTopNav?: string;
  AppNavbar?: ReactNode;
  linkTopNav?: string;
  logout?: ReactNode;
  auth: AuthState;
};

const GenericContainerSidebar = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //const [isOpenSubMenu, setOpenSubMenu] = React.useState<null | HTMLElement>(null);
  //const openSubMenu = Boolean(isOpenSubMenu);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  /*const handleOpenSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenSubMenu(event.currentTarget);
  };*/
  return (
    <div className='container-Sidebar'>
      <div className='Sidebar-top-nav'>
        <div className='sidebar-image'>
          {isOpenSidebar && (
            <div onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
              <GenericIcons
                icon='arrowLeft'
                imageStyle={{
                  width: '30px',
                  height: '30px',
                  transform: isOpenSidebar ? 'rotate(180deg)' : '',
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '20px',
                  opacity: 0.5,
                  top: '15px'
                }}
              />
            </div>
          )}
          <img src={props.img} alt='' />
        </div>
        <div className='title-sidebar'>
          <Link to={`${props.linkTopNav}`} className='test'>
            <span className='text-title-sidebar'>
              Gestión <strong>Finanzas</strong>
            </span>
          </Link>
        </div>
        <div className='avatar'>
          <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <Tooltip title='Account settings'>
                <IconButton
                  onClick={handleClick}
                  size='small'
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}>
                  <Avatar sx={{ width: 32, height: 32, backgroundColor: '#64a70b' }}>
                    {props.auth?.user?.userUsername?.split('')[0].toLocaleUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <MenuItem onClick={handleClose} sx={{ display: 'flex', gap: 1 }}>
                <LogoutIcon />
                {props.logout}
              </MenuItem>
            </Menu>
          </React.Fragment>
        </div>
      </div>
      <div className='Sidebar-bottom-container'>
        <div className={`left-sidebar ${isOpenSidebar ? 'sidebar-fade' : ''}`}>
          <div onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
            <GenericIcons
              icon='arrowLeft'
              imageStyle={{
                width: 30,
                height: 30,
                transform: isOpenSidebar ? 'rotate(0deg)' : '',
                cursor: 'pointer',
                position: 'absolute',
                top: '21px',
                right: '10px',
                opacity: 0.5
              }}
            />
          </div>
          <div className='top'>
            <div className='top-welcome'>
              <GenericIcons
                icon='avatar'
                imageStyle={{
                  width: 32,
                  height: 32
                }}
              />
              <span className='top-welcome-user'>Bienvenido {props.auth?.user?.userUsername}</span>
            </div>
          </div>
          <div className='middle-container'>
            <div className='top-middle'>{props.AppNavbar}</div>
            <div className='bottom-logout-middle'>
              <GenericIcons
                icon='logout'
                imageStyle={{
                  width: 20,
                  margin: 0,
                  height: 20
                }}
              />
              {props.logout}
            </div>
          </div>
          <div className='bottom'>
            <span className='title-botton-gestion'>Gestión Finanzas by</span>
            <a href='https://datafy.cl/' target='_blank' rel='noopener noreferrer'>
              <GenericIcons
                icon='datafy'
                imageStyle={{
                  width: 73,
                  marginLeft: 8,
                  height: 26
                }}
              />
            </a>
          </div>
        </div>

        <div className='right-render'>{props.children}</div>
      </div>
    </div>
  );
};

export default GenericContainerSidebar;
