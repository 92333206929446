import { FunctionComponent } from 'react';
import { GenericForm } from './GenericForm';
import GenericModal from './GenericModal';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

type GenericFilterFormProps = {
  filterFormFields: any;
  isAdmin: boolean;
  onSubmit: (formFields: any) => void;
};

// Table data Filter (Fetch new data from DB)
export const GenericFilterFormModal: FunctionComponent<GenericFilterFormProps> = ({
  filterFormFields,
  isAdmin,
  onSubmit
}) => {
  const renderFilter = (closeModal: () => void) => {
    const localSubmit = (formFields: any) => {
      onSubmit(formFields);
      closeModal();
    };
    return (
      <GenericFilterForm
        filterFormFields={filterFormFields}
        onSubmit={localSubmit}
        isAdmin={isAdmin}
      />
    );
  };
  return (
    <div className='buttonFilterContainer2' style={{ alignSelf: 'end' }}>
      <GenericModal
        title='Filtros'
        buttonName='Filtros'
        buttonIcon={<FilterAltIcon />}
        buttonVariant='contained'
        buttonColor='success'
        children={renderFilter}
      />
    </div>
  );
};

const GenericFilterForm: FunctionComponent<GenericFilterFormProps> = ({
  filterFormFields,
  onSubmit,
  isAdmin
}) => {
  const localOnSubmit = (e: Event, formFields: any) => {
    e.preventDefault();
    onSubmit(formFields);
  };

  return (
    <>
      <GenericForm
        {...{
          fields: filterFormFields,
          onSubmit: localOnSubmit,
          isAdmin: isAdmin,
          actionName: 'Filtrar'
        }}
      />
    </>
  );
};

export default GenericFilterForm;
