import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  CSSProperties
} from 'react';
import { CSVLink } from 'react-csv';
import { Icon } from 'semantic-ui-react';
import { ColumnType, ExtendedColumnDef, FilterFields, WebEntity } from '../../entities/types';
import { BasicEntity, SimpleObject } from '../../types';
import {
  DateFormatType,
  convertArrayOfObjectToFieldsMatrix,
  downloadXLSX,
  numberFormatter
} from '../../utils/export';
import { getObjectFromStorage, addObjectToStorage, removeFromStorage } from '../../utils/storage';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { specialFilter, filterObject } from '../../utils/utils';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  BodyRowProps,
  ColumnComponent,
  CustomTopTable,
  InitialState,
  MRT_Row_WithOriginal,
  RowDisabledCriteria
} from '../types';
import {
  MRT_RowSelectionState,
  useMaterialReactTable,
  MRT_RowData,
  MaterialReactTable,
  MRT_TableInstance
} from 'material-react-table';
import { GenericFilterFormModal } from './GenericFilterForm';
import { Box, Button, CircularProgress } from '@mui/material';
import * as XLSX from 'xlsx';
import { warningPopAlert } from '../PopAlert';
import { AuthState } from '../../reducers/types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const FilterComponent: FunctionComponent<{
  filterText: string;
  onChange: (e: any) => void;
  onKeyDown: (e: any) => void;
  onClear: () => void;
}> = ({ filterText, onChange, onKeyDown, onClear }) => (
  <>
    <input
      id='search'
      type='text'
      placeholder='Filtro general'
      value={filterText}
      onKeyDown={onKeyDown}
      onChange={onChange}
    />

    <Button onClick={onClear}>&times;</Button>
  </>
);

const CustomExport: FunctionComponent<{
  onExport: () => any[][];
  columnTypes: ColumnType[];
  headerStyle: {
    backgroundColor: string;
    color: string;
  };
}> = ({ onExport, columnTypes, headerStyle }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const data = onExport();

      downloadXLSX(data, columnTypes, undefined, headerStyle);

      setLoading(false);
    }, 500);
  };

  return (
    <Button onClick={handleExport} variant='contained' color='success' disabled={loading}>
      <FileDownloadOutlinedIcon />
      Descargar Excel
    </Button>
  );
};

const StoredFormFilterManager: FunctionComponent<{
  storedFilterName: string;
  storedFormFilter: SimpleObject;
  refreshFunction: any;
}> = ({ storedFilterName, storedFormFilter, refreshFunction }) => {
  const objectToData = (simpleObject: SimpleObject) => ({
    header: ['filtro', 'valor'],
    rows: simpleObject
      ? Object.entries(simpleObject).filter(([_, v]) => v != null && v !== '')
      : [[]]
  });

  const data = objectToData(storedFormFilter);
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(objectToData(storedFormFilter));
  }, [storedFormFilter]);

  const onCleanStorageSingleItem = (itemName: string) => {
    const data = getObjectFromStorage(storedFilterName);
    removeFromStorage(storedFilterName);

    delete data[itemName];
    addObjectToStorage(storedFilterName, data);
    refreshFunction();
  };

  return (
    <Fragment>
      {tableData.rows && tableData.rows[0]?.length > 0 && (
        <Fragment>
          {tableData.rows.map((item, i) => (
            <button
              key={i}
              style={{
                display: 'flex',
                height: '34px',
                padding: '2px 10px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                border: '2px solid var(--ccu-verde-oscuro, #205C40)'
              }}
              onClick={() => onCleanStorageSingleItem(item[0])}>
              <p
                style={{
                  color: 'var(--ccu-verde-oscuro, #205C40)',
                  textAlign: 'center',
                  fontFamily: ' Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px'
                }}>
                {item[1]}
              </p>
              X
            </button>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

export const ExportCSV: FunctionComponent<{
  onExport: () => string[][];
}> = ({ onExport }) => (
  <CSVLink
    style={{
      display: 'inline-block',
      padding: '10px 20px',
      marginInline: '1em',
      fontSize: '16px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#4CAF50',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer'
    }}
    data={onExport()}
    filename={`export_${new Date().toISOString().split('T')[0]}.csv`}
    separator={';'}>
    <Icon name={'download'} />
  </CSVLink>
);

const Export: FunctionComponent<{
  onExport: () => any[][];
  columnTypes: ColumnType[];
}> = ({ onExport, columnTypes }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const data = onExport();

      downloadXLSX(data, columnTypes);

      setLoading(false);
    }, 500);
  };

  return (
    <Button onClick={handleExport} variant='contained' color='success' disabled={loading}>
      <FileDownloadOutlinedIcon />
      Descargar Excel
    </Button>
  );
};

// Rows per page in server side
// TODO: change this once the endpoint let us set 'ROWS_PER_PAGE' from client side
const ROWS_PER_PAGE = 20;
const DEFAULT_PAGE = 1;

type Props<T> = {
  columns: ExtendedColumnDef<T>[];
  tableData: MRT_RowData[];
  fetchData: (pageNumber?: number, pageSize?: number, filters?: any) => Promise<any>;
  filterFields?: FilterFields<T>[];
  addAction?: JSX.Element;
  addMultiLineAction?: JSX.Element;
  deleteAction?: (
    getSelectedData: () => { ids: string[]; refs: string },
    table: MRT_TableInstance<MRT_RowData>
  ) => JSX.Element;
  secondaryAction?: AdditionalTableActionsFunc<T>;
  allowedActions?: Partial<{
    add: boolean;
    multiLineForm: boolean;
    delete: boolean;
    export: boolean;
    select: boolean;
    edit: boolean;
    editCondition?: (row: T) => boolean;
    singleSelect: boolean;
  }>;
  tableName?: string;
  serverSidePagination?: boolean;
  onSelectedRowsChange?: (rows: T[]) => void;
  liveFilter?: boolean;
  sharedFilterName?: string;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  selectablePageOnly?: boolean;
  rowDisabledCriteria?: RowDisabledCriteria<T>;
  rowColumnReference?: string;
  expandableRowsComponent?: () => ReactNode;
  columnComponent?: ColumnComponent<T>;
  editAction?: (row: BasicEntity) => JSX.Element;
  additionalTableProps?: AdditionalTableProps<T>;
  customExport?: {
    headerStyle: {
      backgroundColor: string;
      color: string;
    };
  };
  selectedSingleEntity?: (row: T, event: Event) => any;
  isAdmin?: boolean;
  forceRefresh?: boolean;
  ViewInput?: boolean;
  initialState?: InitialState;
  enablePagination?: boolean;
  enableColumnVirtualization?: boolean;
  enableRowVirtualization?: boolean;
  optionsPagination?: {
    initialStatePagination?: {
      pagination?: { pageSize: number; pageIndex: number };
    };
  };
  columnsHeaderStyle?: CSSProperties;
  enableCountError?: boolean;
  webEntity?: WebEntity<T>;
  loadingTable?: boolean;
  auth: AuthState;
  additionalTopTableActions?: JSX.Element;
  customTopTable?: CustomTopTable;
  enableStickyFooter?: boolean;
  bodyRowProps?: BodyRowProps;
};

const GenericTable = <T,>({
  // Required
  columns,
  tableData,
  fetchData,
  // Optionals
  fixedHeaderScrollHeight,
  fixedHeader = false,
  filterFields,
  enablePagination = true,
  addAction,
  addMultiLineAction,
  deleteAction,
  editAction,
  secondaryAction,
  allowedActions,
  tableName = '',
  serverSidePagination = false,
  onSelectedRowsChange,
  liveFilter = false,
  sharedFilterName,
  selectablePageOnly = false,
  rowDisabledCriteria,
  rowColumnReference = '',
  expandableRowsComponent,
  columnComponent,
  additionalTableProps,
  customExport,
  selectedSingleEntity,
  columnsHeaderStyle,
  isAdmin,
  forceRefresh,
  ViewInput = true,
  initialState,
  enableColumnVirtualization = false,
  enableRowVirtualization = false,
  enableCountError = false,
  optionsPagination,
  webEntity,
  loadingTable,
  auth,
  additionalTopTableActions,
  customTopTable,
  enableStickyFooter = false,
  bodyRowProps
}: Props<T>) => {
  // === Initialize states and its 'setters functions' sub-components ===
  // NOTE: useState(initialValue) returns an 'state' var and an asociated 'setter' function
  // FilterText text

  const [filterText, setFilterText] = useState<string>('');
  const [preFilterText, setPreFilterText] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);

  // Pagination state
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

  // Server side pagination
  const [isloading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<{ totalRows: number; availablePages: number }>();

  // Server side filter
  const getStoredSharedFilter = () => {
    if (filterFields) {
      const validFilters = filterFields.map((ff) => ff.selector);
      const sharedFilter = getObjectFromStorage(sharedFilterName || '');
      return filterObject(sharedFilter, validFilters);
    }
    return null;
  };

  const sharedFilter = getStoredSharedFilter();
  // TODO: Add only the filters that match filterfields
  const [formFilters, setFormFilters] = useState(sharedFilter || {});

  // Selected rows
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //Selected IDEntity
  const [idEntity, setIdEntity] = useState('');

  const columnsSelector = useMemo(() => columns.map((c) => c.accessorKey), [columns]);

  const {
    add: canAdd,
    multiLineForm: canMultiLine,
    delete: canDelete,
    export: canExport,
    select: canSelect,
    singleSelect,
    edit
  } = {
    add: true,
    multiLineForm: true,
    delete: true,
    export: false,
    select: false,
    singleSelect: false,
    edit: true,
    ...allowedActions
  };

  /*  const tableColumns = useMemo(
    () =>
      columnComponent
        ? columnComponent.begin
          ? [{ ...columnComponent.column }, ...columns]
          : [...columns, { ...columnComponent.column }]
        : columns,
    [columns, columnComponent, tableData]
  ); */

  const fetchDataPage = useMemo(
    () => async (currentPage: number) => {
      setLoading(true);

      if (serverSidePagination) {
        const { availablePages, totalItemsCount } = await fetchData(
          currentPage,
          pageSize,
          formFilters
        );
        setPagination({ totalRows: totalItemsCount, availablePages });
      } else {
        await fetchData(0, 0, formFilters);
      }

      setLoading(false);
    },
    [fetchData, serverSidePagination, formFilters, pageSize, forceRefresh]
  );

  const onChangeRowsPerPage = (currentRowsPerPage: number) => {
    setPageSize(currentRowsPerPage);
  };

  // ComponentDidUpdate (fetchDataPage)
  // After changing rows per page, always refresh on page 1
  useEffect(() => {
    fetchDataPage(1);
  }, [fetchDataPage]);

  // ComponentDidUpdate (dataTable)
  useEffect(() => {
    setToggleCleared((toggleCleared) => !toggleCleared);
  }, [tableData]);

  // ComponentDidUpdate (fetch data applying the new filters)
  useEffect(() => {
    // Reset pagination
    setResetPaginationToggle((resetPaginationToggle) => !resetPaginationToggle);
    // Fetch data
    fetchDataPage(DEFAULT_PAGE);
  }, [formFilters, fetchDataPage]);
  // Filter items by checking if filterText is contained into the item's joined fields
  const filteredItems = useMemo(
    () => specialFilter(tableData, columnsSelector, filterText),
    [tableData, columnsSelector, filterText]
  );

  useEffect(() => {
    let isTime: NodeJS.Timeout;
    if (!loadingTable) {
      isTime = setTimeout(() => {
        setLoading(false);
      }, 100);
    }

    return () => {
      clearTimeout(isTime);
    };
  }, [loadingTable]);

  // Fn = Create a callback for row selection
  const handleRowSelected = useCallback(
    (state) => {
      setSelectedRows(state.selectedRows);
      onSelectedRowsChange!(state.selectedRows);
    },
    [onSelectedRowsChange]
  );

  // Fn = Create filter component for subheader
  /*   const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText || preFilterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setPreFilterText('');
        setFilterText('');
      }
    };

    const onChange = (e: any) => {
      setPreFilterText(e.target.value);
      if (liveFilter) setFilterText(e.target.value);
    };

    const onKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        setPreFilterText(e.target.value);
        setFilterText(e.target.value);
      }
    };

    return (
      <FilterComponent
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClear={handleClear}
        filterText={preFilterText}
      />
    );
  }, [filterText, preFilterText, liveFilter, resetPaginationToggle]); */

  const refreshStoredFilter = () => {
    const sharedFilter = getStoredSharedFilter();
    setFormFilters(sharedFilter || {});
  };

  const renderActions = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      {/* TODO: temporary Change, add again 'serverSidePagination &&' at the begining of the IF */}
      <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: ' center' }}>
        {filterFields && filterFields.length > 0 && (
          <GenericFilterFormModal
            filterFormFields={filterFields}
            onSubmit={applyServerSideFilter}
            isAdmin={!!isAdmin}
          />
        )}
        {sharedFilterName && (
          <StoredFormFilterManager
            storedFilterName={sharedFilterName}
            storedFormFilter={formFilters}
            refreshFunction={refreshStoredFilter}
          />
        )}
      </div>

      {additionalTopTableActions || canMultiLine || canAdd ? (
        <div
          style={{
            display: 'flex',
            gap: ' 10px ',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%'
          }}>
          {additionalTopTableActions}
        </div>
      ) : null}
    </div>
  );

  const renderExport = useCallback(
    (data: SimpleObject[], columns: ExtendedColumnDef<T>[]) => {
      if (canExport) {
        if (customExport) {
          return (
            <CustomExport
              onExport={() =>
                convertArrayOfObjectToFieldsMatrix(
                  data,
                  columns.map((obj) => obj.accessorKey),
                  columns.map((obj) => obj.header)
                )
              }
              columnTypes={columns.map((obj) => obj.columnType)}
              headerStyle={customExport.headerStyle}
            />
          );
        }
        return (
          <Export
            onExport={() =>
              convertArrayOfObjectToFieldsMatrix(
                data,
                columns.map((obj) => obj.accessorKey),
                columns.map((obj) => obj.header)
              )
            }
            columnTypes={columns.map((obj) => obj.columnType)}
          />
        );
      }
      return <></>;
    },
    [canExport, customExport]
  );

  // Fn = create a component to delete selected fields
  /*   const contextActions = useMemo(() => {
    const getSelectedData = () => {
      const idColumn = '_id';

       Show the idColumn if the rowColumnReference was not provided
      const rcr = rowColumnReference || idColumn;

       Function to select the data to show (depending on if it has data)
      const selectRowColumnFn = (r: any) => r[rcr] || r[idColumn];

       Getting the rows id (to work on it)
       Getting the rows reference column (to show as message)
      const { refs, ids } = selectedRows.reduce(
        (prev, curr) => ({
          refs: [...prev.refs, selectRowColumnFn(curr)],
          ids: [...prev.ids, curr[idColumn]]
        }),
        { refs: [], ids: [] } as { refs: string[]; ids: string[] }
      );

      return { ids, refs };
    };
    return (
      <div style={{ display: 'flex' }}>
        {secondaryAction && secondaryAction({ selected: selectedRows })}
        {canDelete && deleteAction && deleteAction(getSelectedData)}
        TODO: Check this file in 6e6d967f0c90f7c8c45609174791595ae8950c46
         * REALLY STRANGE ERROR:
         * If I execute deleteAction={this.deleteButton()} in GenericCRUD and then
         * deleteAction(getSelectedData) in here, the 'secondaryAction' button cannot
         * refresh its own state.
         *
      </div>
    ); 
  }, [selectedRows, deleteAction, canDelete, rowColumnReference, secondaryAction]);*/

  const serverSidePaginationOptions = serverSidePagination
    ? {
        paginationTotalRows: pagination?.totalRows,
        paginationServer: true,
        onChangePage: fetchDataPage,
        onChangeRowsPerPage,
        paginationRowsPerPageOptions: [ROWS_PER_PAGE],
        paginationPerPage: ROWS_PER_PAGE
      }
    : {};

  const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: !serverSidePagination,
    selectAllRowsItemText: 'Todos'
  };

  const applyServerSideFilter = useMemo(
    () => (formFields: { [key: string]: any }) => {
      // set fields
      setFormFilters(formFields);
      if (sharedFilterName) {
        addObjectToStorage(sharedFilterName, formFields);
      }
    },
    [sharedFilterName]
  );

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  /*   const onRowClicked = (row: T & BasicEntity, event: Event) => {
    if (selectedSingleEntity) {
      selectedSingleEntity(row, event);
    }
    setIdEntity(row._id);
  }; */

  const column = useMemo(() => columns, [columns]);

  const renderTopToolbarCustomActions = useCallback(
    ({ table }: { table: MRT_TableInstance<MRT_RowData> }) => {
      const actions: Array<JSX.Element> = [];

      if (canMultiLine && addMultiLineAction) {
        actions.push(addMultiLineAction);
      }
      if (canAdd && addAction) {
        actions.push(addAction);
      }
      const visibleColumns = table.getVisibleFlatColumns().map((obj) => obj.id);
      const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
      const exportColumns = columns.filter(
        (obj) =>
          typeof obj.accessorKey === 'string' &&
          visibleColumns.includes(obj.accessorKey) &&
          !obj.omitExport
      );

      const data = rows.map((row) =>
        exportColumns.reduce((acc, obj) => {
          const key = obj.accessorKey as string;
          acc[key] = obj.valueToExport
            ? obj.valueToExport(row)
            : row.hasOwnProperty(key)
            ? row[key]
            : '';
          return acc;
        }, {} as Record<string, any>)
      );

      actions.push(renderExport(data, exportColumns));

      if (allowedActions?.delete && canDelete && deleteAction) {
        const getSelectedData = () => {
          const rowsToDelete = table.getSelectedRowModel().rows.map((row) => row.original);
          const ids = rowsToDelete.map((row) => row._id);
          const refs = `¿Seguro que deseas eliminar ${rowsToDelete.length} registros?`;

          return { ids, refs };
        };

        if (
          table.getIsSomeRowsSelected() ||
          table.getIsAllRowsSelected() ||
          table.getGroupedSelectedRowModel().rows.length > 0 ||
          table.getGroupedSelectedRowModel().flatRows.length > 0
        ) {
          actions.push(deleteAction(getSelectedData, table));
        }
      }
      if (secondaryAction) {
        actions.push(
          secondaryAction({
            selected: table.getSelectedRowModel().rows as MRT_Row_WithOriginal<T>[]
          })
        );
      }
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {actions.map((action, idx) => (
            <Fragment key={idx}>{action}</Fragment>
          ))}
        </Box>
      );
    },
    [allowedActions, canDelete, deleteAction, renderExport, secondaryAction]
  );

  const table = useMaterialReactTable({
    data: tableData,
    columns: column,
    enableGrouping: true,
    enablePagination: enablePagination,
    enableSelectAll: allowedActions?.delete || canSelect,
    layoutMode: 'grid',
    enableRowSelection: allowedActions?.delete || canSelect,
    positionToolbarAlertBanner: 'bottom',
    selectAllMode: 'all',
    enableStickyFooter,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Acciones',
        minSize: 80,
        maxSize: 100
      },
      'mrt-row-select': {
        minSize: 42,
        maxSize: 60
      },
      'mrt-row-expand': {
        header: 'Expandir',
        minSize: 80,
        maxSize: 100,
        enableResizing: true
      }
    },
    initialState: {
      grouping: initialState?.grouping,
      columnVisibility: initialState?.columnVisibility || {},
      columnPinning: {
        left: [
          'mrt-row-actions',
          'mrt-row-select',
          'mrt-row-expand',
          ...(initialState?.columnPinning?.left || [])
        ],
        right: initialState?.columnPinning?.right
      },
      pagination: {
        pageSize: optionsPagination?.initialStatePagination?.pagination?.pageSize || 100,
        pageIndex: optionsPagination?.initialStatePagination?.pagination?.pageIndex || 0
      },
      density: 'compact'
    },
    enableBottomToolbar: true,
    enableColumnResizing: true,
    enableGlobalFilterModes: true,
    enableColumnPinning: true,
    enableColumnVirtualization: enableColumnVirtualization,
    enableRowVirtualization: enableRowVirtualization,
    localization: MRT_Localization_ES,
    enableStickyHeader: true,
    enableColumnActions: true,
    enableExpandAll: false,
    enableColumnOrdering: true,
    muiTableContainerProps: { sx: { maxHeight: '500px' } },
    enableRowActions: allowedActions?.edit,
    renderRowActions:
      allowedActions?.edit && editAction
        ? ({ row }) => {
            if (
              allowedActions?.editCondition &&
              !allowedActions.editCondition(row?.original as T)
            ) {
              return undefined;
            }
            return (
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  width: '100%',
                  justifyContent: 'center'
                }}>
                {editAction(row?.original as BasicEntity)}
              </Box>
            );
          }
        : undefined,
    renderTopToolbarCustomActions: (props) => renderTopToolbarCustomActions(props),
    getRowId: (row) => row._id,
    muiTableHeadCellProps: () => ({
      sx: columnsHeaderStyle
    }),
    muiTableBodyRowProps: bodyRowProps
  });

  return (
    <div style={{ display: 'flex ', flexDirection: 'column', gap: 12, position: 'relative' }}>
      {customTopTable
        ? customTopTable({
            auth,
            tableData,
            isloading
          })
        : tableName && <h2 className='title-DataTable'>{tableName.toLocaleUpperCase()}</h2>}
      {renderActions()}
      {isloading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <MaterialReactTable table={table} />
      )}

      {/*  <DataTable
        {...{
          columns: tableColumns,
          data: filteredItems,
          theme: 'invertedHeader',
          conditionalRowStyles: RowStyles(),
          customStyles: customStyles,
          pagination: true,
          paginationResetDefaultPage: resetPaginationToggle, // optionally, a hook to reset pagination to page 1
          progressPending: loading,
          subHeader: !serverSidePagination && ViewInput ? true : false,
          noHeader: !selectedRows.length ? true : false,
          subHeaderComponent: !serverSidePagination ? subHeaderComponentMemo : null,
          selectableRows: canSelect,
          pointerOnHover: singleSelect,
          onRowClicked,
          selectableRowDisabled: rowDisabledCriteria,
          persistTableHead: true,
          contextActions,
          onSelectedRowsChange: onSelectedRowsChange && handleRowSelected,
          clearSelectedRows: toggleCleared,
          striped: true,
          responsive: true,
          expandableRows: expandableRowsComponent ? true : false,
          expandOnRowClicked: expandableRowsComponent ? true : false,
          selectableRowsVisibleOnly: selectablePageOnly,
          paginationComponentOptions: paginationOptions,
          fixedHeader,
          fixedHeaderScrollHeight,
          paginationComponent: serverSidePagination
            ? (props: PaginationProps) => (
                <Pagination
                  {...props}
                  availablePages={pagination ? pagination.availablePages : 0}
                  currentRowsPerPage={pageSize}
                />
              )
            : undefined,
          expandableRowsComponent: expandableRowsComponent && expandableRowsComponent(),
          noDataComponent: <EmptyTableMessage />,
          ...{ ...additionalTableProps, ...serverSidePaginationOptions }
        }}
      />
     */}
    </div>
  );
};

export default GenericTable;
