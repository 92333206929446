import { Stack } from '@mui/material';
import { EquivalentBox } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const EquivalentBoxEntity: WebEntity<EquivalentBox> = {
  name: 'equivalentBox',
  endpoint: 'equivalentBox',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text'
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Fecha',
      accessorKey: 'equivalentBoxDate',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row.original?.equivalentBoxDate, 'DD-MM-YYYY', true)
    },
    {
      header: 'Cajas',
      accessorKey: 'boxQuantity',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.boxQuantity, 0, 0),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: EquivalentBox) =>
            obj.boxQuantity ? acc + Number(obj.boxQuantity) : acc,
          0
        );
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    }
  ]
};

export const EQUIVALENT_BOX_COLUMNS = {
  bp: 'BP',
  zonePortingDescription: 'Zona',
  carrierName: 'EETT',
  equivalentBoxDate: 'Fecha',
  boxQuantity: 'Cantidad Cajas'
};

export default EquivalentBoxEntity;
