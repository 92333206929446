import { ZoneGlobal } from '../types';
import { FieldTypes, WebEntity } from './types';

const ZoneGlobalEntity: WebEntity<ZoneGlobal> = {
  name: 'zoneGlobal',
  endpoint: 'zoneGlobal',
  referenceColumn: 'zoneDescrp',
  customButtonNames: {
    addButtonName: 'Agregar Zona Global'
  },
  customDataActions: {
    editModalTitle: 'Editar Zona Global'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Zona Global',
      selector: 'zoneGlobalDescrp',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Zona Global',
      selector: 'zoneGlobalDescrp',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default ZoneGlobalEntity;
