import { Box } from '@mui/material';
import { FileReaderColumnProps } from '../components/FileReader';
import { ShipmentCarriage } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const ShipmentCarriageEntity: WebEntity<ShipmentCarriage> = {
  name: 'shipmentCarriageQ1',
  endpoint: 'shipmentCarriage',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'N° Transporte',
      accessorKey: 'shipmentNumber',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Clase de Transporte',
      accessorKey: 'shipmentClassCarriageIdCcu',
      columnType: 'text',
      size: 240
    },
    {
      header: 'Ruta p.transporte',
      accessorKey: 'timeRouteIdCcu',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Id.1 / OCA',
      accessorKey: 'oca',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Stat.global transp.',
      accessorKey: 'shipmentPlanStation',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Agente servicios',
      accessorKey: 'bp',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Creado Por',
      accessorKey: 'createdBy',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Creado el',
      accessorKey: 'createdDateSource',
      columnType: 'dateTimeUTC',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original?.createdDateSource, 'DD/MM/YYYY HH:mm:ss', true)
    },
    {
      header: 'Patente/Matrícula Vehículo',
      accessorKey: 'vehiclePatent',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Patente/Matrícula Rampla',
      accessorKey: 'trailerPatent',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Fin act.transporte',
      accessorKey: 'endDateCarriageReport',
      columnType: 'dateUTC',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row.original?.createdDateSource, 'DD/MM/YYYY', true)
    },
    {
      header: 'Denom.ruta',
      accessorKey: 'route',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Nombre agente servicios transp.',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 350
    },
    {
      header: 'Tipo Envase/Carga',
      accessorKey: 'chargePackingType',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Creado Por',
      accessorKey: 'modifiedBy',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Tarifa',
      accessorKey: 'totalTariff',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row.original?.totalTariff, 0, 2)
    },
    {
      header: 'Bahias',
      accessorKey: 'truckBayQuantity',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row.original?.truckBayQuantity, 0, 0)
    },
    {
      header: 'Total PxQ',
      accessorKey: 'pxq',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row.original?.pxq, 0, 2),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum?.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => (obj.pxq ? acc + Number(obj.pxq) : acc), 0);
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Box>
        );
      }
    }
  ]
};

export const SHIPMENT_CARRIAGE_COLUMNS: FileReaderColumnProps = {
  shipmentNumber: 'Nº de transporte',
  shipmentClassCarriageIdCcu: 'Clase de transporte',
  timeRouteIdCcu: 'Ruta p.transporte',
  oca: { name: 'Id.1 / OCA', required: false, export: true, upload: true },
  shipmentPlanStation: 'Stat.global transp.',
  bp: 'Agente servicios',
  createdBy: 'Creado por',
  createdDateSource: 'Creado el',
  hours: 'Hora',
  vehiclePatent: 'Patente/Matrícula Vehículo',
  trailerPatent: 'Patente/Matrícula Rampla',
  endDateCarriageReport: 'Fin act.transporte',
  route: 'Denom.ruta',
  carrierName: 'Nombre agente servicios transp.',
  chargePackingType: 'Tipo Envase/Carga',
  modifiedBy: 'Modificado por',
  type: 'TIPO'
};

export default ShipmentCarriageEntity;
