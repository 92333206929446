import { FunctionComponent, useState, SyntheticEvent, useMemo } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BackButton from '../components/BackButton';
import { Maintainers } from './';
import {
  BudgetCarriageIngresoAcarreoEntity,
  BudgetCarriageGastoReproEntity,
  BudgetCarriageGastoAcarreoEntity,
  BudgetCarriageVolumenReproEntity
} from '../entities';
import '../css/carriageResumenPage.css';
import { BudgetTypeCarriageNames } from '../types';
import { CarriageResumeCRUD } from '../components/GeneratedComponents';

const CarriageResumePage: FunctionComponent = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const ProvisionMaintainer = useMemo(
    () => Maintainers.ProvisionCarriageCRUDMaintainerConnected,
    []
  );

  const BudgetCarriageMaintainer = useMemo(
    () => Maintainers.BudgetCarriageCRUDMaintainerConnected,
    []
  );

  return (
    <div className='carriage-resume-page-container'>
      <div>
        <BackButton route='/acarreo' />
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Resumen' value='1' />
            <Tab label='Presupuesto Ingreso Acarreo' value='2' />
            <Tab label='Presupuesto Gasto Repro' value='3' />
            <Tab label='Presupuesto Gasto Acarreo' value='4' />
            <Tab label='Presupuesto Volumen Repro' value='5' />
            <Tab label='Provision' value='6' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <CarriageResumeCRUD />
        </TabPanel>
        <TabPanel value='2'>
          <BudgetCarriageMaintainer
            entity={BudgetCarriageIngresoAcarreoEntity}
            budgetTypeCarriage={BudgetTypeCarriageNames.pptoIngresoAcarreo}
          />
        </TabPanel>
        <TabPanel value='3'>
          <BudgetCarriageMaintainer
            entity={BudgetCarriageGastoReproEntity}
            budgetTypeCarriage={BudgetTypeCarriageNames.pptoGastoRepro}
          />
        </TabPanel>
        <TabPanel value='4'>
          <BudgetCarriageMaintainer
            entity={BudgetCarriageGastoAcarreoEntity}
            budgetTypeCarriage={BudgetTypeCarriageNames.pptoGastoAcarreo}
          />
        </TabPanel>
        <TabPanel value='5'>
          <BudgetCarriageMaintainer
            entity={BudgetCarriageVolumenReproEntity}
            budgetTypeCarriage={BudgetTypeCarriageNames.volumenRepro}
          />
        </TabPanel>
        <TabPanel value='6'>
          <ProvisionMaintainer />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default CarriageResumePage;
