import { Stack } from '@mui/material';
import { PermanentTruck } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const PermanentTruckEntity: WebEntity<PermanentTruck> = {
  name: 'permanentTruckQ1',
  endpoint: 'permanentTruck',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Glosa',
      accessorKey: 'freightGloss',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Fecha',
      accessorKey: 'tariffDate',
      columnType: 'dateUTC',
      size: 180,
      Cell: ({ row }) => dateFormatFn(row.original?.tariffDate, 'DD-MM-YYYY', true)
    },
    {
      header: 'Valor Neto',
      accessorKey: 'netTariff',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) =>
        row?.original?.netTariff
          ? formatStringNumber(row?.original?.netTariff, 0, 2, '.', ',')
          : '',
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: PermanentTruck) => (obj.netTariff ? acc + Number(obj.netTariff) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Cantidad',
      accessorKey: 'truckQuantity',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) =>
        row?.original?.truckQuantity
          ? formatStringNumber(row?.original?.truckQuantity, 0, 2, '.', ',')
          : '',
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: PermanentTruck) =>
            obj.truckQuantity ? acc + Number(obj.truckQuantity) : acc,
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'totalTariff',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) =>
        row?.original?.totalTariff
          ? formatStringNumber(row?.original?.totalTariff, 0, 2, '.', ',')
          : '',
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: PermanentTruck) =>
            obj.totalTariff ? acc + Number(obj.totalTariff) : acc,
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Retencion por Contingencia',
      accessorKey: 'contingentWithholding',
      columnType: 'numeric',
      size: 300,
      Cell: ({ row }) =>
        row?.original?.contingentWithholding
          ? formatStringNumber(row?.original?.contingentWithholding, 0, 2, '.', ',')
          : '',
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: PermanentTruck) =>
            obj.contingentWithholding ? acc + Number(obj.contingentWithholding) : acc,
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    }
  ]
};

export default PermanentTruckEntity;

export const PERMANENT_TRUCK_COLUMNS = {
  bp: 'BP',
  carrierName: 'EETT',
  zonePortingDescription: 'ZONA',
  truckQuantity: 'CANTIDAD DE CAMIÓN',
  netTariff: 'VALOR CAMIÓN',
  tariffDate: 'FECHA'
};
