import { CarrierZoneGloss, ManagementTypeNames } from '../types';
import { FieldTypes, WebEntity } from './types';

const CarrierZoneGlossEntity: WebEntity<CarrierZoneGloss> = {
  name: 'carrierZoneGloss',
  endpoint: 'carrierZoneGloss',
  referenceColumn: 'carrierName',
  customButtonNames: {
    addButtonName: 'Agregar Glosa x Retención'
  },
  customDataActions: {
    editModalTitle: 'Editar Glosa x Retención'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      size: 100
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text',
      size: 130
    },
    {
      header: 'RUT',
      accessorKey: 'carrierRut',
      columnType: 'text',
      size: 130
    },
    {
      header: 'Transportista',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Glosa SAP',
      accessorKey: 'freightGlossSap',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Glosa Retención',
      accessorKey: 'freightGloss',
      columnType: 'text',
      size: 180
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text',
      size: 160
    }
  ],
  fields: [
    {
      name: 'Transportista',
      selector: 'carrierId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Transportista',
        endpoint: `carrier/managementType/${ManagementTypeNames.porteo}`,
        select: '_id',
        show: 'carrierName'
      }
    },
    {
      name: 'Glosa Sap',
      selector: 'freightGlossId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Glosa',
        endpoint: 'freightGloss/withoutSeverancePay',
        select: '_id',
        show: 'freightGlossSap'
      }
    },
    {
      name: 'Zona',
      selector: 'zonePortingId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Zona',
        endpoint: 'zonePorting',
        select: '_id',
        show: 'zonePortingDescription'
      }
    }
  ],
  editableFields: [
    {
      name: 'Transportista',
      selector: 'carrierId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Transportista',
        endpoint: `carrier/managementType/${ManagementTypeNames.porteo}`,
        select: '_id',
        show: 'carrierName'
      }
    },
    {
      name: 'Glosa Sap',
      selector: 'freightGlossId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Glosa',
        endpoint: 'freightGloss/withoutSeverancePay',
        select: '_id',
        show: 'freightGlossSap'
      }
    },
    {
      name: 'Zona',
      selector: 'zonePortingId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Zona',
        endpoint: 'zonePorting',
        select: '_id',
        show: 'zonePortingDescription'
      }
    }
  ]
};

export const CARRIER_ZONE_GLOSS_COLUMNS = {
  bp: 'BP',
  carrierRut: 'RUT',
  carrierName: 'TRANSPORTISTA',
  freightGlossSap: 'GLOSA',
  freightGloss: 'CONCEPTO',
  zonePortingDescription: 'ZONA'
};

export default CarrierZoneGlossEntity;
