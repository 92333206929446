import { Notification } from '../types';
import { FieldTypes, WebEntity } from './types';

const NotificationEntity: WebEntity<Notification> = {
  name: 'notification',
  endpoint: 'notification',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Notificacion'
  },
  customDataActions: {
    editModalTitle: 'Editar Notificacion'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Tipo de Notificacion',
      accessorKey: 'notificationTypeName',
      columnType: 'text'
    },
    {
      header: 'Usuarios',
      accessorKey: 'userUsername',
      columnType: 'text',
      Cell: ({ row }) => (row.original?.userUsername || '').join(', ')
    }
  ],

  fields: [
    {
      name: 'Tipo de Notificacion',
      selector: 'notificationTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'notificationTypeId',
        endpoint: 'notification/notificationType',
        select: 'notificationTypeId',
        show: 'notificationTypeName'
      }
    },
    {
      name: 'Usuarios',
      selector: 'userAccountId',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'userAccountId',
        endpoint: 'users',
        select: 'userAccountId',
        show: 'userUsername'
      }
    }
  ],

  editableFields: [
    {
      name: 'Tipo de Notificacion',
      selector: 'notificationTypeName',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Usuarios',
      selector: 'userAccountId',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'userAccountId',
        endpoint: 'users',
        select: 'userAccountId',
        show: 'userUsername'
      }
    }
  ]
};

export default NotificationEntity;
