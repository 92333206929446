import { Box } from '@mui/material';
import { ForeignExchangeEstimated } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const ForeignExchangeEstimatedEntity: WebEntity<ForeignExchangeEstimated> = {
  name: 'foreignExchangeEstimated',
  endpoint: 'foreignExchange/estimated',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Mes',
      accessorKey: 'month',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.month
          ? `${spanishMonthNames[new Date(row.original?.month).getUTCMonth()]} ${new Date(
              row.original?.month
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Dólar ($/dólar)',
      accessorKey: 'usd',
      columnType: 'numeric',
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Box>$ {formatStringNumber(row.original?.usd, 0, 2)}</Box>
        </div>
      )
    },
    {
      header: 'UF ($)',
      accessorKey: 'uf',
      columnType: 'numeric',
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Box>$ {formatStringNumber(row.original?.uf, 0, 2)}</Box>
        </div>
      )
    },
    {
      header: 'Diesel ENAP ($/litro)',
      accessorKey: 'dsl',
      columnType: 'numeric',
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Box>$ {formatStringNumber(row.original?.dsl, 0, 2)}</Box>
        </div>
      )
    }
  ]
};

export default ForeignExchangeEstimatedEntity;

export const FOREIGN_EXCHANGE_ESTIMATED_COLUMNS = {
  month: 'Mes',
  usd: 'Dólar ($/dólar)',
  uf: 'UF ($)',
  dsl: 'Diesel ENAP ($/litro)'
};
