import { SubConcept } from '../types';
import { FieldTypes, WebEntity } from './types';

const SubConceptEntity: WebEntity<SubConcept> = {
  name: 'subConcept',
  endpoint: 'subConcept',
  referenceColumn: 'subConceptDescription',
  customButtonNames: {
    addButtonName: 'Agregar Sub Concepto'
  },
  customDataActions: {
    editModalTitle: 'Editar Sub Concepto'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'Concepto',
      accessorKey: 'conceptName',
      columnType: 'text'
    },
    {
      header: 'Sub Concepto',
      accessorKey: 'subConceptDescription',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Sub Concepto',
      selector: 'subConceptDescription',
      required: true,
      type: FieldTypes.Text
    },
    {
      name: 'Concepto',
      selector: 'conceptId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Concepto',
        endpoint: 'concept',
        select: '_id',
        show: 'conceptName'
      }
    }
  ],

  editableFields: [
    {
      name: 'Sub Concepto',
      selector: 'subConceptDescription',
      required: true,
      type: FieldTypes.Text
    },
    {
      name: 'Concepto',
      selector: 'conceptId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Concepto',
        endpoint: 'concept',
        select: '_id',
        show: 'conceptName'
      }
    }
  ]
};

export default SubConceptEntity;
