import { FreightGloss } from '../types';
import { WebEntity } from './types';

const FreightGlossEntity: WebEntity<FreightGloss> = {
  name: 'freightGloss',
  endpoint: 'freightGloss',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      size: 100
    },
    {
      header: 'Glosa SAP',
      accessorKey: 'freightGlossSap',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Glosa Retención',
      accessorKey: 'freightGloss',
      columnType: 'text',
      size: 180
    }
  ]
};

export default FreightGlossEntity;
