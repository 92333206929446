import { CarrierTypeCarriage } from '../types';
import { WebEntity } from './types';

const CarrierTypeCarriageEntity: WebEntity<CarrierTypeCarriage> = {
  name: 'carrierTypeCarriage',
  endpoint: 'carrierRouteCarriage/carrierTypeCarriage',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'Tipo de Contrato',
      accessorKey: 'carrierTypeCarriageName',
      columnType: 'text'
    }
  ]
};

export default CarrierTypeCarriageEntity;
