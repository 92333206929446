import { FunctionComponent, CSSProperties } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

const BackButton: FunctionComponent<{ route: string; customStyle?: CSSProperties }> = ({
  route,
  customStyle
}) => {
  const router = useHistory();
  return (
    <Button
      style={customStyle}
      onClick={() => router.push(route)}
      variant='text'
      color='inherit'
      size='large'>
      <ArrowBackIosNewIcon />
      Volver
    </Button>
  );
};

export default BackButton;
