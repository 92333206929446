import { FunctionComponent } from 'react';
import BackButton from '../components/BackButton';
import { useHistory } from 'react-router-dom';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/haulingPage.css';

const HaulingPage: FunctionComponent = () => {
  const router = useHistory();
  const buttons: Array<{ name: string; href: string; disabled: boolean }> = [
    { name: 'Ingreso', href: '/mantenedores/acarreo/ifac', disabled: false },
    { name: 'Polinomio Acarreo', href: '/reporte/acarreo/polinomio', disabled: false },
    { name: 'Flete', href: '/mantenedores/acarreo/flete', disabled: false },
    { name: 'Extras', href: '/mantenedores/acarreo/extras', disabled: false },
    { name: 'Cobro UEN', href: '/reporte/acarreo/uen', disabled: false },
    { name: 'Resumen Acarreo', href: '/mantenedores/acarreo/resumen', disabled: false }
  ];
  return (
    <div className='hauling-page-container'>
      <BackButton route='/modulos' />
      <div className='hauling-page-body'>
        <div className='hauling-page-truck-img-container'>
          <GenericIcons icon='ccuTruck' className='hauling-page-truck-img' />
        </div>
        <div className='hauling-page-title-container'>
          <h2>Planificación Acarreo</h2>
        </div>
        <div className='hauling-page-buttons-section'>
          {buttons.map((btn, idx) => (
            <div key={`hauling-page-button-${idx}`} className='hauling-page-button-container'>
              <button
                onClick={() => router.push(btn.href)}
                disabled={btn.disabled}
                className={`hauling-page-button ${
                  btn.disabled ? 'hauling-page-button-disabled' : ''
                }`}>
                <GenericIcons icon='truckIcon' imageStyle={{ width: '80%' }} />
              </button>
              <p className='hauling-page-button-name'>{btn.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HaulingPage;
