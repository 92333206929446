import { Gloss } from '../types';
import { spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const GlossEntity: WebEntity<Gloss> = {
  name: 'gloss',
  endpoint: 'gloss',
  referenceColumn: 'glossDescription',
  customButtonNames: { addButtonName: 'Agregar Glosa' },
  customDataActions: {
    editModalTitle: 'Editar Glosa'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Sub concepto',
      accessorKey: 'subConceptDescription',
      columnType: 'text'
    },
    {
      header: 'Concepto',
      accessorKey: 'conceptName',
      columnType: 'text'
    },
    {
      header: 'Mes Pago',
      accessorKey: 'monthPayment',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.monthPayment
          ? `${spanishMonthNames[new Date(row.original?.monthPayment).getUTCMonth()]} ${new Date(
              row.original?.monthPayment
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Mes Cierre',
      accessorKey: 'monthClosing',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.monthClosing
          ? `${spanishMonthNames[new Date(row.original?.monthClosing).getUTCMonth()]} ${new Date(
              row.original?.monthClosing
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'CD',
      accessorKey: 'distributionCenter',
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text'
    },
    {
      header: 'Zona CD',
      accessorKey: 'distributionCenterZona',
      columnType: 'text'
    }
  ]

  /*fields: [
    {
      name: 'Sub concepto',
      selector: 'subConceptId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Sub concepto',
        endpoint: 'subConcept',
        select: 'subConceptId',
        show: 'subConceptDescription'
      }
    },
    { name: 'CD', selector: 'distributionCenter', type: FieldTypes.Text, required: true },
    {
      name: 'Zona',
      selector: 'zonePortingId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Zona',
        endpoint: 'zonePorting',
        select: 'zonePortingId',
        show: 'zonePortingDescription'
      }
    },
    { name: 'Zona CD', selector: 'distributionCenterZona', type: FieldTypes.Text, required: true }
  ],

  editableFields: [
    {
      name: 'Sub concepto',
      selector: 'subConceptId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Sub concepto',
        endpoint: 'subConcept',
        select: 'subConceptId',
        show: 'subConceptDescription'
      }
    },
    { name: 'CD', selector: 'distributionCenter', type: FieldTypes.Text, required: true },
    {
      name: 'Zona',
      selector: 'zonePortingId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Zona',
        endpoint: 'zonePorting',
        select: 'zonePortingId',
        show: 'zonePortingDescription'
      }
    },
    { name: 'Zona CD', selector: 'distributionCenterZona', type: FieldTypes.Text, required: true }
  ]*/
};

export const GLOSS_COLUMNS = {
  conceptName: { name: 'Concepto', required: false, export: true },
  subConceptDescription: 'Subconcepto',
  monthPayment: 'Mes_Pago',
  monthClosing: 'Mes_Cierre',
  distributionCenterZona: 'ZONA_CD',
  distributionCenter: 'CD',
  zonePortingDescription: 'ZONA'
};
export default GlossEntity;
