import { Box } from '@mui/material';
import { ResumeShipmentCarriage } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const percentageFormat = (v: string) => `${parseFloat((Number(v) * 100).toFixed(2).toString())}%`;

const ResumeShipmentCarriageEntity: WebEntity<ResumeShipmentCarriage> = {
  name: 'resumeShipmentCarriageQ1',
  endpoint: 'shipmentCarriage/resume',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'Tipo Transportista',
      accessorKey: 'carrierTypeCarriageName',
      columnType: 'text'
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'P*Q',
      accessorKey: 'pxq',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.pxq ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.pxq, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum?.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => (obj.pxq ? acc + Number(obj.pxq) : acc), 0);
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'Viajes',
      accessorKey: 'travelQuantity',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.travelQuantity ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.travelQuantity, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum?.toString(), 0, 2)}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.travelQuantity ? acc + Number(obj.travelQuantity) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '% Tipo',
      accessorKey: 'pxqPercentageByGroup',
      columnType: 'percentage',
      Cell: ({ row }) =>
        row?.original?.pxqPercentageByGroup ? (
          <Box sx={{ ...rightAlignStyle }}>
            {percentageFormat(row.original?.pxqPercentageByGroup)}
          </Box>
        ) : (
          ''
        )
    },
    {
      header: '% Total',
      accessorKey: 'pxqPercentageTotal',
      columnType: 'percentage',
      Cell: ({ row }) =>
        row?.original?.pxqPercentageTotal ? (
          <Box sx={{ ...rightAlignStyle }}>
            {percentageFormat(row.original?.pxqPercentageTotal)}
          </Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum && percentageFormat(sum?.toString())}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.pxqPercentageTotal ? acc + Number(obj.pxqPercentageTotal) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {percentageFormat(totalFooter.toString())}
          </Box>
        );
      }
    }
  ]
};

export default ResumeShipmentCarriageEntity;
