import { Stack } from '@mui/material';
import { MajorPorting } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const MajorPortingEntity: WebEntity<MajorPorting> = {
  name: 'majorPorting',
  endpoint: 'majorPorting',
  referenceColumn: '',

  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text'
    },
    {
      header: 'N° Documento',
      accessorKey: 'documentNumber',
      columnType: 'text'
    },
    {
      header: 'Fecha Contabilización',
      accessorKey: 'accountingDate',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row.original?.accountingDate, 'DD-MM-YYYY', true)
    },
    {
      header: 'Monto',
      accessorKey: 'amount',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row.original?.amount, 0, 0),
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (!isNaN(Number(obj.amount)) ? acc + Number(obj.amount) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString())}
          </Stack>
        );
      }
    },
    {
      header: 'Glosa',
      accessorKey: 'freightGloss',
      columnType: 'text'
    },
    {
      header: 'Rut Transportista',
      accessorKey: 'carrierRut',
      columnType: 'text'
    }
  ]
};

export default MajorPortingEntity;

export const MAJOR_PORTING_COLUMNS = {
  documentNumber: 'Nº documento',
  accountingDate: 'Fe.contabilización',
  amount: 'VALOR DE MONEDA DE SOCIEDAD',
  text: 'Texto',
  carrierRut: 'Asignación'
};
