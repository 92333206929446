import { Zone } from '../types';
import { FieldTypes, WebEntity } from './types';

const ZoneEntity: WebEntity<Zone> = {
  name: 'zone',
  endpoint: 'zone',
  referenceColumn: 'zoneDescrp',
  customButtonNames: {
    addButtonName: 'Agregar Zona'
  },
  customDataActions: {
    editModalTitle: 'Editar Zona'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      columnType: 'text'
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Zona',
      selector: 'zoneDescrp',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona Global',
      selector: 'zoneGlobalDescrp',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Zone Global',
        endpoint: 'zoneGlobal',
        select: 'zoneGlobalId',
        show: 'zoneGlobalDescrp'
      }
    }
  ],

  editableFields: [
    {
      name: 'Zona',
      selector: 'zoneDescrp',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona Global',
      selector: 'zoneGlobalDescrp',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Zone Global',
        endpoint: 'zoneGlobal',
        select: 'zoneGlobalDescrp',
        show: 'zoneGlobalDescrp'
      }
    }
  ]
};

export default ZoneEntity;
