import { FunctionComponent } from 'react';
import BackButton from '../components/BackButton';
import { useHistory } from 'react-router-dom';
import GenericIcons from '../components/generics/GenericIcons';
import '../css/freightPage.css';

const FreightPage: FunctionComponent = () => {
  const router = useHistory();
  const buttons: Array<{ name: string; href: string; disabled: boolean }> = [
    { name: 'Flete', href: '/mantenedores/porteo/gastos-porteo', disabled: false },
    { name: 'Extras', href: '/mantenedores/porteo/extras-porteo', disabled: false },
    { name: 'Diferencia Tarifa', href: '/mantenedores/diferencia-tarifa', disabled: false },
    {
      name: 'Retención Contingencia',
      href: '/reportes/porteo/retencion-contingencia',
      disabled: false
    },
    {
      name: 'Estado Financiero',
      href: '/reportes/porteo/estado-financiero',
      disabled: false
    }
  ];
  return (
    <div className='freight-page-container'>
      <BackButton route='/modulos' />
      <div className='freight-page-body'>
        <div className='freight-page-truck-img-container'>
          <GenericIcons icon='portingTruckImg' className='freight-page-truck-img' />
        </div>
        <div className='freight-page-title-container'>
          <h2>Planificación Porteo</h2>
        </div>
        <div className='freight-page-buttons-section'>
          {buttons.map((btn, idx) => (
            <div key={`freight-page-button-${idx}`} className='freight-page-button-container'>
              <button
                onClick={() => router.push(btn.href)}
                disabled={btn.disabled}
                className={`freight-page-button ${
                  btn.disabled ? 'freight-page-button-disabled' : ''
                }`}>
                <GenericIcons icon='truckIcon' imageStyle={{ width: '80%' }} />
              </button>
              <p className='freight-page-button-name'>{btn.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FreightPage;
