import { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const exportCSVHeader = (headers: Array<string>) => () => {
  try {
    const csvContent = headers.join(';') + '\n';
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContent], {
      type: 'text/csv;charset=utf-8;'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'plantilla.csv');
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};

const GenericHeaderExportButton: FunctionComponent<{ headers: Array<string> }> = ({ headers }) => (
  <Button variant='contained' color='info' onClick={exportCSVHeader(headers)}>
    <DownloadIcon />
    Descargar plantilla CSV
  </Button>
);

export default GenericHeaderExportButton;
