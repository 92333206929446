import { CarrierRouteCarriage, ManagementTypeNames } from '../types';
import { dateFormatFn, formatStringNumber, postgresIntervalFormat } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const CarrierRouteCarriageEntity: WebEntity<CarrierRouteCarriage> = {
  name: 'carrierRouteCarriage',
  endpoint: 'carrierRouteCarriage',
  referenceColumn: '_id',
  customButtonNames: { addButtonName: 'Agregar Contrato Tranportista' },
  customDataActions: {
    editModalTitle: 'Editar Contrato Tranportista'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Ruta p.transporte',
      accessorKey: 'timeRouteIdCcu',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Ruta',
      accessorKey: 'route',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Tiempo',
      accessorKey: 'time',
      columnType: 'text',
      size: 150,
      valueToExport: (row) => postgresIntervalFormat(row?.time),
      Cell: ({ row }) => postgresIntervalFormat(row.original?.time)
    },
    {
      header: 'Tipo de Contrato',
      accessorKey: 'carrierTypeCarriageName',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Tarifa',
      accessorKey: 'tariff',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row.original?.tariff, 0, 2)
    },
    {
      header: 'Valida Desde',
      accessorKey: 'dateValidFrom',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original?.dateValidFrom, 'DD-MM-YYYY', true)
    },
    {
      header: 'Valida Hasta',
      accessorKey: 'dateValidTo',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original?.dateValidTo, 'DD-MM-YYYY', true)
    }
  ],

  fields: [
    {
      name: 'EETT',
      selector: 'carrierId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'EETT',
        endpoint: `carrier/managementType/${ManagementTypeNames.acarreo}`,
        select: '_id',
        show: 'carrierName'
      }
    },
    {
      name: 'Ruta',
      selector: 'timeRouteId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Ruta',
        endpoint: 'timeRoute',
        select: '_id',
        show: 'timeRouteIdCcu,route'
      }
    },
    {
      name: 'Tipo de Contrato',
      selector: 'carrierTypeCarriageId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Tipo de Contrato',
        endpoint: 'carrierRouteCarriage/carrierTypeCarriage',
        select: '_id',
        show: 'carrierTypeCarriageName'
      }
    },
    {
      name: 'Tarifa',
      selector: 'tariff',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Valida Desde',
      selector: 'dateValidFrom',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true
    }
  ]
};

export const CARRIER_ROUTE_CARRIAGE_COLUMNS = {
  bp: 'BP',
  timeRouteIdCcu: 'Ruta p.transporte',
  carrierTypeCarriageName: 'Tipo de Contrato',
  tariff: 'Tarifa',
  dateValidFrom: 'Fecha Desde'
};

export default CarrierRouteCarriageEntity;
