import { Categories } from '../types';
import { FieldTypes, WebEntity } from './types';

const CategoriesEntity: WebEntity<Categories> = {
  name: 'categories',
  endpoint: 'categories',
  referenceColumn: 'categoriesName',
  customButtonNames: {
    addButtonName: 'Agregar Categoria'
  },
  customDataActions: {
    editModalTitle: 'Editar Categoria'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Nombre Categoria',
      accessorKey: 'categoriesName',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Nombre Categoria',
      selector: 'categoriesName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Nombre Categoria',
      selector: 'categoriesName',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export const CATEGORIES_COLUMNS = {
  accountSap: 'Cuenta de Mayor',
  categoriesName: 'Nombre Categorias',
  descripAccount: 'Cuenta de Mayor: Texto explicativo'
};

export default CategoriesEntity;
