import React, { CSSProperties, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'reactstrap';
import { logout } from '../../actions/authActions';

interface IProps {
  logout: typeof logout;
  style?: CSSProperties;
}

const Logout: FunctionComponent<IProps> = ({ logout, style }) => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <NavLink onClick={handleLogout} href='#'>
      <div style={style}>
        <strong>Cerrar Sesión</strong>
      </div>
    </NavLink>
  );
};

export default connect(null, { logout })(Logout);
