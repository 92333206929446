import { Box } from '@mui/material';
import { ForeignExchangeResume } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const isVariation = (str: string) => str === 'Variación' || str === 'VM';

const currencyFormat = (v: string) => `$ ${formatStringNumber(v, 0, 2)}`;
const percentageFormat = (v: string) => `${parseFloat((Number(v) * 100).toFixed(1).toString())}%`;

const ForeignExchangeResumeEntity: WebEntity<ForeignExchangeResume> = {
  name: 'foreignExchangeResume',
  endpoint: 'foreignExchange/resume',
  referenceColumn: 'foreignExchangeType',
  tableColumns: [
    {
      header: 'Tipo',
      accessorKey: 'foreignExchangeType',
      columnType: 'text'
    },
    {
      header: 'Variable',
      accessorKey: 'currencyName',
      columnType: 'text'
    },
    {
      header: 'Enero',
      accessorKey: 'january',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.january)
            ? percentageFormat(row.original?.january)
            : !isNaN(row.original?.january)
            ? currencyFormat(row.original?.january)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'february',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.february)
            ? percentageFormat(row.original?.february)
            : !isNaN(row.original?.february)
            ? currencyFormat(row.original?.february)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'march',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.march)
            ? percentageFormat(row.original?.march)
            : !isNaN(row.original?.march)
            ? currencyFormat(row.original?.march)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Abril',
      accessorKey: 'april',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.april)
            ? percentageFormat(row.original?.april)
            : !isNaN(row.original?.april)
            ? currencyFormat(row.original?.april)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'may',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.may)
            ? percentageFormat(row.original?.may)
            : !isNaN(row.original?.may)
            ? currencyFormat(row.original?.may)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Junio',
      accessorKey: 'june',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.june)
            ? percentageFormat(row.original?.june)
            : !isNaN(row.original?.june)
            ? currencyFormat(row.original?.june)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Julio',
      accessorKey: 'july',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.july)
            ? percentageFormat(row.original?.july)
            : !isNaN(row.original?.july)
            ? currencyFormat(row.original?.july)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'august',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.august)
            ? percentageFormat(row.original?.august)
            : !isNaN(row.original?.august)
            ? currencyFormat(row.original?.august)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'september',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.september)
            ? percentageFormat(row.original?.september)
            : !isNaN(row.original?.september)
            ? currencyFormat(row.original?.september)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'october',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.october)
            ? percentageFormat(row.original?.october)
            : !isNaN(row.original?.october)
            ? currencyFormat(row.original?.october)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'november',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.november)
            ? percentageFormat(row.original?.november)
            : !isNaN(row.original?.november)
            ? currencyFormat(row.original?.november)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'december',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const value =
          isVariation(row.original?.foreignExchangeType) && !isNaN(row.original?.december)
            ? percentageFormat(row.original?.december)
            : !isNaN(row.original?.december)
            ? currencyFormat(row.original?.december)
            : undefined;

        return value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box>{value}</Box>
          </div>
        ) : (
          ''
        );
      }
    }
  ]
};

export default ForeignExchangeResumeEntity;
