import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthState } from '../reducers/types';
import { UserTypeValues } from '../types';
import { allLinks } from './App';

export const useUserAccess = (auth: AuthState) => {
  const history = useHistory();

  //const { trackingCode } = useParams<{ trackingCode: string | undefined }>();

  useEffect(() => {
    const { isLoading, isAuthenticated, user } = auth;

    // Admin can see all the app links
    if (isLoading || user?.userTypeName.includes(UserTypeValues.admin)) {
      return;
    }

    // Anonimous users donesn't have links
    if (!isAuthenticated) {
      history.push(`/`);
      return;
    }

    // If is authenticated
    if (
      user?.userTypeName.includes(UserTypeValues.operador) ||
      user?.userTypeName.includes(UserTypeValues.boss) ||
      user?.userTypeName.includes(UserTypeValues.supervisor)
    ) {
      if (
        findRouteMatch(
          history.location.pathname,
          [...allLinks].map(({ href }) => href)
        )
      ) {
        return history.push(
          history.location.pathname === '/landing' ? '/mantenedores' : history.location
        );
      }
    }

    // If is any other user
    history.push('/');
  }, [auth, history]);
};

const findRouteMatch = (pathname: string, routes: Array<string>) => {
  for (let i = 0; i < routes.length; i++) {
    const currentRoute = routes[i];
    const regexRoute = currentRoute.replace(/:[^/]+/g, '([^/]+)');
    const regex = new RegExp('^' + regexRoute + '$');
    if (regex.test(pathname)) {
      return true;
    }
  }
  return false;
};
