import { Box } from '@mui/material';
import { ProvisionCarriage } from '../types';
import { WebEntity } from './types';
import { formatStringNumber } from '../utils/utils';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const ProvisionCarriageEntity: WebEntity<ProvisionCarriage> = {
  name: 'provisionCarriage',
  endpoint: 'provisionCarriage',
  referenceColumn: 'carrierName',
  tableColumns: [
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Enero',
      accessorKey: 'january',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.january ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.january, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.january ? acc + Number(obj.january) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'february',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.february ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.february, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) =>
            obj.february ? acc + Number(obj.february) : acc,
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'march',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.march ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.march, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.march ? acc + Number(obj.march) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Abril',
      accessorKey: 'april',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.april ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.april, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.april ? acc + Number(obj.april) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'may',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.may ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.may, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.may ? acc + Number(obj.may) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Junio',
      accessorKey: 'june',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.june ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.june, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.june ? acc + Number(obj.june) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Julio',
      accessorKey: 'july',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.july ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.july, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.july ? acc + Number(obj.july) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'august',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.august ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.august, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.august ? acc + Number(obj.august) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'september',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.september ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.september, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) =>
            obj.september ? acc + Number(obj.september) : acc,
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'october',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.october ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.october, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.october ? acc + Number(obj.october) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'november',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.november ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.november, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) =>
            obj.november ? acc + Number(obj.november) : acc,
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'december',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.december ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.december, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) =>
            obj.december ? acc + Number(obj.december) : acc,
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.total ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.total, 0, 0)}</Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: ProvisionCarriage) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    }
  ]
};

export const PROVISION_CARRIAGE_COLUMNS = {
  bp: 'BP',
  carrierName: 'EETT',
  provisionAmount: 'MONTO PROVISION'
};

export default ProvisionCarriageEntity;
