import { EstimatedClosedPending, EstimatedClosing } from '../types';
import { formatStringNumber, specialFormatStringNumber } from '../utils/utils';
import { WebEntity } from './types';
import {
  Alert,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EditExpensesEstimatedClosed } from '../components/SecondaryButton';
import { errorPopAlert } from '../components/PopAlert';
import { MRT_RowData } from 'material-react-table';

const EstimatedClosingEntity: WebEntity<EstimatedClosing> = {
  name: 'estimatedClosing',
  endpoint: 'expenses/estimatedClosing',
  referenceColumn: 'pendingBudget',
  tableColumns: [
    {
      header: 'Texto Explicativo',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      id: 'options',
      size: 540,
      Cell: ({ row, column }) => {
        const [EstimatedClosedPending, setEstimatedClosedPending] =
          useState<EstimatedClosedPending>({
            estimatedDescription: '',
            file: '',
            type: '',
            fileName: ''
          });
        const [offBudget, setOffBudget] = useState(false);
        const [selectedFile, setSelectedFile] = useState<File | null>(null);
        const data = [
          'REFACTURACION',
          'ARRIENDOS',
          'TRASLADOS',
          'MANTENCIONES',
          'AVANCE PROYECTOS',
          'SOLICITUD PERSONAL ADICIONAL',
          'JORNADA ADICIONAL',
          'INSTALACIONES',
          'SERVICIOS LIMPIEZA',
          'RENDICIONES',
          'APOYOS'
        ];

        const handleInput = (event: SelectChangeEvent) => {
          setEstimatedClosedPending({
            ...EstimatedClosedPending,
            estimatedDescription: event.target.value
          });
        };

        const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
          const fileInput = event.target;
          if (fileInput && fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            const reader = new FileReader();

            if (file.size > 5 * 1024 * 1024) {
              errorPopAlert('Tamaño de Archivo', 'El Archivo no puede pesar mas de 5MB');
              return setSelectedFile(null);
            }

            reader.onload = function () {
              if (typeof reader.result === 'string') {
                const base64Data = reader.result.split(',')[1];

                setEstimatedClosedPending({
                  ...EstimatedClosedPending,
                  file: base64Data,
                  type: file.type.split('/')[1],
                  fileName: file.name
                });
              } else {
                console.error('reader.result no es una cadena');
              }
            };
            setSelectedFile(file);
            reader.readAsDataURL(file);
          }
        };

        useEffect(() => {
          row.original[column.id] = {
            estimatedDescription: EstimatedClosedPending.estimatedDescription,
            file: EstimatedClosedPending.file,
            type: EstimatedClosedPending.type,
            fileName: EstimatedClosedPending.fileName,
            offBudget
          };
        }, [EstimatedClosedPending, column.id, row.original, offBudget]);

        useEffect(() => {
          if (
            (!isNaN(Number(row?.original?.presupuestoAnualTotal)) &&
              !isNaN(Number(row?.original?.estimatedTotal)) &&
              Number(row?.original?.presupuestoAnualTotal) === 0 &&
              Number(row?.original?.estimatedTotal) !== 0) ||
            row?.original?.porcentajeDiferencia * 100 > 10 ||
            row?.original?.porcentajeDiferencia * 100 < -15
          ) {
            setOffBudget(true);
          }
        }, [
          row?.original?.porcentajeDiferencia,
          row?.original?.presupuestoAnualTotal,
          row?.original?.estimatedTotal
        ]);

        return (
          <div style={{ display: ' flex', gap: 10 }}>
            {offBudget ? (
              <>
                <FormControl variant='filled' sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id='estimated-closing-category'>CATEGORIAS PARA ESTIMADO</InputLabel>
                  <Select
                    labelId='estimated-closing-category'
                    id='demo-simple-select-standard'
                    onChange={handleInput}
                    value={EstimatedClosedPending.estimatedDescription}
                    label='Age'>
                    {data?.map((p, i) => (
                      <MenuItem key={i} value={p}>
                        {p}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Input type='file' onChange={handleFileSelect} />
              </>
            ) : (
              <EditExpensesEstimatedClosed data={row?.original} />
            )}
            {EstimatedClosedPending.estimatedDescription !== '' && selectedFile && (
              <EditExpensesEstimatedClosed data={row?.original} />
            )}
          </div>
        );
      },
      AggregatedCell: ({ row }) => {
        const [rows, setRows] = useState<Array<MRT_RowData>>([]);
        const [offBudget, setOffBudget] = useState<boolean>(false);

        useEffect(() => {
          if (row.subRows) {
            setRows(row.subRows?.map((obj) => obj.original));
          }
        }, [row.subRows]);

        useEffect(() => {
          const isOffBudget = rows.some((obj) => {
            return (
              (!isNaN(Number(obj?.presupuestoAnualTotal)) &&
                !isNaN(Number(obj?.estimatedTotal)) &&
                Number(obj.presupuestoAnualTotal) === 0 &&
                Number(obj.estimatedTotal) !== 0) ||
              obj.porcentajeDiferencia * 100 < -15 ||
              obj.porcentajeDiferencia * 100 > 10
            );
          });

          setOffBudget(isOffBudget);
        }, [rows]);

        return offBudget && <Alert severity='error'>Fuera del presupuesto</Alert>;
      }
    },
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      id: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      id: 'zoneDescrp',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Nivel 4',
      accessorKey: 'description',
      id: 'description',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Categoria',
      accessorKey: 'categoriesName',
      id: 'categoriesName',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Cta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Cta PS',
      accessorKey: 'ctaPs',
      id: 'ctaPs',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Descripcion Categoria',
      accessorKey: 'accountDescription',
      id: 'accountDescription',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Presupuesto',
      accessorKey: 'presupuestoAnualTotal',
      id: 'presupuestoAnualTotal',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => formatStringNumber(row?.original?.presupuestoAnualTotal)
    },
    {
      header: 'Estimado Cierre',
      accessorKey: 'estimatedTotal',
      id: 'estimatedTotal',
      columnType: 'text',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row?.original?.estimatedTotal)
    },
    {
      header: 'Diferencia',
      accessorKey: 'diferencia',
      id: 'diferencia',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => `${formatStringNumber(row?.original?.diferencia)}`
    },
    {
      header: 'Porcentaje',
      accessorKey: 'porcentajeDiferencia',
      id: 'porcentajeDiferencia',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        `${specialFormatStringNumber(
          (Number(row?.original?.porcentajeDiferencia) * 100).toFixed(2),
          true,
          2
        )}%`
    }
  ]
};

export const ESTIMATED_CLOSING_COLUMNS = {
  costCenterSap: 'CC SAP',
  categoriesName: 'Categoria',
  accountSap: 'Cta',
  ctaPs: 'Cta PS',
  accountDescription: 'Descipcion Cuenta',
  estimatedTotal: 'Estimado Cierre'
};

export default EstimatedClosingEntity;
