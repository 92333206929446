import { useEffect, useState } from 'react';
import { CostCenter, SimpleObject } from '../types';
import { FieldTypes, WebEntity } from './types';
import { Dropdown } from 'semantic-ui-react';
import { getUncatchEndpointData } from '../utils/request';

const CustomField = (
  onChange: (inputValue: any) => void,
  value: any,
  fetchedFields: { [key: string]: Array<SimpleObject> }
) => {
  const SelectField: React.FC = () => {
    const [data, setData] = useState<Array<{ key: string; value: string; text: string }>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
      const getData = async () => {
        try {
          setLoading(true);
          const res =
            fetchedFields && fetchedFields.hasOwnProperty('costCenterSubId')
              ? fetchedFields['costCenterSubId']
              : await getUncatchEndpointData({ endpoint: 'costCenter/subCostCenters' });
          if (res && Array.isArray(res) && res.length > 0) {
            setData(
              res.map((row) => ({
                key: row._id,
                value: row._id,
                text: `${row.description} (zona: ${row.zoneDescrp})`
              }))
            );
            fetchedFields['costCenterSubId'] = res;
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getData();
    }, []);

    return (
      <Dropdown
        required
        fluid
        lazyLoad
        search
        selection
        placeholder='Seleccione Sub Categoria'
        disabled={loading}
        loading={loading}
        value={value}
        options={data}
        onChange={(_, data) => onChange(data.value)}
      />
    );
  };
  return (
    <div style={{ display: 'flex' }}>
      <SelectField />
    </div>
  );
};

const CostCenterEntity: WebEntity<CostCenter> = {
  name: 'costCenter',
  endpoint: 'costCenter',
  referenceColumn: 'costCenterName',
  customButtonNames: {
    addButtonName: 'Agregar Centro de costo'
  },
  customDataActions: {
    editModalTitle: 'Editar Centro de costo'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'SAP',
      accessorKey: 'costCenterSap',
      columnType: 'text'
    },
    {
      header: 'PSOFT',
      accessorKey: 'codeCostCenter',
      columnType: 'text'
    },
    {
      header: 'Nombre',
      accessorKey: 'costCenterName',
      columnType: 'text'
    },
    {
      header: 'Sub Categoria',
      accessorKey: 'description',
      columnType: 'text'
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'SAP',
      selector: 'costCenterSap',
      type: FieldTypes.Text,
      required: true,
      textUpperCase: true
    },
    {
      name: 'PSOFT',
      selector: 'codeCostCenter',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre',
      selector: 'costCenterName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Sub Categoria',
      selector: 'costCenterSubId',
      type: FieldTypes.Custom,
      required: true,
      CustomField
    }
  ],

  editableFields: [
    {
      name: 'SAP',
      selector: 'costCenterSap',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'PSOFT',
      selector: 'codeCostCenter',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre',
      selector: 'costCenterName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Sub Categoria',
      selector: 'costCenterSubId',
      type: FieldTypes.Custom,
      required: true,
      CustomField
    }
  ]
};

export default CostCenterEntity;
