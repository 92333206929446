import { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { UserTypeValues } from '../types';
import GenericDropdownMenu from './generics/GenericDropdownMenu/GenericDropdownMenu';

type Props = {
  auth: AuthState;
};

const allTree = [
  {
    id: 100000,
    label: 'Inicio',
    isOpened: false,
    icon: 'HouseIcon',
    href: '/'
  }
];

const adminTree = [
  {
    id: 300000,
    label: 'Mantenedores',
    isOpened: false,
    icon: 'mantainer',
    isTreeRoot: true,
    children: [
      {
        id: 310000,
        label: 'Mantenedores SD',
        isOpened: false,
        isTreeRoot: true,
        children: [
          {
            id: 311000,
            label: 'Gestionador de Zonas',
            isOpened: false,
            isTreeRoot: true,
            children: [
              {
                id: 311100,
                label: 'Centro de Costos',
                isOpened: false,
                href: '/mantenedores/SD/zonas/centro-de-costo'
              },
              {
                id: 311200,
                label: 'Sub Centro de costos',
                isOpened: false,
                href: '/mantenedores/SD/zonas/sub-cost-center'
              },
              {
                id: 311300,
                label: 'Zonas',
                isOpened: false,
                href: '/mantenedores/SD/zonas/zona'
              },
              {
                id: 311400,
                label: 'Zona Global',
                isOpened: false,
                href: '/mantenedores/SD/zonas/zona-global'
              },
              {
                id: 311500,
                label: 'Encargados por Zona',
                isOpened: false,
                href: '/mantenedores/SD/zonas/zona-encargados'
              }
            ]
          },
          {
            id: 312000,
            label: 'Gestionador Presupuesto',
            isOpened: false,
            isTreeRoot: true,
            children: [
              {
                id: 312100,
                label: 'Tipo Presupuesto',
                isOpened: false,
                href: '/mantenedores/SD/presupuesto/tipo-presupuesto'
              },
              {
                id: 312200,
                label: 'Presupuesto Anual',
                isOpened: false,
                href: '/mantenedores/SD/presupuesto/presupuesto-anual'
              }
            ]
          },
          {
            id: 313000,
            label: 'Gestionador Cuentas',
            isOpened: false,
            isTreeRoot: true,
            children: [
              {
                id: 313100,
                label: 'Categorias',
                isOpened: false,
                href: '/mantenedores/SD/categorias'
              },
              {
                id: 313200,
                label: 'Cuentas',
                isOpened: false,
                href: '/mantenedores/SD/cuentas'
              }
            ]
          }
        ]
      },
      {
        id: 320000,
        label: 'Mantenedores Porteo',
        isOpened: false,
        isTreeRoot: true,
        children: [
          {
            id: 321000,
            label: 'Gestionador de Tarifas',
            isOpened: false,
            isTreeRoot: true,
            children: [
              {
                id: 321100,
                label: 'Tarifas',
                isOpened: false,
                href: '/mantenedores/porteo/tarifas'
              },
              {
                id: 321200,
                label: 'Tipo de Transportistas',
                isOpened: false,
                href: '/mantenedores/porteo/tipo-transportista'
              }
            ]
          },
          {
            id: 322000,
            label: 'Gestionador de Presupuesto',
            isOpened: false,
            isTreeRoot: true,
            children: [
              {
                id: 322100,
                label: 'Tipo Presupuesto',
                isOpened: false,
                href: '/mantenedores/porteo/presupuesto/tipo-presupuesto'
              },
              {
                id: 322200,
                label: 'Presupuesto Anual',
                isOpened: false,
                href: '/mantenedores/porteo/presupuesto/presupuesto-anual'
              }
            ]
          },
          {
            id: 323000,
            label: 'Gestionador de Conceptos',
            isOpened: false,
            isTreeRoot: true,
            children: [
              {
                id: 323100,
                label: 'Conceptos',
                isOpened: false,
                href: '/mantenedores/porteo/conceptos'
              },
              {
                id: 323200,
                label: 'Sub Conceptos',
                isOpened: false,
                href: '/mantenedores/porteo/sub-conceptos'
              }
            ]
          },
          {
            id: 324000,
            label: 'Glosa',
            isOpened: false,
            href: '/mantenedores/porteo/glosa'
          },
          {
            id: 325000,
            label: 'Zonas Porteo',
            isOpened: false,
            href: '/mantenedores/porteo/zona-porteo'
          },
          {
            id: 326000,
            label: 'Cajas Equivalentes',
            isOpened: false,
            href: '/mantenedores/porteo/cajas-equivalentes'
          },
          {
            id: 327000,
            label: 'Mayor Porteo',
            isOpened: false,
            href: '/mantenedores/porteo/mayor'
          },
          {
            id: 328000,
            label: 'Camiones Fijos',
            isOpened: false,
            href: '/mantenedores/porteo/camiones-fijos'
          },
          {
            id: 329000,
            label: 'Glosa x Retención',
            isOpened: false,
            href: '/mantenedores/porteo/glosa-x-retencion'
          },
          {
            id: 329001,
            label: 'NS Emprendedores',
            isOpened: false,
            href: '/mantenedores/porteo/cs-emprendedores'
          }
        ]
      },
      {
        id: 330000,
        label: 'Mantenedores Acarreo',
        isOpened: false,
        isTreeRoot: true,
        children: [
          {
            id: 331000,
            label: 'Tiempo De Ruta',
            isOpened: false,
            href: '/mantenedores/acarreo/tiempo-de-ruta'
          },
          {
            id: 332000,
            label: 'Variables Macroeconomicas',
            isOpened: false,
            href: '/mantenedores/acarreo/variables-macro'
          },
          {
            id: 333000,
            label: 'Tarifas Transportistas',
            isOpened: false,
            href: '/mantenedores/acarreo/contrato-transportistas'
          }
        ]
      },
      {
        id: 340000,
        label: 'Transportistas',
        isOpened: false,
        icon: 'truckIcon2',
        href: '/mantenedores/transportistas'
      },
      {
        id: 350000,
        label: 'Calendario',
        isOpened: false,
        icon: 'calendarIcon',
        href: '/mantenedores/calendario'
      }
    ]
  },
  {
    id: 400000,
    label: 'Administración',
    isOpened: false,
    icon: 'mantainer',
    isTreeRoot: true,
    children: [
      {
        id: 410000,
        label: 'Usuarios',
        isOpened: false,
        href: '/administracion/usuario'
      },
      {
        id: 420000,
        label: 'Gestionador de Mapa Centro',
        isOpened: false,
        href: '/administracion/mapa'
      },
      {
        id: 430000,
        label: 'Gestionador de Notificaciones',
        isOpened: false,
        href: '/administracion/notificaciones'
      }
    ]
  },
  {
    id: 500000,
    label: 'Inconsistencias',
    isOpened: false,
    icon: 'report',
    isTreeRoot: true,
    children: [
      {
        id: 510000,
        label: 'Porteo',
        isOpened: false,
        isTreeRoot: true,
        children: [
          {
            id: 511000,
            label: 'Retencion por Contingencia',
            isOpened: false,
            href: '/reporte/inconsistencias/porteo/retencion-contingencia'
          },
          {
            id: 512000,
            label: 'Extras',
            isOpened: false,
            href: '/reporte/inconsistencias/porteo/extras'
          }
        ]
      }
    ]
  }
];

const bossTree = [
  {
    id: 600000,
    label: 'Reportes',
    isOpened: false,
    icon: 'report',
    isTreeRoot: true,
    children: [
      {
        id: 610000,
        label: 'Totales Generales',
        isOpened: false,
        href: '/reporte/totales-generales'
      }
    ]
  }
];

const AppNavbar: FunctionComponent<Props> = ({ auth }) => {
  const { isAuthenticated, user } = auth;

  return (
    <div>
      <div>
        {isAuthenticated &&
        (user!.userTypeName.includes(UserTypeValues.admin) ||
          user!.userTypeName.includes(UserTypeValues.operador) ||
          user!.userTypeName.includes(UserTypeValues.supervisor) ||
          user!.userTypeName.includes(UserTypeValues.boss)) ? (
          <div>
            {
              <GenericDropdownMenu
                menu={
                  // TODO: Modificar Arbol para que se defina dentro de cada rama un array de tipos de usuario con acceso
                  // luego aca re "genera" otro arbol solamente con lo que el usuario en sesion tiene acceso
                  auth.user?.userTypeName.includes(UserTypeValues.admin)
                    ? [...allTree, ...adminTree, ...bossTree]
                    : auth.user?.userTypeName.includes(UserTypeValues.boss)
                    ? [...allTree, ...bossTree]
                    : allTree
                }
                auth={auth}
              />
            }
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(AppNavbar);
