import { Provision } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const ApprovedProvisionEntity: WebEntity<Provision> = {
  name: 'provision',
  endpoint: 'provision/approvedProvision',
  referenceColumn: 'provision',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Correlativo',
      accessorKey: 'correlative',
      columnType: 'text'
    },
    {
      header: 'Grupo Ledgers',
      accessorKey: 'ledgerGroup',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sociedad',
      accessorKey: 'society',
      columnType: 'text'
    },
    {
      header: 'Clase de Documento',
      accessorKey: 'documentType',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Fecha de Documento',
      accessorKey: 'documentDate',
      columnType: 'dateUTC',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row?.original?.documentDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Fecha de Contabilizacion',
      accessorKey: 'accountingDate',
      columnType: 'dateUTC',
      size: 300,
      Cell: ({ row }) => dateFormatFn(row?.original?.accountingDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Moneda',
      accessorKey: 'coinDescription',
      columnType: 'text'
    },
    {
      header: 'Texto Cabecera Docuemnto',
      accessorKey: 'textDocument',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Tipo de cuenta',
      accessorKey: 'typeAccount',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Numero de cuenta',
      accessorKey: 'accountSap',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Importe MDA',
      accessorKey: 'amountMd',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row?.original?.amountMd)
    },
    {
      header: 'Asignacion',
      accessorKey: 'assignment',
      columnType: 'text'
    },
    {
      header: 'Texto Posicion',
      accessorKey: 'text',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Centro Beneficio',
      accessorKey: 'centroBeneficio',
      columnType: 'text',
      size: 210
    },
    {
      header: 'Centro de coste',
      accessorKey: 'centroCoste',
      columnType: 'text',
      size: 200
    }
  ]
};

export default ApprovedProvisionEntity;
