import React, { FunctionComponent, useState } from 'react';
import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { WebEntity } from '../../entities/types';
import { BasicEntity } from '../../types';
import { capitalize } from '../../utils/utils';
import { GenericForm } from './GenericForm';
import '../../css/genericModalForm.css';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ModalButton: FunctionComponent<{ buttonName: string; toggle: () => void }> = ({
  buttonName,
  toggle
}) => (
  <Button onClick={toggle} variant='contained' color='primary'>
    <AddIcon />
    {buttonName}
  </Button>
);

const renderModalButton = (
  alternativeButton: ((toggle: () => void) => JSX.Element) | undefined,
  toggle: () => void,
  name: string
) =>
  alternativeButton ? alternativeButton(toggle) : <ModalButton toggle={toggle} buttonName={name} />;

const voidFunction = () => {};

type GenericModalFormProps<T> = {
  webEntity: WebEntity<T>;
  errorMsg: string;
  isAuthenticated: boolean;
  isAdmin: boolean;
  actionFn: (formFields: any) => any;
  alternativeButton?: (toggle: () => void) => React.JSX.Element;
  entityToEdit?: BasicEntity;
  title?: string;
  centered?: boolean;
  closeOnClickOut?: boolean;
  actionName?: string;
  buttonName?: string;
};

const GenericModalForm = <T,>({
  webEntity,
  errorMsg = '',
  isAuthenticated,
  isAdmin,
  actionFn,
  alternativeButton,
  entityToEdit,
  title = '',
  centered = false,
  closeOnClickOut = false,
  actionName = '',
  buttonName = ''
}: GenericModalFormProps<T>) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const onSubmit = async (e: Event, formFields: T) => {
    e.preventDefault();

    // Add Entity
    await actionFn(formFields);
    toggleModal();
  };

  const { name, fields, editableFields } = webEntity;

  if (entityToEdit && !editableFields) {
    throw new Error(`Entity [${webEntity.name}] does not provide a list of editable fields`);
  }

  return (
    <>
      {isAuthenticated ? (
        renderModalButton(
          alternativeButton,
          toggleModal,
          buttonName || `Agregar ${capitalize(name)}`
        )
      ) : (
        <h4 className='mb-3 ml4'>{`Identificate para administrar ${name}`}</h4>
      )}
      <Modal
        isOpen={modal}
        autoFocus={false}
        centered={centered}
        toggle={closeOnClickOut ? toggleModal : voidFunction}>
        <ModalHeader toggle={toggleModal} className='generic-modal-form-header'>
          {title || buttonName || `Agregar ${name}`}
        </ModalHeader>
        <ModalBody>
          {errorMsg && <Alert color='danger'>{errorMsg}</Alert>}
          <GenericForm
            {...{
              fields: entityToEdit ? editableFields : fields,
              onSubmit,
              actionName,
              isAdmin,
              entityToEdit
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default GenericModalForm;
