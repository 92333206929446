import { FunctionComponent, SyntheticEvent, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BackButton from '../components/BackButton';
import GenericModal from '../components/generics/GenericModal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileReaderCSV from '../components/FileReader';
import Swal from 'sweetalert2';
import { IFAC_COLUMNS } from '../entities/IfacEntity';
import { massiveLoadIfac } from '../actions/middleActions';
import { connect } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IfacType, SimpleObject } from '../types';
import { DatePicker } from '@mui/x-date-pickers';
import { Maintainers } from './';
import moment from 'moment';
import '../css/ifacPage.css';
import GenericInfoTag from '../components/generics/GenericInfoTag';

const IfacPage: FunctionComponent<{
  massiveLoadIfac: (
    data: Array<SimpleObject>,
    selectedDate: moment.Moment,
    ifacType: IfacType
  ) => any;
}> = ({ massiveLoadIfac }) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [value, setValue] = useState('1');
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const IfacReport = useMemo(() => Maintainers.IfacCRUDMaintainer, []);
  const IfacSimulatedReport = useMemo(() => Maintainers.IfacSimulatedCRUDMaintainerConnected, []);

  const uploadFile = async (data: any) => {
    if (!data) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes cargar un archivo`
      });
      return;
    } else if (!selectedDate) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: `Debes seleccionar un Mes`
      });
      return;
    }

    const res = await massiveLoadIfac(
      data,
      selectedDate,
      value === '1' ? IfacType.netValue : IfacType.palletQuantity
    );

    if (res) {
      setSelectedDate(null);
      setCloseModal(true);
    } else {
      throw Error;
    }
  };

  return (
    <div className='ifac-page-container'>
      <BackButton route='/acarreo' />
      <GenericModal
        title={'CARGA MASIVA IFAC'}
        buttonName={'CARGA MASIVA IFAC'}
        buttonIcon={<FileUploadIcon />}
        buttonVariant='contained'
        buttonColor='success'
        openHook={() => setCloseModal(false)}
        closeModal={closeModal}>
        <div className='ifac-page-massive-load-modal-container'>
          <GenericInfoTag
            icon='info'
            text={`La fecha de carga es la columna 'Fecha Doc. Material Recepción'`}
            strongTitle='Importante!'
          />

          <div>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
              <DatePicker
                label='Fecha Doc. Material Recepción'
                views={['year', 'month']}
                openTo='year'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </LocalizationProvider>
          </div>

          <FileReaderCSV columns={IFAC_COLUMNS} callback={uploadFile} disabled={!selectedDate} />
        </div>
      </GenericModal>
      <div className='ifac-page-body'>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              textColor='inherit'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#64a70b'
                }
              }}>
              <Tab label='Valor Neto' value='1' />
              <Tab label='Cantidad Pallet' value='2' />
              <Tab label='Simulación IFAC' value='3' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <IfacReport title='IFAC Valor Neto' ifacType={IfacType.netValue} />
          </TabPanel>
          <TabPanel value='2'>
            <IfacReport title='IFAC Cantidad Pallet' ifacType={IfacType.palletQuantity} />
          </TabPanel>
          <TabPanel value='3'>
            <IfacSimulatedReport />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export const IfacPageConnected = connect(null, {
  massiveLoadIfac
})(IfacPage);
