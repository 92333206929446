import { ConfirmRefusedProvisionButton } from '../components/SecondaryButton';
import { RefusedProvision } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const RefusedProvisionEntity: WebEntity<RefusedProvision> = {
  name: 'provision',
  endpoint: 'provision/refusedProvision',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Opciones',
      accessorKey: '_id',
      id: 'Options',
      omitExport: true,
      columnType: 'text',
      size: 200,
      Cell: ({ row }) => ConfirmRefusedProvisionButton(row?.original._id, row.original?.amountMd)
    },
    {
      header: 'Comentario',
      accessorKey: 'comment',
      id: 'comment',
      columnType: 'text'
    },
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Correlativo',
      accessorKey: 'correlative',
      id: 'correlative',
      columnType: 'text'
    },
    {
      header: 'Grupo Ledgers',
      accessorKey: 'ledgerGroup',
      id: 'ledgerGroup',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sociedad',
      accessorKey: 'society',
      id: 'society',
      columnType: 'text'
    },
    {
      header: 'Clase de Documento',
      accessorKey: 'documentType',
      id: 'documentType',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Fecha de Documento',
      accessorKey: 'documentDate',
      id: 'documentDate',
      columnType: 'dateUTC',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row?.original?.documentDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Fecha de Contabilizacion',
      accessorKey: 'accountingDate',
      id: 'accountingDate',
      columnType: 'dateUTC',
      size: 300,
      Cell: ({ row }) => dateFormatFn(row?.original?.accountingDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Moneda',
      accessorKey: 'coinDescription',
      id: 'coinDescription',
      columnType: 'text'
    },
    {
      header: 'Texto Cabecera Docuemnto',
      accessorKey: 'textDocument',
      id: 'textDocument',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Tipo de cuenta',
      accessorKey: 'typeAccount',
      id: 'typeAccount',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Numero de cuenta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Cuenta: Texto',
      accessorKey: 'accountDescription',
      id: 'accountDescription',
      columnType: 'text'
    },
    {
      header: 'Importe MDA',
      accessorKey: 'amountMd',
      id: 'amountMd',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row?.original?.amountMd)
    },
    {
      header: 'Asignacion',
      accessorKey: 'assignment',
      id: 'assignment',
      columnType: 'text'
    },
    {
      header: 'Texto Posicion',
      accessorKey: 'text',
      id: 'text',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Centro Beneficio',
      accessorKey: 'centroBeneficio',
      id: 'centroBeneficio',
      columnType: 'text',
      size: 210
    },
    {
      header: 'Centro Beneficio : Texto',
      accessorKey: 'costCenterName',
      id: 'costCenterName',
      columnType: 'text',
      size: 210
    },
    {
      header: 'Centro de coste',
      accessorKey: 'centroCoste',
      id: 'centroCoste',
      columnType: 'text',
      size: 200
    }
  ],

  editableFields: [
    {
      name: 'Total',
      selector: 'amountMd',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Cuenta',
      selector: 'accountDescription',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Cuenta',
        endpoint: 'dictionaryCc',
        select: 'accountDescription',
        show: 'accountDescription'
      }
    },
    {
      name: 'Centro de costo',
      selector: 'costCenterName',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'costCenter',
        endpoint: 'costCenter/user',
        select: 'costCenterName',
        show: 'costCenterName'
      }
    }
  ]
};

export default RefusedProvisionEntity;
