import { Box } from '@mui/material';
import { Polynomial } from '../types';
import { WebEntity } from './types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const PolynomialEntity: WebEntity<Polynomial> = {
  name: 'polynomial',
  endpoint: 'polynomial',
  referenceColumn: 'date',
  tableColumns: [
    {
      header: 'Fecha',
      accessorKey: 'date',
      columnType: 'dateUTC',
      Cell: ({ row }) => (
        <Box sx={{ fontWeight: 'bold' }}>
          {row.original?.date.includes('VARIACIÓN')
            ? row.original.date
            : dateFormatFn(row.original?.date, 'DD/MM/YYYY', true)}
        </Box>
      )
    },
    {
      header: 'Dólar',
      accessorKey: 'usdValue',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const isVar = row.original?.date.includes('VARIACIÓN');
        const style = isVar
          ? Number(row.original?.usdValue) < 0
            ? { ...rightAlignStyle, color: 'red', fontWeight: 'bold' }
            : { ...rightAlignStyle, color: 'green', fontWeight: 'bold' }
          : { ...rightAlignStyle };
        const value = isVar
          ? `${formatStringNumber((Number(row.original?.usdValue) * 100).toString(), 0, 2)} %`
          : formatStringNumber(row.original?.usdValue, 0, 2);
        return <Box sx={style}>{value}</Box>;
      }
    },
    {
      header: 'UF',
      accessorKey: 'ufValue',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const isVar = row.original?.date.includes('VARIACIÓN');
        const style = isVar
          ? Number(row.original?.ufValue) < 0
            ? { ...rightAlignStyle, color: 'red', fontWeight: 'bold' }
            : { ...rightAlignStyle, color: 'green', fontWeight: 'bold' }
          : { ...rightAlignStyle };
        const value = isVar
          ? `${formatStringNumber((Number(row.original?.ufValue) * 100).toString(), 0, 2)} %`
          : formatStringNumber(row.original?.ufValue, 0, 2);
        return <Box sx={style}>{value}</Box>;
      }
    },
    {
      header: 'Petroleo',
      accessorKey: 'dslValue',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const isVar = row.original?.date.includes('VARIACIÓN');
        const style = isVar
          ? Number(row.original?.dslValue) < 0
            ? { ...rightAlignStyle, color: 'red', fontWeight: 'bold' }
            : { ...rightAlignStyle, color: 'green', fontWeight: 'bold' }
          : { ...rightAlignStyle };
        const value = isVar
          ? `${formatStringNumber((Number(row.original?.dslValue) * 100).toString(), 0, 2)} %`
          : formatStringNumber(row.original?.dslValue, 0, 2);
        return <Box sx={style}>{value}</Box>;
      }
    }
  ]
};

export default PolynomialEntity;
