import { Concept } from '../types';
import { FieldTypes, WebEntity } from './types';

const ConceptEntity: WebEntity<Concept> = {
  name: 'concept',
  endpoint: 'concept',
  referenceColumn: 'conceptName',
  customButtonNames: {
    addButtonName: 'Agregar Concepto'
  },
  customDataActions: {
    editModalTitle: 'Editar Concepto'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'Concepto',
      accessorKey: 'conceptName',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Concepto',
      selector: 'conceptName',
      required: true,
      type: FieldTypes.Text
    }
  ],

  editableFields: [
    {
      name: 'Concepto',
      selector: 'conceptName',
      required: true,
      type: FieldTypes.Text
    }
  ]
};

export default ConceptEntity;
