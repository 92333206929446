import React, { CSSProperties, FunctionComponent } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal } from 'semantic-ui-react';
import { Button, ButtonPropsVariantOverrides, ButtonPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

type Props = {
  buttonName: string;
  buttonIcon?: JSX.Element;
  buttonVariant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  buttonColor?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
  title?: string;
  openHook?: () => void;
  closeHook?: () => void;
  autoOpen?: number; // NOTE: A random number, such as Date.now()
  style?: CSSProperties;
  modalStyle?: CSSProperties;
  closeModal?: boolean;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
};

const GenericModal: FunctionComponent<Props> = ({
  buttonName = 'Abrir',
  buttonIcon,
  buttonVariant,
  buttonColor,
  title,
  openHook = () => {},
  closeHook = () => {},
  autoOpen,
  children,
  style,
  modalStyle,
  closeModal,
  size = 'small'
}) => {
  const [open, setOpen] = React.useState(false);
  const triggerButton = React.useMemo(
    () => (
      <Button
        style={style}
        variant={buttonVariant}
        color={buttonColor}
        onClick={() => {
          setOpen(true);
          openHook();
        }}>
        {buttonIcon}
        {buttonName}
      </Button>
    ),
    [buttonName, buttonIcon, buttonVariant, buttonColor, style, openHook]
  );
  React.useEffect(() => {
    if (closeModal) {
      setOpen(false);
    }
  }, [closeModal]);

  React.useEffect(() => {
    if (autoOpen) {
      setOpen(true);
    }
  }, [autoOpen]);

  const onClose = React.useMemo(
    () => () => {
      setOpen(false);
      closeHook();
    },
    [closeHook]
  );

  const Styles = {
    default: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      overflow: 'visible'
    }
  };

  const renderChildrenWithProps = () => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        // Manejar casos especiales, como ReactPortal
        if (React.isValidElement<{ onClose: () => void }>(child)) {
          return React.cloneElement<{ onClose: () => void }>(child, { onClose });
        }
      }
      return child;
    });
  };

  return (
    <Modal
      closeIcon={true}
      open={open}
      size={size}
      style={modalStyle ? modalStyle : Styles.default}
      onClose={onClose}
      trigger={autoOpen ? undefined : triggerButton}>
      {title ? <Modal.Header>{title}</Modal.Header> : null}
      <Modal.Content>
        <Modal.Description>{renderChildrenWithProps()}</Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default GenericModal;
