import { Box } from '@mui/material';
import { FareDifferenceSummary } from '../types';
import { WebEntity } from './types';
import { formatStringNumber } from '../utils/utils';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const percentageOptions = {
  size: 60,
  enableGrouping: false,
  enableResizing: false,
  enableColumnOrdering: false,
  enableSorting: false,
  enableColumnActions: false
};

const FareDifferenceSummaryEntity: WebEntity<FareDifferenceSummary> = {
  name: 'fareDifferenceSummary',
  endpoint: 'fareDifference/summary',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Enero',
      accessorKey: 'january',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.january ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.january) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.january, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) =>
            obj.january ? acc + Number(obj.january) : acc,
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'januaryPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.januaryPercentage
          ? row?.original?.januaryPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'february',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.february ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.february) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.february, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) =>
            obj.february ? acc + Number(obj.february) : acc,
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'februaryPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.februaryPercentage
          ? row?.original?.februaryPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'march',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.march ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.march) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.march, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) => (obj.march ? acc + Number(obj.march) : acc),
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'marchPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.marchPercentage
          ? row?.original?.marchPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Abril',
      accessorKey: 'april',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.april ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.april) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.april, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) => (obj.april ? acc + Number(obj.april) : acc),
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'aprilPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.aprilPercentage
          ? row?.original?.aprilPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'may',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.may ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.may) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.may, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) => (obj.may ? acc + Number(obj.may) : acc),
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'mayPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.mayPercentage ? row?.original?.mayPercentage * 100 : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Junio',
      accessorKey: 'june',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.june ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.june) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.june, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) => (obj.june ? acc + Number(obj.june) : acc),
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'junePercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.junePercentage
          ? row?.original?.junePercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Julio',
      accessorKey: 'july',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.july ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.july) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.july, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) => (obj.july ? acc + Number(obj.july) : acc),
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'julyPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.julyPercentage
          ? row?.original?.julyPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'august',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.august ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.august) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.august, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) =>
            obj.august ? acc + Number(obj.august) : acc,
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'augustPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.augustPercentage
          ? row?.original?.augustPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'september',
      columnType: 'numeric',
      size: 170,
      Cell: ({ row }) =>
        row?.original?.september ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.september) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.september, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) =>
            obj.september ? acc + Number(obj.september) : acc,
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'septemberPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.septemberPercentage
          ? row?.original?.septemberPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'october',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.october ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.october) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.october, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) =>
            obj.october ? acc + Number(obj.october) : acc,
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'octoberPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.octoberPercentage
          ? row?.original?.octoberPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'november',
      columnType: 'numeric',
      size: 170,
      Cell: ({ row }) =>
        row?.original?.november ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.november) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.november, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) =>
            obj.november ? acc + Number(obj.november) : acc,
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'novemberPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.novemberPercentage
          ? row?.original?.novemberPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'december',
      columnType: 'numeric',
      size: 160,
      Cell: ({ row }) =>
        row?.original?.december ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.december) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.december, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) =>
            obj.december ? acc + Number(obj.december) : acc,
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: '%',
      accessorKey: 'decemberPercentage',
      columnType: 'numeric',
      ...percentageOptions,
      Cell: ({ row }) => {
        const percentage = row?.original?.decemberPercentage
          ? row?.original?.decemberPercentage * 100
          : null;
        return percentage != null ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${percentage >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(percentage.toString(), 0, 2)}%
          </Box>
        ) : (
          ''
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.total ? (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${Number(row?.original?.total) >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(row?.original?.total, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: FareDifferenceSummary) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    }
  ]
};

export default FareDifferenceSummaryEntity;
