import { FunctionComponent, SyntheticEvent, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BackButton from '../components/BackButton';
import { Reports } from './';
import '../css/fareDifferencePage.css';

const FareDifferencePage: FunctionComponent = () => {
  const [value, setValue] = useState('1');
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const FareDifferenceReport = useMemo(() => Reports.FareDifferenceReportConnected, []);
  const FareDifferenceSummaryReport = useMemo(() => Reports.FareDifferenceSummaryReport, []);

  return (
    <div className='fare-difference-page-container'>
      <BackButton route='/porteo' />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Diferencia Tarifa' value='1' />
            <Tab label='Diferencia Tarifa Emprendedores' value='2' />
            <Tab label='Resumen Diferencia Tarifa' value='3' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <FareDifferenceReport entrepreneurs={false} />
        </TabPanel>
        <TabPanel value='2'>
          <FareDifferenceReport entrepreneurs={true} />
        </TabPanel>
        <TabPanel value='3'>
          <FareDifferenceSummaryReport />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default FareDifferencePage;
