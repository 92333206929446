import moment from 'moment';
import { fieldSelector, formatStringNumber } from './utils';
import { utils, writeFile } from 'xlsx-js-style';
import { ColumnType } from '../entities/types';

const DEFAULT_CSV_FILENAME = 'reporte';

export const convertArrayOfObjectsToCSV = (
  array: any[],
  selectors: any[],
  columnNames: string[],
  columnDelimiter = ';'
) => {
  let result: any;

  const lineDelimiter = '\n';

  result = '';
  result += columnNames.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    selectors.forEach((selector: any) => {
      if (ctr > 0) result += columnDelimiter;
      result += fieldSelector(selector)(item);
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const convertArrayOfObjectToFieldsMatrix = (
  array: any[],
  selectors: any[],
  columnNames: string[]
) => {
  const result = [];

  result.push(columnNames);
  array.forEach((item) => result.push(selectors.map((selector) => fieldSelector(selector)(item))));

  return result;
};

export const downloadCSV = (fileData: Blob, fileName?: string) => {
  const currentDate = new Date().toISOString().split('T')[0];
  downloadFile(
    new Blob(['\ufeff', fileData]),
    `${fileName ? fileName : DEFAULT_CSV_FILENAME}_${currentDate}.csv`
  );
};

export const downloadXLSX = async (
  data: any[][],
  columnTypes: ColumnType[],
  fileName?: string,
  headerStyle?: {
    backgroundColor: string;
    color: string;
  }
) => {
  const workbook = utils.book_new();
  const sheet = utils.aoa_to_sheet(data);

  if (headerStyle) {
    const headerRow = sheet['!ref']?.split(':')[0].replace(/\D/g, '');
    columnTypes.forEach((_, colIdx) => {
      const columnLetter = utils.encode_col(colIdx);
      const cellAddress = `${columnLetter}${headerRow}`;
      if (sheet[cellAddress]) {
        sheet[cellAddress].s = {
          fill: { fgColor: { rgb: headerStyle.backgroundColor } },
          font: { color: { rgb: headerStyle.color } }
        };
      }
    });
  }

  for (let rowIndex = 1; rowIndex <= data.length; rowIndex++) {
    columnTypes.forEach((columnType, colIdx) => {
      const columnLetter = utils.encode_col(colIdx);
      const cellAddress = columnLetter + (rowIndex + 1);
      if (sheet[cellAddress]) {
        const cellValue = sheet[cellAddress].v;
        if (columnType === 'boolean' && typeof cellValue === 'boolean') {
          sheet[cellAddress].t = 'b';
        } else if (columnType === 'numeric') {
          const res = numberFormatter(cellValue, 5, '.', ',');
          if (!isNaN(cellValue) && res.cellValueFormated) {
            sheet[cellAddress].v = res.cellValueFormated;
            sheet[cellAddress].z = res.format;
            sheet[cellAddress].t = 'n';
          }
        } else if (['date', 'dateTime', 'dateUTC', 'dateTimeUTC'].includes(columnType)) {
          const formattedDate = dateXLSXFormatter(cellValue, columnType as DateFormatType);
          if (formattedDate) {
            sheet[cellAddress].v = formattedDate;
            sheet[cellAddress].t = 'd';
            if (['dateTime', 'dateTimeUTC'].includes(columnType)) {
              sheet[cellAddress].z = 'dd-mm-yyyy h:mm:ss';
            }
          }
        } else if (columnType === 'percentage') {
          const res = numberFormatter(cellValue, 5, '.', ',');
          if (!isNaN(cellValue) && res.cellValueFormated) {
            sheet[cellAddress].v = res.cellValueFormated;
            sheet[cellAddress].z = '0.00%';
            sheet[cellAddress].t = 'n';
          }
        } else if (Array.isArray(data[rowIndex][colIdx])) {
          sheet[cellAddress].v = data[rowIndex][colIdx].join(', ');
          sheet[cellAddress].t = 's';
          sheet[cellAddress].f = undefined;
        }
      }
    });
  }
  utils.book_append_sheet(workbook, sheet, 'Datos');
  writeFile(
    workbook,
    fileName ? fileName : 'export_' + new Date().toISOString().split('T')[0] + '.xlsx'
  );
};

export const downloadBase64As = async ({
  base64,
  asFile
}: {
  base64: string;
  asFile: 'png' | 'pdf';
}) => {
  const blob = await (
    await fetch(`data:${asFile === 'pdf' ? 'application/pdf' : 'image/png'};base64,${base64}`)
  ).blob();

  downloadFile(blob, `${Date.now()}`);
};

const downloadFile = (fileData: Blob, fileName: string) => {
  const link = document.createElement('a');

  link.setAttribute('href', window.URL.createObjectURL(fileData));
  link.setAttribute('download', fileName);
  link.click();
};

export const numberFormatter = (
  cellValue: any,
  maximumFloat: number,
  thousandSep: string,
  decimalSep: string
): { cellValueFormated: string; format: string } => {
  const cellValueFormated = formatStringNumber(cellValue, 0, maximumFloat)
    .replace(new RegExp(`[${thousandSep}]`, 'g'), '')
    .replace(new RegExp(`[${decimalSep}]`, 'g'), '.');

  const decimalFormat =
    maximumFloat && maximumFloat > 0 ? `#,##0.${'#'.repeat(maximumFloat)}` : '#,##0.##';
  const thousandFormat =
    maximumFloat && maximumFloat > 0 ? `#,${'#'.repeat(maximumFloat)}0` : '#,##0';
  const format = cellValue % 1 ? decimalFormat : thousandFormat;
  return { cellValueFormated, format };
};

export type DateFormatType = 'date' | 'dateTime' | 'dateUTC' | 'dateTimeUTC';

export const dateXLSXFormatter = (cellValue: any, format: DateFormatType): Date | null => {
  //NOTE: The time difference between a time zone and a zero time zone is different in various historical periods.
  // the XLSX library has a problem that exports the dates with 45 seconds less for Chile.
  // Sheetjs use getTimezoneOffset to fix this issue, but getTimezoneOffset only returns minutes, not include seconds.
  // An additional offset is performed to correct this error
  // reference: https://github.com/SheetJS/sheetjs/issues/2350

  const localDate = new Date(cellValue);
  if (isNaN(localDate.getTime())) {
    return null;
  }
  const baseDate = new Date(1899, 11, 30, 0, 0, 0);
  const baseDateUtc = new Date(Date.UTC(1899, 11, 30, 0, 0, 0));
  const timezoneOffsetFix =
    baseDateUtc.valueOf() + baseDate.getTimezoneOffset() * 60000 - baseDate.valueOf();

  const utcYear = localDate.getUTCFullYear();
  const utcMonth = localDate.getUTCMonth();
  const utcDay = localDate.getUTCDate();
  const utcHours = localDate.getUTCHours();
  const utcMinutes = localDate.getUTCMinutes();
  const utcSeconds = localDate.getUTCSeconds();

  if (!['date', 'dateTime', 'dateUTC', 'dateTimeUTC'].includes(format)) {
    return null;
  }

  let date = new Date();

  if (format === 'date') {
    date = moment(
      new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0)
    ).toDate();
  } else if (format === 'dateTime') {
    date = moment(
      new Date(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        localDate.getHours(),
        localDate.getMinutes(),
        localDate.getSeconds()
      )
    ).toDate();
  } else if (format === 'dateUTC') {
    date = moment(new Date(utcYear, utcMonth, utcDay, 0, 0, 0)).toDate();
  } else if (format === 'dateTimeUTC') {
    date = moment(new Date(utcYear, utcMonth, utcDay, utcHours, utcMinutes, utcSeconds)).toDate();
  }

  const needFix =
    new Date(date.valueOf() - timezoneOffsetFix).getTimezoneOffset() !==
    baseDate.getTimezoneOffset();
  const fixedDate = needFix ? new Date(date.valueOf() - timezoneOffsetFix) : date;
  return new Date(moment(fixedDate).format('YYYY-MM-DD HH:mm:ss'));
};
