import React, { useEffect, useState } from 'react';
import { Provision, StatusBudgetNames } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';
import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ApproveOrRejectProvisionButton } from '../components/SecondaryButton';

const PendingProvisionEntity: WebEntity<Provision> = {
  name: 'provision',
  endpoint: 'provision/pendingProvision',
  referenceColumn: 'provision',
  tableColumns: [
    {
      header: 'Opciones',
      accessorKey: '_id',
      id: 'Options',
      columnType: 'text',
      omitExport: true,
      size: 400,
      Cell: ({ row, column }) => {
        const [input, setinput] = useState('');

        const data = [StatusBudgetNames.approved, StatusBudgetNames.rejected];

        const [textExplication, setTextExplication] = useState('');
        const [isNegative, setIsNegative] = useState(false);

        const handleInput = (event: SelectChangeEvent) => {
          if (event.target.value !== '') {
            row.original[column.id] = event.target.value;
            setinput(event.target.value);
          }
        };

        const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
          event
        ) => {
          if (event.target.value !== '') {
            setTextExplication(event.target.value);
          }
        };

        useEffect(() => {
          if (Number(row._valuesCache?.amountMd) < 0) {
            setIsNegative(true);
          }
        }, [row._valuesCache]);

        return (
          !isNegative && (
            <div
              style={{
                display: 'flex',
                gap: 20
              }}>
              <FormControl variant='filled' sx={{ minWidth: 140 }}>
                <InputLabel id='demo-simple-select-standard-label'>Estado Cierre</InputLabel>
                <Select
                  labelId='demo-simple-select-standard-label'
                  id='demo-simple-select-standard'
                  onChange={handleInput}
                  value={input}
                  style={{
                    border: `1px solid ${
                      input === StatusBudgetNames.approved
                        ? 'green'
                        : input === StatusBudgetNames.rejected
                        ? 'red'
                        : 'transparent'
                    }`
                  }}
                  label='Age'>
                  {data?.map((p, i) => (
                    <MenuItem key={i} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {input === StatusBudgetNames.rejected && (
                <Input type='text' onChange={handleChange} />
              )}
              {input === StatusBudgetNames.approved && (
                <ApproveOrRejectProvisionButton data={row?.original} actions={input} />
              )}
              {input === StatusBudgetNames.rejected && textExplication && (
                <ApproveOrRejectProvisionButton
                  data={row?.original}
                  actions={input}
                  textExplication={textExplication}
                />
              )}
            </div>
          )
        );
      }
    },
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Correlativo',
      accessorKey: 'correlative',
      id: 'correlative',
      columnType: 'text'
    },
    {
      header: 'Grupo Ledgers',
      accessorKey: 'ledgerGroup',
      id: 'ledgerGroup',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sociedad',
      accessorKey: 'society',
      id: 'society',
      columnType: 'text'
    },
    {
      header: 'Clase de Documento',
      accessorKey: 'documentType',
      id: 'documentType',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Fecha de Documento',
      accessorKey: 'documentDate',
      id: 'documentDate',
      columnType: 'dateUTC',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row?.original?.documentDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Fecha de Contabilizacion',
      accessorKey: 'accountingDate',
      id: 'accountingDate',
      columnType: 'dateUTC',
      size: 300,
      Cell: ({ row }) => dateFormatFn(row?.original?.accountingDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Moneda',
      accessorKey: 'coinDescription',
      id: 'coinDescription',
      columnType: 'text'
    },
    {
      header: 'Texto Cabecera Docuemnto',
      accessorKey: 'textDocument',
      id: 'textDocument',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Tipo de cuenta',
      accessorKey: 'typeAccount',
      id: 'typeAccount',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Numero de cuenta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Importe MDA',
      accessorKey: 'amountMd',
      id: 'amountMd',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row?.original?.amountMd)
    },
    {
      header: 'Asignacion',
      accessorKey: 'assignment',
      id: 'assignment',
      columnType: 'text'
    },
    {
      header: 'Texto Posicion',
      accessorKey: 'text',
      id: 'text',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Centro Beneficio',
      accessorKey: 'centroBeneficio',
      id: 'centroBeneficio',
      columnType: 'text',
      size: 210
    },
    {
      header: 'Centro de coste',
      accessorKey: 'centroCoste',
      id: 'centroCoste',
      columnType: 'text',
      size: 200
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'userTypeName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default PendingProvisionEntity;
