import GenericIcons from './GenericIcons';

const GenericInfoTag = ({
  text,
  strongTitle,
  icon
}: {
  text: string;
  strongTitle?: string;
  icon?: string;
}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#FFF5E2',
        border: '2px solid #ffb81c',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px'
      }}>
      {strongTitle || icon ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '20%',
            height: '100%'
          }}>
          {icon ? (
            <GenericIcons icon={icon} imageStyle={{ width: '100%', height: '28px' }} />
          ) : null}
          <strong
            style={{
              color: '#4f4f4f',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: 'bold'
            }}>
            {strongTitle}
          </strong>
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          height: '100%'
        }}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default GenericInfoTag;
