import { Stack } from '@mui/material';
import { Volumen } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const VolumenEntity: WebEntity<Volumen> = {
  name: 'volumen',
  endpoint: 'volumen',
  referenceColumn: 'volumen',
  tableColumns: [
    {
      header: 'Fecha',
      accessorKey: 'fecha',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row?.original?.fecha ? dateFormatFn(row?.original?.fecha, 'DD/MM/YYYY', true) : ''
    },
    {
      header: 'Entregado',
      accessorKey: 'entregado',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.entregado ? formatStringNumber(row?.original?.entregado, 0, 2) : '',
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Volumen) => (obj.entregado ? acc + Number(obj.entregado) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    },
    {
      header: 'Facturado',
      accessorKey: 'facturado',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.facturado ? formatStringNumber(row?.original?.facturado, 0, 2) : '',
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Volumen) => (obj.facturado ? acc + Number(obj.facturado) : acc),
          0
        );
        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Stack>
        );
      }
    }
  ]
};

export default VolumenEntity;
