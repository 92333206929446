import { Box } from '@mui/material';
import { FreightTimeMaster } from '../types';
import { booleanFormatFn, dateFormatFn, spanishDayNames, spanishMonthNames } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const FreightTimeMasterEntity: WebEntity<FreightTimeMaster> = {
  name: 'freightTimeMaster',
  endpoint: 'ifac/freightTimeMaster',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text',
      omitExport: true
    },
    {
      header: 'Fecha',
      accessorKey: 'timeDate',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row?.original?.timeDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Dia de la Semana',
      accessorKey: 'dayOfWeek',
      columnType: 'text',
      Cell: ({ row }) => {
        const numberDay =
          Number(row?.original?.dayOfWeek) === 7 ? 0 : Number(row?.original?.dayOfWeek);

        return spanishDayNames[numberDay];
      }
    },
    {
      header: 'Semana',
      accessorKey: 'weekDate',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row?.original?.weekDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Mes',
      accessorKey: 'monthDate',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.monthDate
          ? `${spanishMonthNames[new Date(row.original?.monthDate).getUTCMonth()]} ${new Date(
              row.original?.monthDate
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: '¿Feriado?',
      accessorKey: 'isHoliday',
      columnType: 'boolean',
      Cell: ({ row }) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          {booleanFormatFn(row?.original?.isHoliday)}
        </Box>
      )
    },
    {
      header: '¿Dia hábil?',
      accessorKey: 'isBusinessDay',
      columnType: 'boolean',
      Cell: ({ row }) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          {booleanFormatFn(row?.original?.isBusinessDay)}
        </Box>
      )
    }
  ],

  editableFields: [
    {
      name: '¿Feriado?',
      selector: 'isHoliday',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: '¿Dia hábil?',
      selector: 'isBusinessDay',
      type: FieldTypes.Boolean,
      required: true
    }
  ]
};

export default FreightTimeMasterEntity;
