import { AreaManager } from '../types';
import { FieldTypes, WebEntity } from './types';

const AreaManagerEntity: WebEntity<AreaManager> = {
  name: 'areaManager',
  endpoint: 'costCenter/centerAssignment',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Asignacion'
  },
  customDataActions: {
    editModalTitle: 'Editar Asignacion'
  },
  tableColumns: [
    {
      header: 'CC SAP',
      accessorKey: '_id',
      columnType: 'text',
      size: 200
    },
    {
      header: 'CC',
      accessorKey: 'codeCostCenter',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Centro de costo',
      accessorKey: 'costCenterName',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Nivel 4',
      accessorKey: 'description',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Nivel 1',
      accessorKey: 'levelOne',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Nivel 2',
      accessorKey: 'levelTwo',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Nivel 3',
      accessorKey: 'levelThree',
      columnType: 'text',
      size: 200
    }
  ],

  fields: [
    {
      name: 'Centro de costo',
      selector: 'costCenterId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'costCenter',
        endpoint: 'costCenter',
        select: 'costCenterId',
        show: 'costCenterName'
      }
    },
    {
      name: 'Nivel 1',
      selector: 'JefeUserId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Nivel 1',
        endpoint: 'users',
        select: 'userAccountId',
        show: 'userUsername'
      }
    },
    {
      name: 'Nivel 2',
      selector: 'SupervisorUserId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Nivel 2',
        endpoint: 'users',
        select: 'userAccountId',
        show: 'userUsername'
      }
    },
    {
      name: 'Nivel 3',
      selector: 'OperadorUserId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Nivel 3',
        endpoint: 'users',
        select: 'userAccountId',
        show: 'userUsername'
      }
    }
  ],

  editableFields: [
    {
      name: 'Nivel 1',
      selector: 'levelOne',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Nivel 1',
        endpoint: 'users',
        select: 'userUsername',
        show: 'userUsername'
      }
    },
    {
      name: 'Nivel 2',
      selector: 'levelTwo',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Nivel 2',
        endpoint: 'users',
        select: 'userUsername',
        show: 'userUsername'
      }
    },
    {
      name: 'Nivel 3',
      selector: 'levelThree',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Nivel 3',
        endpoint: 'users',
        select: 'userUsername',
        show: 'userUsername'
      }
    }
  ]
};

export const AREAMANAGER_COLUMNS = {
  costCenterSap: 'CC SAP',
  codeCostCenter: { name: 'CC', required: false, export: true },
  costCenterName: 'Nombre CC',
  zoneName: 'Zona',
  levelOne: 'Nivel 1',
  levelTwo: 'Nivel 2',
  levelThree: 'Nivel 3',
  description: 'Nivel 4'
};

export default AreaManagerEntity;
