import { ConfirmProvisionButton, DeleteProvisionButton } from '../components/SecondaryButton';
import { Provision } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const ProvisionEntity: WebEntity<Provision> = {
  name: 'provision',
  endpoint: 'provision',
  referenceColumn: 'provision',
  tableColumns: [
    {
      header: 'Opciones',
      accessorKey: '_id',
      id: 'options',
      size: 200,
      omitExport: true,
      columnType: 'text',
      Cell: ({ row }) => {
        return (
          <div>
            {ConfirmProvisionButton(row.original._id, row.original?.amountMd)}
            {DeleteProvisionButton(row.original._id, row.original?.amountMd)}
          </div>
        );
      }
    },
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Correlativo',
      accessorKey: 'correlative',
      id: 'correlative',
      columnType: 'text'
    },
    {
      header: 'Grupo Ledgers',
      accessorKey: 'ledgerGroup',
      id: 'ledgerGroup',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sociedad',
      accessorKey: 'society',
      id: 'society',
      columnType: 'text'
    },
    {
      header: 'Clase de Documento',
      accessorKey: 'documentType',
      id: 'documentType',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Fecha de Documento',
      accessorKey: 'documentDate',
      id: 'documentDate',
      columnType: 'numeric',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row?.original?.documentDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Fecha de Contabilizacion',
      accessorKey: 'accountingDate',
      id: 'accountingDate',
      columnType: 'numeric',
      size: 300,
      Cell: ({ row }) => dateFormatFn(row?.original?.accountingDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Moneda',
      accessorKey: 'coinDescription',
      id: 'coinDescription',
      columnType: 'text'
    },
    {
      header: 'Texto Cabecera Docuemnto',
      accessorKey: 'textDocument',
      id: 'textDocument',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Tipo de cuenta',
      accessorKey: 'typeAccount',
      id: 'typeAccount',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Numero de cuenta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Importe MDA',
      accessorKey: 'amountMd',
      id: 'amountMd',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row?.original?.amountMd)
    },
    {
      header: 'Asignacion',
      accessorKey: 'assignment',
      id: 'assignment',
      columnType: 'text'
    },
    {
      header: 'Texto Posicion',
      accessorKey: 'text',
      id: 'text',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Centro Beneficio',
      accessorKey: 'centroBeneficio',
      id: 'centroBeneficio',
      columnType: 'text',
      size: 210
    },
    {
      header: 'Centro de coste',
      accessorKey: 'centroCoste',
      id: 'centroCoste',
      columnType: 'text',
      size: 200
    }
  ]
};

export const PROVISION_COLUMNS = {
  correlative: 'Correlativo',
  ledgerGroup: 'Grupo de Ledgers',
  society: 'Sociedad',
  documentType: 'Clase de Documento',
  reference: { name: 'Referencia', required: false, export: true },
  documentDate: 'Fecha Documento',
  accountingDate: 'Fecha Contabilizacion',
  coinTypeName: 'Moneda',
  dateConversion: { name: 'Fecha Conversion', required: false, export: true },
  changeType: { name: 'Tipo de Cambio', required: false, export: true },
  textDocument: 'Texto Cabecera Documento',
  referenceDocument: { name: 'Referencia Documento', required: false, export: true },
  typeAccount: 'Tipo de cuenta',
  accountSap: 'Numero de cuenta',
  amountMd: {
    name: 'Importe MDA',
    required: true,
    export: true,
    format: (amountMd: string) => {
      if (!amountMd) {
        return '';
      }
      return Number(amountMd.replace(/\./g, '') || '');
    }
  },
  assignment: { name: 'Asignacion', required: false, export: true, upload: true },
  taxAmount: { name: 'Importe Impuesto', required: false, export: true },
  taxindicator: { name: 'Indicador Impuesto', required: false, export: true },
  text: 'Texto Posicion',
  paymentCondition: { name: 'Condicion de Pago', required: false, export: true },
  paymentMethod: { name: 'Via de Pago', required: false, export: true },
  factureReference: { name: 'Referencia Factura', required: false, export: true },
  invoiceExercise: { name: 'Ejercicio Ref. Factura', required: false, export: true },
  positionRefFacture: { name: 'Posicion Ref. Factura', required: false, export: true },
  costCenterName: 'Centro Beneficio',
  referenceOne: { name: 'Referencia 1', required: false, export: true },
  referencetwo: { name: 'Referencia 2', required: false, export: true },
  referencethree: { name: 'Referencia 3', required: false, export: true },
  accountClass: { name: 'Clase Cuenta', required: false, export: true },
  treasuryDate: { name: 'Fecha de Tesoseria', required: false, export: true },
  costCenter: 'Centro de coste',
  elementPep: { name: 'Elemento PEP', required: false, export: true },
  orderNumber: { name: 'Numero De Orden', required: false, export: true },
  societyGl: { name: 'Sociedad GL', required: false, export: true },
  mybank: { name: 'Banco Propio', required: false, export: true },
  accountBank: { name: 'Cuenta Bancaria', required: false, export: true },
  distributionChanel: { name: 'Canal de Distribucion', required: false, export: true },
  sectoring: { name: 'Sector', required: false, export: true },
  sellOrganization: { name: 'Organizacion de Ventas', required: false, export: true },
  groupSeller: { name: 'Grupo de vendedores', required: false, export: true },
  salesOffice: { name: 'Oficina de Ventas', required: false, export: true },
  materiales: { name: 'Material', required: false, export: true },
  cmeIndicator: { name: 'Indicador CME', required: false, export: true }
};

export default ProvisionEntity;
