import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom/client';
import { Accordion, Icon, Modal, Header, SemanticICONS } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { returnErrors } from '../actions/errorActions';
import { WebEntityName } from '../entities/types';
import { AppDispatch } from '../store';
import { setEntityLoading } from '../actions/entitiesActions';
import LogText from './utils/LogText';

export enum PopAlertType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS'
}

type PopAlertTypeContent = {
  icon: SemanticICONS;
  color: SemanticCOLORS;
  value: string;
};

const PopAlertTypes: { [type in PopAlertType]: PopAlertTypeContent } = {
  [PopAlertType.ERROR]: { icon: 'warning circle', color: 'red', value: 'ERROR' },
  [PopAlertType.WARNING]: { icon: 'warning sign', color: 'orange', value: 'WARNING' },
  [PopAlertType.SUCCESS]: { icon: 'check', color: 'green', value: 'SUCCESS' }
};

type Props = {
  type: PopAlertType;
  title: string;
  message?: string;
  details?: string;
};

export const popAlert = ({ type, title = '', message = '', details = '' }: Props) => {
  const root = document.getElementById('root');

  if (root) {
    const wrapper: HTMLElement = document.createElement('div');
    root.append(wrapper);

    const reactRoot = ReactDOM.createRoot(wrapper);
    reactRoot.render(<PopAlert {...{ type, title, message, details }} />);
  }
};

export const requestErrorPopAlert = (error: unknown) =>
  popAlert({
    type: PopAlertType.ERROR,
    title: (error as any).response?.data?.msg || (error as any).response?.statusText || '',
    details: (error as any).response?.data?.details
  });

export const errorPopAlert = (title: string, message?: string, details?: string) =>
  popAlert({
    type: PopAlertType.ERROR,
    title,
    message,
    details
  });

export const warningPopAlert = (title: string, message?: string, details?: string) =>
  popAlert({
    type: PopAlertType.WARNING,
    title,
    message,
    details
  });

export const successPopAlert = (title: string, message?: string, details?: string) =>
  popAlert({
    type: PopAlertType.SUCCESS,
    title,
    message,
    details
  });

export const handleError = ({
  error,
  entityName,
  dispatch
}: {
  error: unknown;
  entityName?: WebEntityName;
  dispatch: AppDispatch;
}) => {
  console.log((error as any).response);

  popAlert({
    type: PopAlertType.ERROR,
    title: (error as any).response?.data?.msg || (error as any).response?.statusText || '',
    details: (error as any).response?.data?.details
  });

  dispatch(returnErrors((error as any).response.data, (error as any).response.status));

  if (entityName) {
    dispatch(setEntityLoading(entityName, false));
  }
};

const PopAlert: FunctionComponent<Props> = ({ type, title, message, details }) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const onClose = () => {
    setOpen(false);
  };

  const switchShowDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Modal
      dimmer={'blurring'}
      open={open}
      size={'small'}
      style={{
        height: 'auto',
        top: 'auto',
        left: 'auto',
        overflow: 'visible'
      }}
      onClose={onClose}>
      <Modal.Header align={'center'}>
        <Icon name={PopAlertTypes[type].icon} color={PopAlertTypes[type].color} />
        {PopAlertTypes[type].value}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description align={'center'}>
          <Header align={'center'}>{title}</Header>
          {message && <p>{message}</p>}
        </Modal.Description>
      </Modal.Content>
      {details && (
        <Accordion fluid styled>
          <Accordion.Title active={showDetails} index={0} onClick={switchShowDetails}>
            Detalle <Icon name='dropdown' />
          </Accordion.Title>
          <Accordion.Content active={showDetails}>
            <LogText>{details}</LogText>
          </Accordion.Content>
        </Accordion>
      )}
    </Modal>
  );
};
