import { CSSProperties } from 'react';
import '../css/cardAlert.css';

export type CardAlertProps = {
  title: string;
  numberInfo: string;
  icon?: JSX.Element;
  variant?: 'success' | 'warning' | 'error';
  detailStyle?: CSSProperties;
};

const GenericCardAlert = (props: CardAlertProps) => {
  return (
    <div
      className='card-alert-container'
      style={{
        borderColor:
          props.variant === 'success'
            ? '#64a70b'
            : props.variant === 'error'
            ? 'red'
            : props.variant === 'warning'
            ? 'yellow'
            : ''
      }}>
      <h4
        className='card-alert-title'
        style={{
          color:
            props.variant === 'success'
              ? '#64a70b'
              : props.variant === 'error'
              ? 'red'
              : props.variant === 'warning'
              ? 'yellow'
              : ''
        }}>
        {props.title}
      </h4>
      <div className='card-alert-content-container'>
        {props.icon}
        <span
          className='card-alert-content'
          style={{
            color:
              props.variant === 'success'
                ? '#64a70b'
                : props.variant === 'error'
                ? 'red'
                : props.variant === 'warning'
                ? 'yellow'
                : '',
            ...props.detailStyle
          }}>
          {props.numberInfo}
        </span>
      </div>
    </div>
  );
};

export default GenericCardAlert;
