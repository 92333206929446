import { DictionaryCc } from '../types';
import { dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';

const updateDependency =
  (fieldName: string, fieldToUpdate: 'dateValidFrom' | 'dateValidTo'): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);

      const fieldValueToUpdate = getFieldValue(fieldToUpdate);

      const fieldValueDateFormat = new Date(fieldValue);
      const fieldValueToUpdateDateFormat = new Date(fieldValueToUpdate);

      const checkIfIsStart = () => fieldName === 'dateValidFrom';
      const checkIfIsEnd = () => fieldName === 'dateValidTo';
      const checkIfIsMajor = () => fieldValueDateFormat > fieldValueToUpdateDateFormat;
      const checkIfIsMinus = () => fieldValueDateFormat < fieldValueToUpdateDateFormat;

      let newValueToUpdate = fieldValueToUpdate;

      if (fieldValue && fieldValueToUpdate) {
        if ((checkIfIsEnd() && checkIfIsMinus()) || (!checkIfIsEnd() && checkIfIsMajor())) {
          newValueToUpdate = fieldValue;
        }
      } else if (
        (!fieldValue && fieldValueToUpdate && checkIfIsStart()) ||
        (fieldValue && !fieldValueToUpdate && checkIfIsEnd())
      ) {
        newValueToUpdate = fieldValue;
      }

      updateFieldValue(fieldToUpdate, newValueToUpdate);
      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const DictionaryCcEntity: WebEntity<DictionaryCc> = {
  name: 'dictionaryCc',
  endpoint: 'dictionaryCc',
  referenceColumn: 'categoriesName',
  customButtonNames: {
    addButtonName: 'Agregar Diccionario'
  },
  customDataActions: {
    editModalTitle: 'Editar Diccionario'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Cuenta',
      accessorKey: 'accountSap',
      columnType: 'text',
      size: 120
    },
    {
      header: 'Cuenta de Mayor: Texto explicativo',
      accessorKey: 'accountDescription',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Categoria',
      accessorKey: 'categoriesName',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Valida desde',
      accessorKey: 'dateValidFrom',
      columnType: 'dateUTC',
      size: 150,
      Cell: ({ row }) => dateFormatFn(row.original?.dateValidFrom, 'DD-MM-YYYY', true)
    },
    {
      header: 'Valida hasta',
      accessorKey: 'dateValidTo',
      columnType: 'dateUTC',
      size: 150,
      Cell: ({ row }) => dateFormatFn(row.original?.dateValidTo, 'DD-MM-YYYY', true)
    }
  ],

  fields: [
    {
      name: 'Cuenta',
      selector: 'accountSap',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Cuenta de Mayor: Texto explicativo',
      selector: 'accountDescription',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Categoria',
      selector: 'categoriesId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Categoria',
        endpoint: 'categories',
        select: 'categoriesId',
        show: 'categoriesName'
      }
    }
  ],

  editableFields: [
    {
      name: 'Cuenta',
      selector: 'accountSap',
      type: FieldTypes.Text,
      disabled: true,
      required: true
    },
    {
      name: 'Cuenta de Mayor: Texto explicativo',
      selector: 'accountDescription',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Categoria',
      selector: 'categoriesId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Categoria',
        endpoint: 'categories',
        select: 'categoriesId',
        show: 'categoriesName'
      }
    },
    {
      name: 'Valida Desde',
      selector: 'dateValidFrom',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true,
      updateDependency: updateDependency('dateValidFrom', 'dateValidTo')
    },
    {
      name: 'Valida Hasta',
      selector: 'dateValidTo',
      type: FieldTypes.Date,
      timezone: 'UTC',
      required: true,
      updateDependency: updateDependency('dateValidTo', 'dateValidFrom')
    }
  ]
};

export default DictionaryCcEntity;
